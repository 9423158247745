import { ModalDialog, ModalHeader,SpriteIcon } from "@cloudike/web_ui_components"
import styled from 'styled-components'
import { useTranslation } from "react-i18next"


export const InfoModalPreview = ({ isOpened, handleCloseModal, size, date, titleText }) => {
  const parentBlock = document.getElementsByTagName('body')[0]
  const { t } = useTranslation()

  return(
    <ModalDialog
      header={(
        <StyledHeaderBox>
          <StyledModalHeader title={titleText}
            titleIcon={<StyledSpriteIcon iconName={'info'} />}
          />

          <StyledHeaderCloseButton iconName="close"
            onClick={handleCloseModal}
          />
        </StyledHeaderBox>

      )}
      footer={<></>}
      parentBlock={parentBlock}
      isOpened={isOpened}
      onCloseModal={handleCloseModal}
    >
      <InfoText>
        <SizeText>
          {`${t('l_common_size')}: ${size}`}
        </SizeText>

        <DateText>
          {t('l_common_added', { DATE: date })}
        </DateText>
      </InfoText>
    </ModalDialog>
  )
}


const StyledSpriteIcon = styled(SpriteIcon)`
  width: 32px;
  height: 32px;
`

const InfoText = styled.div`
  margin-bottom: 24px;
`

const SizeText = styled.div`
`

const DateText = styled.div`
`

const StyledHeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledHeaderCloseButton = styled(SpriteIcon)`
  color: var(--icon-secondary);
  margin-top: 20px;
  margin-right: 20px;
  cursor: pointer;
`

const StyledModalHeader = styled(ModalHeader)`
  align-items: center;
`
