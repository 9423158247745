import { STYLED_VARIABLES } from 'constants/styledVariables'

import { useEffect, useState } from 'react'

import { MenuItem, MenuWithActionItems } from '@cloudike/web_ui_components'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { getUserDataSelector, getUserSettingsByPathSelector } from 'features/user/selectors'
import { useAppDispatch } from "store"
import { DialogModal } from 'ui/DialogModal'
import {
  createAndShareAlbumThunk
} from 'features/albums/album/albumSlice'
import { SDK_TYPES } from 'sdk/sdkConstants'
import { MobileToolbar } from 'features/common/right-sidebar/MobileToolbar'
import { appActions } from 'store/app'
import { analytics, ANALYTICS_EVENTS } from 'features/common/analytics'

import {
  getContentTypesUploadSelector,
  getIsOpenedAddPhotosToAlbumModal,
} from "../../common/selectors"
import { addSelectedToFavoritesItemsThunk, removeSelectedFromFavoritesItemsThunk } from "../../favorites/favoritesSlice"
import { openPreviewDuplicateThunk } from "../photo-preview-duplicate/photoPreviewDuplicateSlice"
import { USER_SETTINGS_PATHS } from "../../user/constants"
import { MobileToolbarCounter } from "../../common/mobile-toolbar-counter/MobileToolbarCounter"
import { FAMILY_ONBOARDING_CLASSES } from "../../../constants/familyOnboarding"
import { fetchAlbumsThunk } from "../../albums/albums-list/albumsSlice"
import { transformingPhotoThunk } from "../photo-transformer-preview/photoPreviewTransformerSlice"

import { useUploadTimelineItemsUploadHandler } from "./useUploadFilesHandler"
import {
  copyTimelineItemsToFamilyThunk,
  copyTimelineItemsToPersonalCloudThunk,
  downloadTimelineItemsThunk,
  removeSelectedItemsThunk,
  timelineActions
} from './timelineSlice'
import {
  getTimelineFilterSelector,
  getTimelineItemsSelector,
  getTimelineSelectedItemsSelector,
  getTimelineTotalItemsCountSelector
} from './selectors'


interface TimelineToolbarProps {
  className?: string,
  type: SDK_TYPES
}

export const TimelineToolbar: React.FC<TimelineToolbarProps> = ({ className = '', type }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const userData = getUserDataSelector()
  const selectedItems = getTimelineSelectedItemsSelector()
  const selectedItemsCount = selectedItems.length
  // const fileInput = useRef(null)
  const contentTypesUpload = getContentTypesUploadSelector()
  const isEnabledPhotoDuplicates = getUserSettingsByPathSelector(USER_SETTINGS_PATHS.PHOTOS_DUPLICATES).enabled
  const filter = getTimelineFilterSelector()
  const isOpenedAddPhotosToAlbumModal = getIsOpenedAddPhotosToAlbumModal()

  const isEnableTransformerPhoto = getUserSettingsByPathSelector(USER_SETTINGS_PATHS.TRANSFORMER)?.enabled

  const [removingConfirmationModalOpened, toggleRemovingConfirmationModal] = useState(false)

  useEffect(() => {
    if (isOpenedAddPhotosToAlbumModal) {
      dispatch(appActions.setIsSomeSpecialModalOpened(true))
    } else {
      dispatch(appActions.setIsSomeSpecialModalOpened(false))
    }
  }, [isOpenedAddPhotosToAlbumModal])

  const isSelectedItemsHaveOnlyFavorite = () => {
    return selectedItems.some(item => !item.favorite)
  }

  const handleUploadFiles = useUploadTimelineItemsUploadHandler(type, filter)

  const handleDownloadFiles = () => {
    dispatch(downloadTimelineItemsThunk({ items: selectedItems, type }))
    analytics.push(ANALYTICS_EVENTS.WEB_PHOTO_DOWNLOAD_CLICK)
  }

  const handleOpenRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(true)
  }

  const handleCloseRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(false)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleRemoveItems = () => {
    dispatch(removeSelectedItemsThunk({ items: selectedItems, type }))
    handleCloseRemovingConfirmationModal()
    analytics.push(ANALYTICS_EVENTS.WEB_PHOTO_REMOVE_CLICK)
  }

  const handleShareItems = () => {
    dispatch(createAndShareAlbumThunk({
      items: selectedItems,
      type: type === SDK_TYPES.DEFAULT ? SDK_TYPES.DEFAULT : SDK_TYPES.FAMILY
    }))
    analytics.push(ANALYTICS_EVENTS.WEB_PHOTO_CREATE_LINK)
  }

  const handleCopyToFamily = () => {
    dispatch(copyTimelineItemsToFamilyThunk(selectedItems))
    dispatch(appActions.toggleRightMenuOnMobile(false))
    analytics.push(ANALYTICS_EVENTS.WEB_PHOTO_COPY_TO_FC_CLICK)
  }

  const handleCopyToPersonalCloud = () => {
    dispatch(copyTimelineItemsToPersonalCloudThunk(selectedItems))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleAddToFavoritesItems = () => {
    dispatch(addSelectedToFavoritesItemsThunk({ items: selectedItems, withNotification: true, type }))
    dispatch(timelineActions.unselectAll())
    analytics.push(ANALYTICS_EVENTS.WEB_PHOTOS_ACTIONS_ADD_TO_FAVORITES)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleRemoveFromFavoritesItems = () => {
    dispatch(removeSelectedFromFavoritesItemsThunk({ items: selectedItems, type }))
    dispatch(timelineActions.unselectAll())
    analytics.push(ANALYTICS_EVENTS.WEB_PHOTOS_ACTIONS_REMOVE_FROM_FAVORITES)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const items = getTimelineItemsSelector()
  const totalItemsCount = getTimelineTotalItemsCountSelector()

  const handleDuplicate = () => {
    if (selectedItems.length === 1) {
      dispatch(openPreviewDuplicateThunk({ selectedItem: selectedItems[0], items, totalItemsCount, type }))
      dispatch(appActions.toggleRightMenuOnMobile(false))
    }
  }

  const setIsOpenedAddPhotosToAlbumModal = (isOpened: boolean) => {
    dispatch(appActions.setIsOpenedAddPhotosToAlbumModal(isOpened))
    if(isOpened){
      if(type === SDK_TYPES.DEFAULT){
        analytics.push(ANALYTICS_EVENTS.WEB_PHOTOS_OUTSIDE_ADD_TO_ALBUM)
      } else {
        analytics.push(ANALYTICS_EVENTS.WEB_FC_OUTSIDE_ADD_TO_ALBUM)
      }
    }
  }

  const openAddToAlbumModal = () => {
    dispatch(appActions.setSelectedItemsToAddToAlbumModal(selectedItems))
    dispatch(appActions.setAlbumTypeToAddToAlbumModal(type))
    dispatch(fetchAlbumsThunk())
    setIsOpenedAddPhotosToAlbumModal(true)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleClickTransformer = () => {
    dispatch(transformingPhotoThunk(selectedItems))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const menuItems = (() => {
    const items: React.ComponentProps<typeof MenuItem>[] = [
      isEnableTransformerPhoto && type === SDK_TYPES.DEFAULT && selectedItems.every(item => item.type === 'image' ) && {
        label: 'Transform',
        iconName: 'gallery',
        onClickItem: handleClickTransformer
      },
      {
        label: t('a_common_addToAlbum'),
        iconName: 'add_to_album',
        onClickItem: () => openAddToAlbumModal()
      },
      isSelectedItemsHaveOnlyFavorite() && {
        label: t('a_common_addToFavorites'),
        iconName: 'favorite_border',
        onClickItem: handleAddToFavoritesItems
      },
      !isSelectedItemsHaveOnlyFavorite() && (type === SDK_TYPES.DEFAULT || type === SDK_TYPES.FAMILY) && {
        label: t('a_common_removeFavorite'),
        iconName: 'remove_favorite_empty',
        onClickItem: handleRemoveFromFavoritesItems
      },
      {
        label: t('a_common_share'),
        iconName: 'share',
        onClickItem: handleShareItems
      },
      isEnabledPhotoDuplicates && selectedItems.length === 1 && selectedItems[0].type === 'image' && {
        label: t('a_common_searchForSimilarPhotos'),
        iconName: 'search_duplicate',
        onClickItem: handleDuplicate
      },
      {
        label: t('a_common_download'),
        iconName: 'download_empty',
        onClickItem: handleDownloadFiles
      },
      {
        label: t('a_common_remove'),
        iconName: 'remove_icon',
        onClickItem: handleOpenRemovingConfirmationModal
      },
    ].filter(item => !!item)

    if (!!userData?.family_user_id) {
      items.unshift({
        label: type === SDK_TYPES.FAMILY ? t('a_common_copyToPersonal') : t('a_common_copyToFamily'),
        iconName: type === SDK_TYPES.FAMILY ? 'add_from_personal' : 'add_from_family',
        onClickItem: type === SDK_TYPES.FAMILY ? handleCopyToPersonalCloud : handleCopyToFamily
      })
    }

    return items
  })()

  const mobileMenuItems = (() => {
    let items: React.ComponentProps<typeof MenuItem>[] = [
      !selectedItemsCount && {
        label: t('a_common_uploadPhotos'),
        iconName: 'upload_photo',
        type: 'file',
        onClickItem: handleUploadFiles,
        accept: contentTypesUpload
      }
    ]

    if (!!selectedItemsCount) {
      items = [...items, ...menuItems]
    }

    return items.filter(Boolean)
  })()

  if (type === SDK_TYPES.FAMILY && !userData?.family_user_id) {
    return null
  }

  return (
    <>
      <TimelineToolbarBox className={className}>
        <StyledPrimaryButton
          actionName={t('a_common_uploadPhotos')}
          htmlFor="upload-btn"
          className={FAMILY_ONBOARDING_CLASSES.STEP_2}
        >
          {t('a_common_uploadPhotos')}

          <HiddenFilesInput
            id="upload-btn"
            onChange={handleUploadFiles}
            type="file"
            multiple
            accept={contentTypesUpload}
          />
        </StyledPrimaryButton>

        {
          !!selectedItemsCount &&
          <MenuWithActionItems menuItems={menuItems} />
        }
      </TimelineToolbarBox>

      <MobileToolbar
        items={mobileMenuItems}
        headContent={!!selectedItemsCount && (
          <MobileToolbarCounter selectedItemsCount={selectedItemsCount} />
        )}
      />

      <DialogModal
        isOpened={removingConfirmationModalOpened}
        title={t('l_common_headerRemovePhotos', { number: selectedItemsCount })}
        onClose={handleCloseRemovingConfirmationModal}
        okText={t('a_common_ok')}
        onOk={handleRemoveItems}
        cancelText={t('a_common_cancel')}
        onCancel={handleCloseRemovingConfirmationModal}
      >
        <TextInModalBox>
          {t('l_common_areYouSure')}
        </TextInModalBox>
      </DialogModal>
    </>
  )
}

const TextInModalBox = styled.span`
  font-size: 14px;
`

const TimelineToolbarBox = styled.div`
  padding-right: 24px;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    display: none;
  }
`

const StyledPrimaryButton = styled.label`
  background: var(--button-primary-default);
  border: 0;
  border-radius: 4px;
  color: var(--button-primary-text);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.4s linear;

  &:hover {
    background: var(--button-primary-hover);
  }

  &:focus, &:active {
    background: var(--button-primary-hover);
  }
`

const HiddenFilesInput = styled.input`
  display: none;
`
