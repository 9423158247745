import { getModalsRootElement } from 'constants/modals'

import React, { useCallback, useEffect, useRef, useState } from 'react'

import ReactDOM from 'react-dom'
import { PageOverlay,SpriteIcon } from '@cloudike/web_ui_components'
import styled from 'styled-components'
import { useAppDispatch } from  "store"
import { useDocumentKeyDown } from 'features/common/hooks/useDocumentKeyDown'
import classNames from "classnames"

import { useMobileDetection } from "../../common/hooks"
import {
  ArrowBox,
  ArrowContainer,
  ArrowIcon,
  ImageBox,
  Img,
  MainContent,
  PreviewModalBox,
  SHiddenImg,
  StyledSpinner,
  TopRow,
} from "../../common/preview-modal/styles"
import { getUserSettingsByPathSelector } from "../../user/selectors"
import { USER_SETTINGS_PATHS } from "../../user/constants"

import { getCurrentPhotoPreviewTransformerItemIndexSelector,
  getCurrentPhotoPreviewTransformerModalStatusSelector,
  getPhotoPreviewTransformerErrorSelector,
  getPhotoPreviewTransformerItemsSelector,
  getPhotoPreviewTransformerLoadingMoreSelector,
  getPhotoPreviewTransformerTotalItemsCountSelector } from "./selectors"
import { handleNextPhotoTransformerThunk, handlePrevPhotoTransformerThunk, photoPreviewTransformerActions } from "./photoPreviewTransformerSlice"
import { PhotoPreviewTransformerToolbar } from "./PhotoPreviewTransformerToolbar"

export const PhotoPreviewTransformerModal = () => {
  const dispatch = useAppDispatch()

  const isMobile = useMobileDetection()
  const items = getPhotoPreviewTransformerItemsSelector()
  const totalItemsCount = getPhotoPreviewTransformerTotalItemsCountSelector()
  const currentIndex = getCurrentPhotoPreviewTransformerItemIndexSelector()
  const isModalOpened = getCurrentPhotoPreviewTransformerModalStatusSelector()
  const isLoading = getPhotoPreviewTransformerLoadingMoreSelector()
  const hasError = getPhotoPreviewTransformerErrorSelector()

  const isEnableTransformerPhoto = getUserSettingsByPathSelector(USER_SETTINGS_PATHS.TRANSFORMER)?.enabled

  // const sdkType = getCurrentPhotoPreviewSdkTypeSelector()

  const [previewUrl, setPreviewUrl] = useState(null)

  const [currentItem, setCurrentItem] = useState<any>(items[currentIndex])

  const imgRef = useRef(null)

  useEffect(() => {
    setCurrentItem(items[currentIndex])
  }, [currentIndex, items, isModalOpened])

  const loadingTimeoutRef = useRef(null)
  const abortControllerRef = useRef(null)


  const handleFinishLoading = () => {
    dispatch(photoPreviewTransformerActions.setLoadingMore(false))
    clearTimeout(loadingTimeoutRef.current)
  }

  const handleClose = () => {
    dispatch(photoPreviewTransformerActions.setCurrentItemIndex(0))
    dispatch(photoPreviewTransformerActions.setVisiblePreview(false))
  }

  const handleNext = useCallback(() => {

    if (currentIndex >= totalItemsCount - 1 || !isModalOpened) {
      return
    }

    dispatch(handleNextPhotoTransformerThunk())

  }, [currentIndex, totalItemsCount, isModalOpened])

  const handlePrev = useCallback(() => {

    if (currentIndex <= 0 || !isModalOpened) {
      return
    }

    dispatch(handlePrevPhotoTransformerThunk())

  }, [currentIndex, isModalOpened])

  useDocumentKeyDown(37, handlePrev)
  useDocumentKeyDown(39, handleNext)
  useDocumentKeyDown(27, handleClose)

  const handleError = () => {
    dispatch(photoPreviewTransformerActions.setLoadingMore(false))
    dispatch(photoPreviewTransformerActions.setError(true))
  }

  useEffect(() => {
    clearTimeout(loadingTimeoutRef.current)
    setPreviewUrl(null)

    if (abortControllerRef.current) {
      abortControllerRef.current.abort()
      abortControllerRef.current = null
    }

    const fetchFileData = async () => {
      dispatch(photoPreviewTransformerActions.setError(false))
      dispatch(photoPreviewTransformerActions.setLoadingMore(true))

      try {
        abortControllerRef.current = new AbortController()

        setPreviewUrl(currentItem.results[0])
        if (currentItem.results[0] === previewUrl) return handleFinishLoading()

        clearTimeout(loadingTimeoutRef.current)

        loadingTimeoutRef.current = setTimeout(() => {
          dispatch(photoPreviewTransformerActions.setLoadingMore(false))
          dispatch(photoPreviewTransformerActions.setError(true))
        }, 60000)
      } catch (error) {
        if (error?.name === 'AbortError') {
          return
        }

        dispatch(photoPreviewTransformerActions.setLoadingMore(false))
        dispatch(photoPreviewTransformerActions.setError(true))
      }
    }

    if (currentItem) {
      fetchFileData()
    }
  }, [currentItem])

  const prevImageUrl = items[currentIndex - 1]?.type === 'image' ? items[currentIndex - 1]?.results[0] : ''
  const nextImageUrl = items[currentIndex + 1]?.type === 'image' ? items[currentIndex + 1]?.results[0] : ''

  return ReactDOM.createPortal(
    <>
      {
        isModalOpened && isEnableTransformerPhoto && (
          <PhotoPreviewModalBox alignItems={ isMobile && 'flex-start'}>
            <SHiddenImg src={prevImageUrl} />

            <SHiddenImg src={nextImageUrl} />

            <TopRow>
              {!!currentItem && (
                <PhotoPreviewTransformerToolbar />
              )}
            </TopRow>

            <MainContentBox height={ isMobile ? 'calc(100%)' : 'calc(100% - 120px)'}>

              <ArrowContainer>
                {currentIndex > 0 && (
                  <ArrowBox
                    onClick={handlePrev}
                  >
                    <ArrowIcon iconName="arrow_left" />
                  </ArrowBox>
                )
                }
              </ArrowContainer>

              <ImageBox>

                {
                  !!currentItem && !!previewUrl && !hasError && (
                    <Img
                      ref={imgRef}
                      src={previewUrl}
                      onLoad={handleFinishLoading}
                      onError={handleError}
                      fetchPriority='high'
                      className={classNames({ 'not-loaded' : isLoading })}
                    />
                  )
                }

                {
                  hasError && (
                    <>
                      <SpriteIcon iconName={'error'} />

                      <span>
Проблемы с загрузкой фото попробуйте переоткрыть просмотр
                      </span>
                    </>
                  )
                }

                {(isLoading) && <StyledSpinner color='#E1E1E2' />}

                {isMobile && <ShadowLayerTop />}
              </ImageBox>

              <ArrowContainer className="right">
                {currentIndex < totalItemsCount - 1 && (
                  <ArrowBox
                    className="rotated"
                    onClick={handleNext}
                  >
                    <ArrowIcon iconName="arrow_left" />
                  </ArrowBox>
                )
                }
              </ArrowContainer>
            </MainContentBox>

          </PhotoPreviewModalBox>
        )}

      <PageOverlay
        active={isModalOpened}
        backgroundColor="rgba(0, 0, 0, 0.8)"
      />

    </>, getModalsRootElement()
  )
}


const MainContentBox = styled(MainContent)`
  height: ${props => props.height};
`

const PhotoPreviewModalBox = styled(PreviewModalBox)`
  align-items: ${props => props.alignItems}
`

const ShadowLayerTop = styled.div`
  position: absolute;
  top: 0;
  height: 142px;
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.00) 100%);
`
