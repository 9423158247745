import { useEffect } from "react"

import { getTelegramTokenThunk,userActions } from 'features/user/userSlice'
import { useAppDispatch } from  "store"
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { getTelegramTokenSelector } from 'features/user/selectors'
import { PrimaryButton, SpriteIcon } from '@cloudike/web_ui_components'

import { NOTIFICATION_TYPES, showNotification } from "../../../common/notifications"
import { LOADING_STATUSES } from "../../../../constants/loadingStatuses"

export const ProfileTelegramBot = ({ className = '' }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const telegramBot = getTelegramTokenSelector()

  useEffect(() => {
  	return () => {
      dispatch(userActions.setTelegramBotToken(''))
  	}
  }, [])

  const handleIconClick = () => {
    navigator.clipboard.writeText(telegramBot.token).then(() => {
      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: t('l_settings_token_copied_to_buffer')
      })
    })
  }

  const handleGetToken = () => {
    dispatch(getTelegramTokenThunk())
  }

  return (
    <ProfileTelegramBotBox className={className}>
      <Title>
        {t('l_settings_connecting_telegram_bot')}
      </Title>

      <SubTitle>
        {t('l_settings_connecting_telegram_bot_request_token')}
      </SubTitle>


      <PrimaryButton onAction={handleGetToken}
        disabled={telegramBot.status === LOADING_STATUSES.LOADING}
      >
        {t('l_settings_get_token')}
      </PrimaryButton>

      {telegramBot?.token && (
        <TokenBox>
          <TokenText>
            {telegramBot.token}
          </TokenText>

          <LincIconBox iconName={'link'}
            onClick={handleIconClick}
          />

        </TokenBox>
      )}


    </ProfileTelegramBotBox>
  )
}

const ProfileTelegramBotBox = styled.div`
  background: var(--background-default);
  margin-top: 16px;
`

const LincIconBox = styled(SpriteIcon)`
  margin-left: 25px;
  cursor: pointer;
`

const Title = styled.h2`
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: var(--text-primary);
`

const TokenText = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: var(--text-primary);
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
`

const TokenBox = styled.div`
  margin: 15px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const SubTitle = styled.h1`
  margin-bottom: 15px;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: var(--text-primary);
`
