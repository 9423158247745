import { useAppSelector } from "store"
import { createSelector } from "@reduxjs/toolkit"
import _ from "lodash"

import { USER_SETTINGS_PATHS } from "./constants"

export const getUserDataSelector = () => useAppSelector(state => state.user.userData)

export const getUserSubscriptionsSelector = () => useAppSelector(state => state.user.subscriptions)

export const getUserPromocodesSelector = () => useAppSelector(state => state.user.promocodes)

export const getUserTokensSelector = () => useAppSelector(state => state.user.tokens)

export const getUserStatisticsSelector = () => useAppSelector(state => state.user.statistics)

export const getUserEventsSelector = () => useAppSelector(state => state.user.events.items)

export const getUserEventsDataSelector = () => useAppSelector(state => state.user.events)

export const getBasicAuthSelector = () => useAppSelector(state => state.user.basicAuth)

export const getUserNameModalStatusSelector = () => useAppSelector(state => state.user.userNameModalOpened)

export const getUserPasswordModalStatusSelector = () => useAppSelector(state => state.user.userPasswordModalOpened)

export const getTelegramTokenSelector = () => useAppSelector(state => state.user.telegramBot)

export const getUserSettingsSelector = () => useAppSelector(state => state.user.userSettings ?? {})

export const getUserSettingsByPathSelector = (path: USER_SETTINGS_PATHS) => useAppSelector(state => state.user.userSettings[path] ?? {})

export const getAllowPasswordChangeSelector = () => useAppSelector(
  createSelector(
    [getUserSettingsSelector],
    (settings) => {
      const ALLOW_PASSWORD_CHANGE = 'allow_password_change'
      const clientAccountSettings = _.get(settings, USER_SETTINGS_PATHS.CLIENTS_ACCOUNT, {})
      return _.get(clientAccountSettings, ALLOW_PASSWORD_CHANGE, false)
    }
  ))

export const getUiTheme = () => useAppSelector(state => state.user.uiTheme)
export const getTheme = () => useAppSelector(state => state.user.theme)
