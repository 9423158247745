import { STYLED_VARIABLES } from 'constants/styledVariables'
import { formatImgUrl } from 'constants/configs/theme.config'

import React from 'react'

import styled from 'styled-components'
import { SecondaryButton } from '@cloudike/web_ui_components'

import { getUiTheme } from "../../user/selectors"
import { THEME } from "../../user/userSlice"

interface EmptyAlbumPlaceholderProps {
    className?: string,
    texts: {
        title: string,
        description: string,
        uploadBtn: string
    },
    onButtonClick: () => void
}

export const EmptyAlbumPlaceholder: React.FC<EmptyAlbumPlaceholderProps> = ({ className = '', texts, onButtonClick }) => {
  const theme = getUiTheme()

  return (
    <EmptyAlbumPlaceholderBox className={className}>
      {theme === THEME.LIGHT ? <ContainerImg src={formatImgUrl("/assets/placeholders-images/no-photos.svg")} /> :
        <ContainerImg src={formatImgUrl("/assets/placeholders-images-black/no-photos.svg")} />}

      <EmptyTitle>
        {texts.title}
      </EmptyTitle>

      <EmptyText>
        {texts.description}
      </EmptyText>

      {!!onButtonClick && (
        <StyledSecondaryButton
          actionName={texts.uploadBtn}
          onAction={onButtonClick}
        />
      )}
    </EmptyAlbumPlaceholderBox>
  )
}

const EmptyAlbumPlaceholderBox = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 0;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: calc(100vh - 16rem);
`

const ContainerImg = styled.img`
  width: 292px;
  height: 186px;
  pointer-events: none;
`

const EmptyTitle = styled.h2`
  margin-top: 40px;
  font-weight: 400;
  font-size: 24px;
  line-height: 37px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--text-primary);
  max-width: 280px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    line-height: 32px;
    max-width: unset;
    font-weight: 500;
  }
`

const EmptyText = styled.p`
  margin-top: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: none;
  align-items: center;
  text-align: center;
  color: var(--text-primary);

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    display: flex;
  }
`

const StyledSecondaryButton = styled(SecondaryButton)`
  margin-top: 32px;
  height: 40px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    margin-top: 40px;
  }
`
