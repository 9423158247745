import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SDK_TYPES } from 'sdk/sdkConstants'
import { RootState } from 'store'

import { transformerApi } from "../../../api/transformerApi"
import { checkDuplicateToChecksum, getTransformerId } from "../../../utils/utils"
import { timelineActions } from "../timeline/timelineSlice"
import { NOTIFICATION_TYPES, showNotification } from "../../common/notifications"

export enum PREVIEW_TYPES {
  TIMELINE = 'TIMELINE',
}

interface State {
  type: PREVIEW_TYPES,
  sdkType: SDK_TYPES,
  currentItemIndex: number,
  isModalOpened: boolean,
  isWidgetTransformerVisible: boolean,
  loadingTransformer: boolean,
  loadingMore: boolean,
  totalItemsCount: number,
  hasError: boolean,
  isViewed: boolean,
  indexQueue: number,
  inProgress: number,
  maxInProgress: number,
  errorArray: any[],
  startArray: any[],
  intervalIdsArray: any[],
}

const adapter = createEntityAdapter<any>()

const checkTransformationFinished = ({ url, transformationId, jwt, callback, dispatch, checksum }) => {
  const intervalId = setInterval(async () => {
    try {
      const resultTransformation = await transformerApi.getResultTransformation({
        url, transformationId, jwt
      })

      if (resultTransformation?.results.length !== 0 || !!resultTransformation?.error) {
        clearInterval(intervalId)
        dispatch(actions.removeIntervalId(intervalId))
        callback(resultTransformation, checksum)
      }

    } catch (error) {
      console.log(error)
    }
  }, 1000)

  dispatch(actions.addIntervalId(intervalId))
}

export const transformingPhotoThunk = createAsyncThunk(
  'photoPreviewTransformer/transformingPhotoThunk',
  function(selectedItems: any[], { dispatch, getState }) {
    dispatch(actions.setLoading(true))
    dispatch(actions.toggleTransformerWidget(true))

    const state = getState() as RootState

    const startArray = state.photoPreviewTransformer.startArray
    const errorArray = state.photoPreviewTransformer.errorArray
    const inProgress = state.photoPreviewTransformer.inProgress

    const newArray = checkDuplicateToChecksum(startArray, errorArray, selectedItems)
    dispatch(timelineActions.unselectAll())

    if (startArray.length === newArray.length) {
      !inProgress && dispatch(actions.setLoading(false))

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: !!inProgress ? 'Все выбранные фото уже в процессе улучшения' : 'Все выбранные фото уже были улучшены',
      })
      return
    }

    const filterArray = startArray.length === 0 ? selectedItems : newArray
    dispatch(actions.setStartArray(filterArray))
  }
)

export const fetchTransformerThunk = createAsyncThunk(
  'photoPreviewTransformer/handleNextPhotoTransformerThunk',
  async function({ callback: handleCallback }: any, { dispatch, getState }) {
    const state = getState() as RootState
    const indexQueue = state.photoPreviewTransformer.indexQueue
    const startArray = state.photoPreviewTransformer.startArray
    const item = startArray[indexQueue]

    try {


      console.log(item)

      const {
        _links: {
          content: { href: contentLink }
        }
      } = item

      console.log(contentLink)

      dispatch(actions.setIndexQueue(indexQueue + 1))
      dispatch(actions.upInProgress())

      const rsp = await transformerApi.getTransformJWT()
      const jwtToken = rsp.jwt_token
      const transformerLink = rsp._links?.transformer?.href

      const rspContent = await transformerApi.getContentPhoto(contentLink)
      const { file_name,
        _links: { data: { href: downloadLink } }
      } = rspContent

      const transformerParamsData = {
        file_name,
        file_url: downloadLink,
        transformation_name: 'gfpgan'
      }

      const transformerId = getTransformerId()

      await transformerApi.transformationPhoto({
        url: transformerLink, transformerParamsData, transformationId: transformerId, jwt: jwtToken
      })

      checkTransformationFinished({ url: transformerLink, transformationId: transformerId, jwt: jwtToken, callback: handleCallback, dispatch, checksum: item.checksum })

    } catch (e) {
      dispatch(actions.addErrorArray({ ...item, error: 'Error request', ...e }))
      console.log(e)
    }

  }
)

export const handleNextPhotoTransformerThunk = createAsyncThunk(
  'photoPreviewTransformer/handleNextPhotoTransformerThunk',
  async function(_, { dispatch, getState }) {
    const state = getState() as RootState
    const currentIndex = state.photoPreviewTransformer.currentItemIndex
    const totalItemsCount = state.photoPreviewTransformer.totalItemsCount

    if(currentIndex < totalItemsCount - 1) {
      dispatch(actions.setError(false))
      dispatch(actions.setCurrentItemIndex(currentIndex + 1))
      dispatch(actions.setLoadingMore(true))
    }
  }
)

export const handlePrevPhotoTransformerThunk = createAsyncThunk(
  'photoPreviewTransformer/handlePrevPhotoTransformerThunk',
  async function(_, { dispatch, getState }) {
    const state = getState() as RootState
    const currentIndex = state.photoPreviewTransformer.currentItemIndex

    if(currentIndex > 0) {
      dispatch(actions.setError(false))
      dispatch(actions.setCurrentItemIndex(currentIndex - 1))
      dispatch(actions.setLoadingMore(true))
    }
  }
)

export const photoPreviewTransformerSelectors = adapter.getSelectors()

export const photoPreviewTransformerSlice = createSlice({
  name: 'photoPreviewTransformer',
  initialState: adapter.getInitialState<State>({
    type: PREVIEW_TYPES.TIMELINE,
    sdkType: SDK_TYPES.DEFAULT,
    currentItemIndex: 0,
    isModalOpened: false,
    isWidgetTransformerVisible: false,
    loadingTransformer: false,
    totalItemsCount: 0,
    loadingMore: true,
    hasError: false,
    isViewed: false,
    indexQueue: 0,
    inProgress: 0,
    maxInProgress: 3,
    errorArray: [],
    startArray: [],
    intervalIdsArray: [],
  }),
  reducers: {
    updateItem: (state, action) => {
      adapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      })
    },
    addTransformedPhoto: (state, action) => {
      adapter.addOne(state, action.payload)
    },
    setAllItems: (state, action) => {
      adapter.setAll(state, action.payload)
    },
    setTotalItemsCount: (state, action: PayloadAction<number>) => {
      state.totalItemsCount = action.payload
    },
    upInProgress: (state, action: PayloadAction<number>) => {
      state.inProgress = state.inProgress + 1
    },
    downInProgress: (state, action: PayloadAction<number>) => {
      state.inProgress = state.inProgress - 1
    },
    addErrorArray: (state, action) => {
      state.errorArray.push(action.payload)
    },
    addIntervalId: (state, action) => {
      state.intervalIdsArray.push(action.payload)
    },
    removeIntervalId: (state, action) => {
      state.intervalIdsArray = state.intervalIdsArray.filter(item => item !== action.payload)
    },
    setIndexQueue: (state, action: PayloadAction<number>) => {
      state.indexQueue = action.payload
    },
    setStartArray: (state, action) => {
      state.startArray = action.payload
    },
    setCurrentItemIndexById: (state, action: PayloadAction<string>) => {
      const ids = adapter.getSelectors().selectIds(state)
      const indexOfItem = ids.indexOf(action.payload)

      state.currentItemIndex = indexOfItem
    },
    setCurrentItemIndex: (state, action) => {
      state.currentItemIndex = action.payload
    },
    setVisiblePreview: (state, action) => {
      state.isModalOpened = action.payload
    },
    toggleTransformerWidget: (state, action) => {
      state.isWidgetTransformerVisible = action.payload
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loadingTransformer = action.payload
    },
    setLoadingMore: (state, action: PayloadAction<boolean>) => {
      state.loadingMore = action.payload
    },
    setError: (state, action: PayloadAction<boolean>) => {
      state.hasError = action.payload
    },
    resetState: (state) => {
      adapter.removeAll(state)
      state.currentItemIndex = 0
      state.isModalOpened = false
      state.loadingTransformer = false
      state.loadingMore = true
      state.hasError = false
      state.indexQueue = 0
      state.inProgress = 0
      state.totalItemsCount = 0
      state.errorArray = []
      state.startArray = []
      state.intervalIdsArray = []
    }
  }
})

const {
  reducer, actions
} = photoPreviewTransformerSlice

export { reducer as photoPreviewTransformerReducer, actions as photoPreviewTransformerActions }
