import { LOADING_STATUSES } from 'constants/loadingStatuses'

import { PayloadAction, createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { getFilesSdk, getFilesWS } from 'sdk/files'
import { OrderType, SelectType } from '@cloudike/web_ui_components'
import { IFsNodeSchema, NodeTypes } from '@cloudike/web_files'
import { hideGlobalProgressLoader, showGlobalProgressLoader } from 'features/common/app-progress-bar'
import { NOTIFICATION_TYPES, showNotification } from 'features/common/notifications'
import { t } from 'i18next'
import { RootState } from 'store'
import _ from 'lodash'

import { publicLinksApi } from "../../api/publicLinksApi"
import {
  selectFileListItemReducer,
  CheckboxVisibilityType,
  selectAllFileListItemsReducer,
  unselectAllFileListItemsReducer,
  selectFileListItemWithPressedCtrlReducer,
  selectFileListItemWithPressedShiftReducer
} from "../../utils/filesListSelection"
import { debouncedCheckPublicLinksTotalItemsCounts } from "../public-links-albums/publicLinksSlice"

export enum SortColumns {
  NAME = 'name',
  MODIFIED = 'updated',
  SIZE = 'file_info.size',
}

export enum ReplaceModalTypes {
  REPLACE = 'replace',
  KEEP_BOTH = 'keep_both'
}

const adapter = createEntityAdapter<IFsNodeSchema>()

export const filesSelectors = adapter.getSelectors()

const sortNodes = (nodes, { by, direction }) => {
  const orderByType = (entity) => entity.type

  return _.chain(nodes)
    .orderBy(by === 'name' ? node => node.name.toUpperCase() : by, [direction])
    .orderBy(orderByType)
    .value()
}

const initialState = {
  status: LOADING_STATUSES.LOADING,
  error: null,
  sort: { by: SortColumns.NAME, direction: OrderType.ASK },
  selectedItemsIds: [],
  renamingItemId: null,
  currentFolder: null,
  currentFolderId: '',
  selectType: SelectType.NONE,
  lastSelectedIndex: null,
  lastSelectedIndexes: [],
  lastSelectedWithShiftIndex: null,
  checkboxVisibility: CheckboxVisibilityType.HIDDEN,
  replaceFilesNodeModalData: {
    opened: false,
    nodeType: NodeTypes.DIR,
    name: null,
    type: ReplaceModalTypes.KEEP_BOTH
  }
}

export const subscribePublicLinksFilesToWSThunk = createAsyncThunk(
  'publicLinksFiles/subscribePublicLinksFilesToWSThunk',
  async function (_, { dispatch, getState }) {
    const filesWs = getFilesWS()

    filesWs.addEventListener('fs2', ({ output }) => {
      const state = getState() as RootState
      const changes = output?.changes || []
      const currentFilesItems = filesSelectors.selectAll(state.publicLinksFiles)

      changes.forEach(change => {
        if (change.action === 'changed') {
          const existedNode = currentFilesItems.find(n => n.id === change.node_id)

          if (change.fields.is_shared === false && existedNode) {
            dispatch(actions.deleteItems([existedNode.id]))
            dispatch(actions.unselectAll())
          }

          if (change.fields.is_shared) {
            const node = {
              created: change.created,
              updated: change.updated,
              name: change.name,
              id: change.node_id,
              type: change.type,
              parent_id: change.parent_id,
              is_trashed: false,
              is_explicitly_trashed: false,
              is_shared: false,
              _embedded: change._embedded || (existedNode as any)?._embedded,
              ...(change?.fields ? change.fields : {})
            }

            dispatch(actions.addItem(node))
          }

          debouncedCheckPublicLinksTotalItemsCounts(dispatch)
        }
      })
    })
  }
)

export const unSubscribePublicLinksFilesFromWSThunk = createAsyncThunk(
  'publicLinksFiles/unSubscribePublicLinksFilesFromWSThunk',
  async function () {
    const filesWs = getFilesWS()

    filesWs.removeEventListener('fs2')
  }
)

export const fetchNodesThunk = createAsyncThunk(
  'publicLinksFiles/fetchNodesThunk',
  async (_, { getState }) => {
    const userId = (getState() as RootState).user.userData.id

    const response = await publicLinksApi.getSharedWithMeNodes(userId, { preview: true, preview_jwt: true, limit: 100, total_count: false })

    return response.filter(node => !node.is_trashed)
  }
)

export const downloadNodesThunk = createAsyncThunk(
  'publicLinksFiles/downloadNodesThunk',
  async ({ ids }: { ids: string[] }, { getState }) => {
    const state = getState() as RootState
    const nodes = filesSelectors.selectAll(state.publicLinksFiles)

    showGlobalProgressLoader()

    try {
      const sdk = getFilesSdk()

      let link

      if (ids.length === 1) {
        const nodeType = nodes.find(node => node.id === ids[0]).type

        if (nodeType === NodeTypes.DIR) {
          link = await sdk.folderOperationsService.getZipStreamLink(ids)
        } else {
          link = await sdk.folderOperationsService.getDownloadLink(ids[0])
        }
      } else {
        link = await sdk.folderOperationsService.getZipStreamLink(ids)
      }

      window.location.href = link
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_somethingWrongTryAgain')
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const publicLinksFilesSlice = createSlice({
  name: 'publicLinksFiles',
  initialState: adapter.getInitialState(initialState),
  reducers: {
    addItem: (state, action) => {
      adapter.removeOne(state, action.payload.id)
      adapter.addOne(state, action.payload)
    },
    updateItem: (state, action) => {
      adapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      })
    },
    setAll: (state, action) => {
      const nodes = sortNodes(action.payload, state.sort)

      adapter.setAll(state, nodes)
    },
    selectItem: selectFileListItemReducer(filesSelectors),
    selectItemWithPressedCtrl: selectFileListItemWithPressedCtrlReducer(filesSelectors),
    selectItemWithPressedShift: selectFileListItemWithPressedShiftReducer(filesSelectors),
    selectAll: selectAllFileListItemsReducer,
    unselectAll: unselectAllFileListItemsReducer,
    setSort: (state, action) => {
      state.sort = action.payload

      const nodes = sortNodes(filesSelectors.selectAll(state), state.sort)

      adapter.setAll(state, nodes)
    },
    setCurrentFolder: (state, action) => {
      state.currentFolder = action.payload
    },
    setCurrentFolderId: (state, action) => {
      state.currentFolderId = action.payload
    },
    deleteItems: (state, action: PayloadAction<string[]>) => {
      adapter.removeMany(state, action.payload)
    },
    resetState: (state) => {
      adapter.removeAll(state)

      Object.keys(initialState).forEach(key => {
        state[key] = initialState[key]
      })
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchNodesThunk.pending, (state) => {
        state.status = LOADING_STATUSES.LOADING
      })
      .addCase(fetchNodesThunk.fulfilled, (state, action) => {
        state.status = LOADING_STATUSES.SUCCEEDED

        const nodes = sortNodes(action.payload, state.sort)

        adapter.setAll(state, nodes)
      })
      .addCase(fetchNodesThunk.rejected, (state, action) => {
        state.status = LOADING_STATUSES.FAILED
        state.error = action.error.message
      })
  },
})

export default publicLinksFilesSlice.reducer

const {
  reducer, actions
} = publicLinksFilesSlice

export { reducer as publicLinksFilesReducer, actions as publicLinksFilesActions }
