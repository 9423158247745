import { getModalsRootElement } from 'constants/modals'
import { STYLED_VARIABLES } from 'constants/styledVariables'

import { useState } from 'react'

import { MenuItem, MenuWithActionItems, PrimaryButton } from '@cloudike/web_ui_components'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from "store"
import { DialogModal } from 'ui/DialogModal'
import { useNavigate } from 'react-router-dom'
import { AlbumType } from '@cloudike/web_photos'
import { SDK_TYPES } from 'sdk/sdkConstants'
import { MobileToolbar } from 'features/common/right-sidebar/MobileToolbar'
import { appActions } from 'store/app'
import { analytics, ANALYTICS_EVENTS } from 'features/common/analytics'
import { IAlbumSchema } from "@cloudike/web_photos/dist/types/intarfaces/IAlbumSchema"
import _ from "lodash"

import { sharingActions } from '../../sharing/sharingSlice'
import { RenameAlbumModal } from '../album/RenameAlbumModal'
import { albumActions, createNewEmptyAlbumThunk } from '../album/albumSlice'
import { getAlbumsSeasonsSelectedItemsSelector } from "../albums-season/selectors"
import { MobileToolbarCounter } from "../../common/mobile-toolbar-counter/MobileToolbarCounter"
import { getUserDataSelector } from "../../user/selectors"

import { getAlbumsSelectedItemsSelector, getLoadingAlbumSelector } from './selectors'
import { downloadAlbumThunk, removeAlbumsThunk, renameAlbumThunk } from './albumsSlice'

interface AlbumsToolbarProps {
  className?: string,
  type: SDK_TYPES,
  isSeason?: boolean,
}

export const AlbumsToolbar: React.FC<AlbumsToolbarProps> = ({ className = '', type, isSeason = false }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const isLoading = getLoadingAlbumSelector()

  const selectedItems = [...getAlbumsSelectedItemsSelector(), ...getAlbumsSeasonsSelectedItemsSelector()]
  const selectedItemsCount = selectedItems.length
  const userData = getUserDataSelector()

  const isSelectedSeason = selectedItems.some(item => item.type === AlbumType.SMART)

  const [removingConfirmationModalOpened, toggleRemovingConfirmationModal] = useState(false)
  const [renameModalOpened, toggleRenameModal] = useState(false)

  const handleDownloadAlbum = () => {
    dispatch(downloadAlbumThunk(selectedItems[0].id))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleCreateNewAlbum = () => {
    if (isLoading) return

    const callback = (id: string) => {
      if (type === SDK_TYPES.DEFAULT) {
        navigate(`/photos/albums/${id}`)
      }

      if (type === SDK_TYPES.FAMILY) {
        navigate(`/family/photos/albums/${id}`)
      }
    }

    if (type === SDK_TYPES.DEFAULT) {
      analytics.push(ANALYTICS_EVENTS.WEB_PHOTOS_ALBUM_CREATE)
    }

    if (type === SDK_TYPES.FAMILY) {
      analytics.push(ANALYTICS_EVENTS.WEB_FC_ALBUM_CREATE)
    }

    dispatch(createNewEmptyAlbumThunk({ type, name: '', callback }))
    dispatch(albumActions.toggleAlbumNameEditStatus(true))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleOpenRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(true)
  }

  const handleCloseRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(false)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleOpenRenameModal = () => {
    toggleRenameModal(true)
  }

  const handleCloseRenameModal = () => {
    toggleRenameModal(false)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleRemoveAlbums = () => {
    dispatch(removeAlbumsThunk(selectedItems))
    handleCloseRemovingConfirmationModal()

    analytics.push(ANALYTICS_EVENTS.WEB_ALBUM_REMOVE_CLICK)

    if (type === SDK_TYPES.DEFAULT) {
      analytics.push(ANALYTICS_EVENTS.WEB_PHOTOS_OUTSIDE_ALBUM_DELETE)
    }

    if (type === SDK_TYPES.FAMILY) {
      analytics.push(ANALYTICS_EVENTS.WEB_FC_OUTSIDE_ALBUM_DELETE)
    }
  }

  const handleShare = (albumData: IAlbumSchema | null) => {
    dispatch(sharingActions.setSharingAlbumConfig({
      sdkType: type,
      sharedAlbumType: AlbumType.SHARED
    }))
    dispatch(sharingActions.setSharedItemData(selectedItems[0]))

    if(!_.isNil(albumData) && albumData?.shared_hash) {
      analytics.push(ANALYTICS_EVENTS.WEB_ALBUM_EDIT_LINK)
    } else {
      analytics.push(ANALYTICS_EVENTS.WEB_ALBUM_CREATE_LINK)
    }
  }

  const handleRenameAlbum = (name) => {
    if (type === SDK_TYPES.FAMILY) {
      analytics.push(ANALYTICS_EVENTS.WEB_FC_OUTSIDE_ALBUM_RENAME)
    } else {
      analytics.push(ANALYTICS_EVENTS.WEB_PHOTOS_ALBUM_OUTSIDE_RENAME)
    }

    dispatch(renameAlbumThunk({ name: name.trim(), id: selectedItems[0].id }))
    toggleRenameModal(false)
  }

  const menuItems = (() => {
    let items = isSelectedSeason ? [] : [{
      label: t('a_common_remove'),
      iconName: 'remove_icon',
      onClickItem: handleOpenRemovingConfirmationModal
    }]

    if (selectedItemsCount < 2) {
      items = [
        {
          label: t('a_common_share'),
          iconName: 'share',
          onClickItem: handleShare.bind(null,selectedItems.length && selectedItems[0])
        },
        !isSelectedSeason && {
          label: t('a_common_rename'),
          iconName: 'pencil',
          onClickItem: handleOpenRenameModal
        },
        ...items
      ]
    }

    return items.filter(item => !!item)
  })()

  const mobileMenuItems = (() => {
    let items: React.ComponentProps<typeof MenuItem>[] = []

    if (!selectedItemsCount) {
      items.push({
        label: t('a_common_createAlbumButton'),
        iconName: 'photo_album',
        onClickItem: handleCreateNewAlbum
      })
    }

    if (selectedItemsCount === 1) {
      items.push({
        label: t('a_albums_downloadAlbum'),
        iconName: 'download_empty',
        onClickItem: handleDownloadAlbum
      })
    }

    if (!!selectedItemsCount) {
      items = [...items, ...menuItems]
    }

    return items
  })()

  if (type === SDK_TYPES.FAMILY && !userData?.family_user_id) {
    return null
  }

  return (
    <>
      <AlbumsToolbarBox className={className}>
        {
          selectedItemsCount === 1 && (
            <StyledPrimaryButton
              actionName={t('a_albums_downloadAlbum')}
              onAction={handleDownloadAlbum}
            />
          )
        }

        {
          !selectedItemsCount && !isSeason && (
            <StyledPrimaryButton
              actionName={t('a_common_createAlbumButton')}
              onAction={handleCreateNewAlbum}
            />
          )}

        {
          !!selectedItemsCount && (
            <MenuWithActionItems menuItems={menuItems}
              isBorder={selectedItemsCount <= 1}
            />
          )}
      </AlbumsToolbarBox>

      <MobileToolbar
        items={mobileMenuItems}
        headContent={!!selectedItemsCount && (
          <MobileToolbarCounter selectedItemsCount={selectedItemsCount} />
        )}
      />

      <DialogModal
        isOpened={removingConfirmationModalOpened}
        title={t('l_common_headerRemoveAlbumName', { number: selectedItemsCount, albumname: selectedItems[0]?.description || t('l_common_untitledAlbum') })}
        onClose={handleCloseRemovingConfirmationModal}
        okText={t('a_common_ok')}
        onOk={handleRemoveAlbums}
        cancelText={t('a_common_cancel')}
        onCancel={handleCloseRemovingConfirmationModal}
      >
        <TextInModalBox>
          {t('l_common_areYouSure')}
        </TextInModalBox>
      </DialogModal>

      <RenameAlbumModal
        isShown={renameModalOpened}
        albumNameBeforeRenaiming={selectedItems[0]?.description}
        parentBlock={getModalsRootElement()}
        onCloseModal={handleCloseRenameModal}
        onRenameAlbum={handleRenameAlbum}
        texts={{
          TITLE: selectedItems[0]?.description ? t('a_albums_renameAlbum') : t('l_common_addName'),
          PLACEHOLDER: t('l_common_untitledAlbum'),
          NAME_BUTTON_CANCEL: t('a_common_cancel'),
          NAME_BUTTON_ACTION: t('a_common_save'),
          SUB_TITLE: t('l_albums_albumNameField'),
          TITLE_ICON: selectedItems[0]?.description ? 'edit' : 'add_name'
        }}
      />
    </>
  )
}

const TextInModalBox = styled.span`
  font-size: 14px;
`

const AlbumsToolbarBox = styled.div`
  padding-right: 24px;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    display: none;
  }
`

const StyledPrimaryButton = styled(PrimaryButton)`
  width: 100%;
  transition: all 0.4s linear;

  :hover {
    background-color: var(--button-primary-hover);
  }
`
