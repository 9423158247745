import { useEffect, useRef } from "react"

import { getUploadedFilesCountSelector, getUploadingFilesCountSelector } from "./selectors"

export const useUploadingFinishDetection = (callback) => {
  const uploadingFilesCount = getUploadingFilesCountSelector()
  const uploadedFilesCount = getUploadedFilesCountSelector()

  const isFirstCallRef = useRef(true)

  useEffect(() => {
    if (isFirstCallRef.current) {
      isFirstCallRef.current = false

      return
    }

    if (uploadingFilesCount > 0 && uploadedFilesCount > 0 && uploadingFilesCount === uploadedFilesCount) {
      callback()
    }
  }, [uploadingFilesCount, uploadedFilesCount])
}
