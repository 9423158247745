import { SpriteIcon, STYLED_VARIABLES } from '@cloudike/web_ui_components'
import styled from 'styled-components'
import { useAppDispatch } from  "store"
import { useTranslation } from 'react-i18next'
import { getUserSettingsByPathSelector } from 'features/user/selectors'
import { USER_SETTINGS_PATHS } from 'features/user/constants'

import { addOtherCloudThunk } from './otherCloudsSlice'
import { DROPBOX, GOOGLE_DRIVE, YANDEX_DRIVE } from './constants'
import { getUserLinkedCloudsSelector } from './selectors'

export const ProfileAddAccountButtons = ({ className = '' }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const linkedClouds = getUserLinkedCloudsSelector()
  const userSettings = getUserSettingsByPathSelector(USER_SETTINGS_PATHS.AVAILABLE_OTHER_CLOUDS)

  const isGoogleAvailable = userSettings?.enable_google_drive
  const isDropboxAvailable = userSettings.enable_dropbox && !linkedClouds.some(cloud => cloud.type === DROPBOX.name)
  const isYandexAvailable = userSettings?.enable_yandex_disk

  const handleOptionClick = (source) => {
    dispatch(addOtherCloudThunk({ source }))
  }

  return (
    <AddAccountBox className={className}>
      {
        isDropboxAvailable && (
          <SSocialBtn onClick={() => handleOptionClick(DROPBOX.name)}>
            <CloudIcon iconName="dropbox" />

            {t('a_settings_addOtherCloudAccount', { name: DROPBOX.label })}
          </SSocialBtn>
        )}

      {
        isGoogleAvailable && (
          <SSocialBtn onClick={() => handleOptionClick(GOOGLE_DRIVE.name)}>
            <CloudIcon iconName="google" />

            {t('a_settings_addOtherCloudAccount', { name: GOOGLE_DRIVE.label })}
          </SSocialBtn>
        )}

      {
        isYandexAvailable && (
          <SSocialBtn onClick={() => handleOptionClick(YANDEX_DRIVE.name)}>
            <CloudIcon iconName="yandex" />

            {t('a_settings_addOtherCloudAccount', { name: YANDEX_DRIVE.label })}
          </SSocialBtn>
        )}
    </AddAccountBox>
  )
}

const AddAccountBox = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    flex-direction: row;
  }
`

const SSocialBtn = styled.button`
  height: 39px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: var(--text-primary);
  border-radius: 4px;
  border: 1px solid var(--button-secondary-default-border);
  
  & ~ & {
    margin-left: 8px;
  }

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    & ~ & {
      margin-left: 0;
      margin-top: 8px;
    }
  }
`

const CloudIcon = styled(SpriteIcon)`
  margin-right: 8px;
`
