import { WS_EVENTS_NAMES } from 'constants/wsEventsNames'
import { LOADING_STATUSES } from 'constants/loadingStatuses'
import { PHOTOS_COUNT_PER_PAGE } from 'constants/pagination'

import { IItemSchema } from '@cloudike/web_photos/dist/types/intarfaces/IAlbumItem'
import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { hideGlobalProgressLoader, showGlobalProgressLoader } from 'features/common/app-progress-bar'
import { getPhotosWS } from 'sdk/photo'
import {
  getCurrentTimelinePaginator,
  getFamilyTimelineSdk,
  getPhotoTimelineSdk,
  getTimelineSdkByType,
  initTimelinePaginator
} from 'sdk/timeline'
import { RootState } from 'store'
import i18n from 'i18n'
import { getErrorData } from 'utils/getErrorData'
import { NOTIFICATION_TYPES, showNotification } from 'features/common/notifications'
import { SDK_TYPES } from 'sdk/sdkConstants'
import _ from 'lodash'
import { downloadItemByLink, getErrorByFieldName } from 'utils/utils'
import { AlbumType } from "@cloudike/web_photos"
import { IGetEmbeddedItemsSchema } from "@cloudike/web_photos/dist/types/intarfaces/IGetEmbeddedItemsSchema"

import { photoPreviewActions } from "../photo-preview/photoPreviewSlice"
import { getAlbumsSdkByType } from "../../../sdk/albums"
import { photoPreviewFlashbackActions } from "../photo-preview-flashback/photoPreviewFlashbackSlice"
import { sortFlashbackAlbums } from "../photo-preview-flashback/PhotoPreviewFlashbackModal"
import {
  photoPreviewDuplicateActions,
  photoPreviewDuplicateSelectors
} from "../photo-preview-duplicate/photoPreviewDuplicateSlice"
import { DATES_FORMATS } from "../../../constants/datesFormats"
import { API_UTILS } from "../../../constants/apiUtils"
import { TOTAL_COUNT_HEADER } from "../../../constants/headers"

const adapter = createEntityAdapter<IItemSchema>()

export const timelineSelectors = adapter.getSelectors()

export const SORT_FORMATS = {
  sortFormat: {
    day: "DAY",
    month: "MONTH",
    year: "YEAR",
  },
  sortGridClass: {
    day: "grid-day",
    month: "grid-month",
    year: "grid-year",
  },
}

export enum TIMELINE_FILTERS {
 all = 'ALL',
 photos = 'PHOTOS',
 videos = 'VIDEOS',
 favorites = 'FAVORITES'
}

const getCurrentTimelineType = (state: RootState) => state.timeline.type

const getCurrentTimelineFilter = (state: RootState) => state.timeline.filter

const updateFlashbackAlbums = (output, state) => {
  const flashbackAlbums = state.timeline.flashbackAlbums
  return flashbackAlbums.map((item) => {
    return item.id === output.id ? { ...output } : item
  })
}

export const subscribeTimelineToWSThunk = createAsyncThunk(
  'timeline/subscribeTimelineToWSThunk',
  async function(_, { dispatch, getState }) {
    const photosWs = getPhotosWS()

    photosWs.addEventListener(WS_EVENTS_NAMES.PHOTOS_OPERATION_DONE, ({ action, output }) => {
      const itemsType = (getState() as RootState).timeline.filter

      if (action === 'add_items') {
        if (itemsType === TIMELINE_FILTERS.favorites) {
          return
        }

        if (!Array.isArray(output) && output?.status !== 200) {
          return
        }

        dispatch(loadJustUploadedTimelineItemsThunk())

        output.forEach(item => {
          dispatch(getTimelineItemThunk({ itemId: item.detail.item_id }))
        })
      }

      if (action === 'add_favorite' && itemsType === TIMELINE_FILTERS.favorites) {
        dispatch(loadJustUploadedTimelineItemsThunk())

        output.forEach(item => {
          dispatch(getTimelineItemThunk({ itemId: item.detail.item_id }))
        })
      }

      if (action === 'delete_items' || (action === 'delete_favorite' && itemsType === TIMELINE_FILTERS.favorites)) {
        const state = getState() as RootState
        const allItemsLength = adapter.getSelectors().selectIds(state.timeline).length

        if (output.length === allItemsLength) {
          dispatch(actions.setStatus(LOADING_STATUSES.LOADING))
          dispatch(loadTimelineItemsFirstTimeThunk())
        } else {
          dispatch(actions.deleteItems(output.map(item => item.detail.item_id)))
          dispatch(actions.decreaseTotalCount(output.length))
        }

        dispatch(actions.unselectAll())

        hideGlobalProgressLoader()
      }

      if (action === 'add_favorite') {
        output.forEach(item => {
          dispatch(actions.updateItem({ id: item.detail.item_id, favorite: true }))
          dispatch(photoPreviewActions.updateItem({ id: item.detail.item_id, favorite: true }))
        })
      }

      if (action === 'delete_favorite') {
        output.forEach(item => {
          dispatch(actions.updateItem({ id: item.detail.item_id, favorite: false }))
          dispatch(photoPreviewActions.updateItem({ id: item.detail.item_id, favorite: false }))
        })
      }
    })

    photosWs.addEventListener(WS_EVENTS_NAMES.PHOTOS_ITEMS_MOVED_INTO_TRASH, ({ output }) => {
      const state = getState() as RootState
      const array = photoPreviewDuplicateSelectors.selectAll(state.photoPreviewDuplicate)
      const currentItem = array.find(item => item.id === output.item_id)

      if(state.photoPreviewDuplicate.duplicateMode && !!currentItem) {
        if (currentItem.isOrigin) {
          dispatch(photoPreviewDuplicateActions.resetState())
          dispatch(photoPreviewActions.resetState())
        } else {
          dispatch(photoPreviewDuplicateActions.deleteItem(output.item_id))
        }
      }
    })

    photosWs.addEventListener(WS_EVENTS_NAMES.PHOTOS_ITEM_UPDATED, ({ output }) => {
      const state = getState() as RootState
      const currentEntities = adapter.getSelectors().selectEntities(state.timeline)
      const currentTimelineType = getCurrentTimelineType(state)

      dispatch(photoPreviewFlashbackActions.updateItem(output))
      dispatch(photoPreviewDuplicateActions.updateItem(output))

      if (!output.id || currentTimelineType !== SDK_TYPES.DEFAULT) {
        return
      }

      if (currentEntities[output.id]) {
        dispatch(actions.updateItem(output))
        dispatch(photoPreviewActions.updateItem(output))
        return
      }
    })

    photosWs.addEventListener(WS_EVENTS_NAMES.PHOTOS_ALBUM_CHANGED, ({ output }) => {
      const state = getState() as RootState
      dispatch(actions.setFlashbackAlbums(updateFlashbackAlbums(output, state).filter(item => item.live_items_count > 0)))
    })
  }
)

export const unsubscribeTimelineFromWSThunk = createAsyncThunk(
  'timeline/unsubscribeTimelineFromWSThunk',
  async function() {
    const photosWs = getPhotosWS()

    photosWs.removeEventListener(WS_EVENTS_NAMES.PHOTOS_OPERATION_DONE)
    photosWs.removeEventListener(WS_EVENTS_NAMES.PHOTOS_ITEM_UPDATED)
    photosWs.removeEventListener(WS_EVENTS_NAMES.PHOTOS_ALBUM_CHANGED)
    photosWs.removeEventListener(WS_EVENTS_NAMES.PHOTOS_ITEMS_MOVED_INTO_TRASH)
  }
)

export const loadTimelineItemsFirstTimeThunk = createAsyncThunk(
  'timeline/loadTimelineItemsFirstTimeThunk',
  async function(_, { getState }) {
    const type = getCurrentTimelineType(getState() as RootState)
    const itemsType = getCurrentTimelineFilter(getState() as RootState)

    const options = { total_count: true } as IGetEmbeddedItemsSchema

    if (itemsType === TIMELINE_FILTERS.videos) {
      options.type = ['video']
    }

    if (itemsType === TIMELINE_FILTERS.photos) {
      options.type = ['image']
    }

    if (itemsType === TIMELINE_FILTERS.favorites) {
      options.favorite = true
    }

    const paginator = initTimelinePaginator(type, PHOTOS_COUNT_PER_PAGE, options)

    const response = await paginator.next()

    return {
      items: response.data._embedded.items,
      totalCount: parseInt(response.headers[TOTAL_COUNT_HEADER]) || 0
    }
  }
)

export const loadJustUploadedTimelineItemsThunk = createAsyncThunk(
  'timeline/loadFirstTimelineItemsThunk',
  async function(_, { getState, dispatch }) {
    const state = getState() as RootState
    const type = getCurrentTimelineType(state)
    const timelineSdk = getTimelineSdkByType(type)
    const itemsType = getCurrentTimelineFilter(state)

    const options = { offset: 0, limit: 1, total_count: true } as IGetEmbeddedItemsSchema

    if (itemsType === TIMELINE_FILTERS.videos) {
      options.type = ['video']
    }

    if (itemsType === TIMELINE_FILTERS.photos) {
      options.type = ['image']
    }

    if (itemsType === TIMELINE_FILTERS.favorites) {
      options.favorite = true
    }

    const response = await timelineSdk.getTimelineItems(options)

    // const currentItems = timelineSelectors.selectAll(state.timeline)
    // const currentItemsIds = currentItems.map(item => item.id)

    // const itemsForInsert = response.data._embedded.items.filter(item => !currentItemsIds.includes(item.id))

    // dispatch(actions.setAllItems([...itemsForInsert, ...currentItems]))
    dispatch(actions.setTotalCount(response.headers[TOTAL_COUNT_HEADER]))
    dispatch(actions.setStatus(LOADING_STATUSES.SUCCEEDED))
  }
)

export const getTimelineItemThunk = createAsyncThunk(
  'timeline/getTimelineItemThunk',
  async function({ itemId }: {itemId: string}, { getState, dispatch }) {
    const state = getState() as RootState
    const type = getCurrentTimelineType(state)
    const timelineSdk = getTimelineSdkByType(type)
    const currentItems = [...timelineSelectors.selectAll(state.timeline)].sort((a, b) => a.created_original - b.created_original)

    const itemsType = state.timeline.filter

    const response = await timelineSdk.getPhoto(itemId)
    const paginator = getCurrentTimelinePaginator(type)

    if (itemsType === TIMELINE_FILTERS.videos && response.data.type !== 'video') {
      return
    }

    if (itemsType === TIMELINE_FILTERS.photos && response.data.type !== 'image') {
      return
    }

    if (currentItems?.[0]?.created_original < response.data.created_original || !(paginator as any).nextPageUrl) {
      dispatch(actions.addItem(response.data))
    }
  }
)

export const loadMoreTimelineItemsThunk = createAsyncThunk(
  'timeline/loadMoreTimelineItemsThunk',
  async function(_, { getState }) {
    const type = getCurrentTimelineType(getState() as RootState)
    const paginator = getCurrentTimelinePaginator(type)

    const response = await paginator.next()

    return response.data._embedded.items
  }
)

export const removeSelectedItemsThunk = createAsyncThunk(
  'timeline/removeTimelineItemsThunk',
  async function({ items, type }: { items: IItemSchema[], type: SDK_TYPES}, { dispatch }) {
    try {
      const timelineSdk = getTimelineSdkByType(type)

      showGlobalProgressLoader()

      await timelineSdk.removeItems(items)

      dispatch(actions.unselectAll())

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: i18n.t('l_notification_itemsDeletedforWeb', { number: items.length })
      })
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const downloadTimelineItemsThunk = createAsyncThunk(
  'timeline/downloadTimelineItemsThunk',
  async function({ items, type }: { items: IItemSchema[], type: SDK_TYPES }, { dispatch }) {
    try {
      const timelineSdk = getTimelineSdkByType(type)
      let href

      showGlobalProgressLoader()

      if (items.length === 1) {
        const {
          _links: {
            content: { href: contentLink }
          }
        } = items[0]

        href = await downloadItemByLink(contentLink)
      } else {
        href = await timelineSdk.createItemsZipStream(items)
      }

      window.location.href = href
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    } finally {
      hideGlobalProgressLoader()
      dispatch(actions.unselectAll())
    }
  }
)

export const copyTimelineItemsToFamilyThunk = createAsyncThunk(
  'timeline/copyTimelineItemsToFamilyThunk',
  async function(items:IItemSchema[], { dispatch, getState }) {
    const state = getState() as RootState

    try {
      const familyTimelineSdk = getFamilyTimelineSdk()

      showGlobalProgressLoader()

      await familyTimelineSdk.addItemsToTimeline(items)

      dispatch(timelineSlice.actions.unselectAll())

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: i18n.t('l_common_photoToFamilyOk', { number: items.length })
      })
    } catch (error) {
      const isOperationsHasError = (operation: any) => operation.status >= 400
      const details = getErrorByFieldName(error, 'details')
      const operationsError = details.operations.find(isOperationsHasError)
      if(!operationsError) return
      const errorData = getErrorData({
        data: { ...operationsError },
        action: API_UTILS.ACTIONS_TYPES.COPY_TO_FAMILY,
        type: API_UTILS.ITEM_TYPE.PHOTO,
        isFamilyOwner: state.user.userData.is_owner_family
      })
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        isPermanent: true,
        ...errorData
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const addTimelineItemsToFamilyThunk = createAsyncThunk(
  'timeline/addTimelineItemsToFamilyThunk',
  async function({ items, callback, errorCallback }: {items: IItemSchema[], callback: () => void, errorCallback?: () => void }, { dispatch, getState }) {
    const state = getState() as RootState
    try {
      const familyTimelineSdk = getFamilyTimelineSdk()

      showGlobalProgressLoader()

      await familyTimelineSdk.addItemsToTimeline(items)

      dispatch(timelineSlice.actions.unselectAll())

      callback()
    } catch (error) {
      const isOperationsHasError = (operation: any) => operation.status >= 400
      const details = getErrorByFieldName(error, 'details')
      const operationsError = details.operations.find(isOperationsHasError)

      if (!operationsError) {
        return
      }

      const errorData = getErrorData({
        data: { ...operationsError },
        action: API_UTILS.ACTIONS_TYPES.COPY_TO_FAMILY,
        type: API_UTILS.ITEM_TYPE.PHOTO,
        isFamilyOwner: state.user.userData.is_owner_family
      })

      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        isPermanent: true,
        ...errorData
      })

      if (errorCallback) {
        errorCallback()
      }
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const copyTimelineItemsToPersonalCloudThunk = createAsyncThunk(
  'timeline/copyTimelineItemsToPersonalCloudThunk',
  async function(items:IItemSchema[], { dispatch, getState }) {
    try {
      const timelineSdk = getPhotoTimelineSdk()

      showGlobalProgressLoader()

      await timelineSdk.addItemsToTimeline(items)

      dispatch(timelineSlice.actions.unselectAll())

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: i18n.t('l_notification_copiedToPersonalCloud')
      })
    } catch (error) {
      const details = getErrorByFieldName(error, 'details')
      const operationsError = details.operations[0]

      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        isPermanent: true,
        ...getErrorData({ data: { ...operationsError }, action: API_UTILS.ACTIONS_TYPES.COPY, type: API_UTILS.ITEM_TYPE.PHOTO })
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const insertElementsToTimelineThunk = createAsyncThunk(
  'timeline/insertElementsToTimelineThunk',
  async function({ items }: {items: IItemSchema[]}, { dispatch, getState }) {
    const state = getState() as RootState
    const currentEntities = adapter.getSelectors().selectEntities(state.timeline)
    const currentItems = timelineSelectors.selectAll(state.timeline).sort((a, b) => a.created_original - b.created_original)
    const totalCount = Number(state.timeline.totalItemsCount)

    items.forEach(item => {
      if (currentEntities[item.id]) {
        dispatch(actions.updateItem(item))

        return
      }

      if (currentItems?.[0]?.created_original < item.created_original || currentItems.length >= totalCount) {
        dispatch(actions.addItem(item))
      }
    })
  }
)

export const loadFlashbackAlbumsThunk = createAsyncThunk(
  'albums/loadFlashbackAlbumsThunk',
  async function(_, { getState }) {
    const type = getCurrentTimelineType(getState() as RootState)
    const albumsSdk = getAlbumsSdkByType(type)
    const response = await albumsSdk.getAlbums({ limit: 300, offset: 0, type: [AlbumType.FLASHBACK], preview_jwt: true })
    return {
      items : sortFlashbackAlbums(response.data._embedded.albums)
    }
  }
)


export const timelineSlice = createSlice({
  name: 'timeline',
  initialState: adapter.getInitialState({
    status: LOADING_STATUSES.LOADING,
    loadingMoreStatus: LOADING_STATUSES.IDLE,
    loadingSmartStatus: LOADING_STATUSES.IDLE,
    error: '',
    type: SDK_TYPES.DEFAULT,
    totalItemsCount: 0,
    selectedItemsIds: [],
    flashbackAlbums: [],
    dateFormat: DATES_FORMATS.timelineDay,
    sortFormat: SORT_FORMATS.sortFormat.day,
    filter: TIMELINE_FILTERS.all
  }),
  reducers: {
    updateItem: (state, action) => {
      adapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      })
    },
    setStatus: (state, action) => {
      state.status = action.payload
    },
    selectItem: (state, action) => {
      const id = action.payload
      const indexOfItemId = state.selectedItemsIds.indexOf(id)

      if (indexOfItemId === -1) {
        state.selectedItemsIds = [...state.selectedItemsIds, id]
      } else {
        state.selectedItemsIds = [...state.selectedItemsIds.slice(0, indexOfItemId), ...state.selectedItemsIds.slice(indexOfItemId + 1)]
      }
    },
    addItem: (state, action) => {
      adapter.addOne(state, action.payload)
    },
    setAllItems: (state, action) => {
      adapter.setAll(state, action.payload)
    },
    unselectAll: (state) => {
      state.selectedItemsIds = []
    },
    deleteItems: (state, action: PayloadAction<string[]>) => {
      adapter.removeMany(state, action.payload)
    },
    selectAllItemsInGroup: (state, action) => {
      state.selectedItemsIds = _.uniq([...state.selectedItemsIds, ...action.payload])
    },
    unselectAllItemsInGroup: (state, action) => {
      state.selectedItemsIds = _.difference(state.selectedItemsIds, action.payload)
    },
    setCurrentTimelineType: (state, action) => {
      state.type = action.payload
    },
    resetState: (state) => {
      state.status = LOADING_STATUSES.LOADING
      state.selectedItemsIds = []
      state.flashbackAlbums = []

      adapter.removeAll(state)
    },
    setTotalCount: (state, action) => {
      state.totalItemsCount = action.payload
    },
    setFlashbackAlbums: (state, action) => {
      state.flashbackAlbums = action.payload
    },
    decreaseTotalCount: (state, action: PayloadAction<number>) => {
      state.totalItemsCount = state.totalItemsCount - action.payload
    },
    setDateFormat: (state, action) => {
      state.dateFormat = action.payload
    },
    setSortFormat: (state, action) => {
      state.sortFormat = action.payload
    },
    setFilter: (state, action) => {
      state.filter = action.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(loadTimelineItemsFirstTimeThunk.pending, (state) => {
        state.status = LOADING_STATUSES.LOADING
      })
      .addCase(loadTimelineItemsFirstTimeThunk.fulfilled, (state, action) => {
        state.status = LOADING_STATUSES.SUCCEEDED
        adapter.setAll(state, action.payload.items)
        state.totalItemsCount = action.payload.totalCount
      })
      .addCase(loadTimelineItemsFirstTimeThunk.rejected, (state, action) => {
        state.status = LOADING_STATUSES.FAILED
        state.error = action.error.message
      })
      .addCase(loadMoreTimelineItemsThunk.pending, (state) => {
        state.loadingMoreStatus = LOADING_STATUSES.LOADING
      })
      .addCase(loadMoreTimelineItemsThunk.fulfilled, (state, action) => {
        state.status = LOADING_STATUSES.SUCCEEDED
        adapter.addMany(state, action.payload)
      })
      .addCase(loadFlashbackAlbumsThunk.pending, (state) => {
        state.loadingSmartStatus = LOADING_STATUSES.LOADING
      })
      .addCase(loadFlashbackAlbumsThunk.fulfilled, (state, action) => {
        state.loadingSmartStatus = LOADING_STATUSES.SUCCEEDED
        state.flashbackAlbums = action.payload.items
      })
      .addCase(loadFlashbackAlbumsThunk.rejected, (state, action) => {
        state.loadingSmartStatus = LOADING_STATUSES.FAILED
        state.error = action.error.message
      })
  },
})

const {
  reducer, actions
} = timelineSlice

export { reducer as timelineReducer, actions as timelineActions }
