import { formatImgUrl } from 'constants/configs/theme.config'
import { STYLED_VARIABLES } from 'constants/styledVariables'

import React from 'react'

import styled from 'styled-components'

import { getUiTheme } from "../user/selectors"
import { THEME } from "../user/userSlice"

interface EmptyPublicLinksProps {
  className?: string,
  texts: {
    title: string,
    description: string
  }
}

export const EmptyPublicLinksPlaceholder: React.FC<EmptyPublicLinksProps> = ({ className = '', texts }) => {
  const theme = getUiTheme()

  return (
    <EmptyPublicLinksBox
      className={className}
    >
      {theme === THEME.LIGHT ? <ContainerImg src={formatImgUrl("/assets/placeholders-images/no-photos.svg")} /> :
        <ContainerImg src={formatImgUrl("/assets/placeholders-images-black/no-photos.svg")} />}

      <EmptyTitle>
        {texts.title}
      </EmptyTitle>

      <EmptyText>
        {texts.description}
      </EmptyText>
    </EmptyPublicLinksBox>
  )
}

const EmptyPublicLinksBox = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 0;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

const ContainerImg = styled.img`
  width: 292px;
  height: 188px;
  pointer-events: none;
`

const EmptyTitle = styled.h2`
  margin-top: 30px;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--text-primary);
  max-width: 280px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    margin-top: 40px;
    line-height: 32px;
    max-width: unset;
    font-weight: 500;
  }
`

const EmptyText = styled.p`
  margin-top: 20px;
  max-width: 520px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  color: var(--text-primary);

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    display: flex;
  }
`
