import { useEffect,useState } from "react"

import styled from 'styled-components'
import { SpriteIcon, STYLED_VARIABLES } from "@cloudike/web_ui_components"
import { useTranslation } from "react-i18next"

import { getErrorArraySelector,
  getIndexQueueSelector,getInProgressSelector,getIntervalIdsArraySelector,
  getMaxInProgressSelector,
  getPhotoPreviewTransformerItemsSelector,
  getPhotoPreviewTransformerLoadingSelector,
  getStartArraySelector,
  getWidgetTransformerVisibleSelector } from "../../photo/photo-transformer-preview/selectors"
import { Spinner } from "../../../ui"
import { useAppDispatch } from "../../../store"
import { fetchTransformerThunk, photoPreviewTransformerActions } from "../../photo/photo-transformer-preview/photoPreviewTransformerSlice"
import { NOTIFICATION_TYPES, showNotification } from "../notifications"

export const TransformerWidget = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const isWidgetTransformerVisible = getWidgetTransformerVisibleSelector()
  const isLoading = getPhotoPreviewTransformerLoadingSelector()
  const transformedItems = getPhotoPreviewTransformerItemsSelector()
  const errorItems = getErrorArraySelector()
  const indexQueue = getIndexQueueSelector()
  const inProgress = getInProgressSelector()
  const startArray  = getStartArraySelector()
  const maxInProgress = getMaxInProgressSelector()
  const intervalIdsArray = getIntervalIdsArraySelector()

  const [isVisibleList, setVisibleList] = useState(false)

  useEffect(() => {
  	if ((transformedItems.length + errorItems.length) === startArray.length && startArray.length > 0) {
      dispatch(photoPreviewTransformerActions.setTotalItemsCount(transformedItems.length))
      dispatch(photoPreviewTransformerActions.setLoading(false))
      setVisibleList(true)

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: t('l', 'Обработка фото завершена'),
      })
  	}
  }, [startArray, transformedItems, errorItems])

  const handleCallback = (data: any, checksum: string) => {
    !data.error ? dispatch(photoPreviewTransformerActions.addTransformedPhoto(data)) : dispatch(photoPreviewTransformerActions.addErrorArray({ ...data, checksum }))
    dispatch(photoPreviewTransformerActions.downInProgress())
  }

  useEffect(() => {
    if (inProgress < maxInProgress && indexQueue < startArray.length) {
      dispatch(fetchTransformerThunk({ callback: handleCallback }))
    }
  }, [indexQueue, startArray, inProgress])

  const handleClose = () => {
    intervalIdsArray.forEach(item => clearInterval(item))
    dispatch(photoPreviewTransformerActions.toggleTransformerWidget(false))
    dispatch(photoPreviewTransformerActions.resetState())
  }

  const handleClickPreview = () => {
    dispatch(photoPreviewTransformerActions.setVisiblePreview(true))
  }

  const handleClickFileName = (id: string) => () => {
    dispatch(photoPreviewTransformerActions.setCurrentItemIndexById(id))
    dispatch(photoPreviewTransformerActions.setVisiblePreview(true))
  }

  const handleOpenList = () => {
    setVisibleList(prev => !prev)
  }

  return (
    isWidgetTransformerVisible && (
      <TransformerWidgetBox>

        <HeaderBox>
          <LoadingTextBox>
            <TextBox>
              <Text>
                {`Фото обработано ${transformedItems.length + errorItems.length} из ${startArray.length}`}
              </Text>

              { !isLoading && errorItems.length > 0 && (
                <Text>
                  {`Завершено с ошибкой ${errorItems.length}`}
                </Text>
              )}
            </TextBox>

            { isLoading && (
              <SpinnerBox>
                <Spinner />
              </SpinnerBox>
            )
            }
          </LoadingTextBox>

          <IconBox>
            {!isLoading && (
              <HeaderButtonClose
                iconName={'album'}
                onClick={handleClickPreview}
              />
            )}

            {!isLoading && (
              <HeaderButtonArrow
                iconName={'arrow_right'}
                onClick={handleOpenList}
                rotate={isVisibleList ? '-270deg' : '-90deg'}
              />
            )}

            <HeaderButtonClose iconName={'close'}
              onClick={handleClose}
            />

          </IconBox>


        </HeaderBox>

        { isVisibleList && !isLoading && (
          <TransformedItemsBox>
            {transformedItems.map(item => (
              <FileNameBox key={item.id}
                onClick={handleClickFileName(item.id)}
              >
                <SpriteIcon iconName={'file_image'} />

                <FileName>
                  {item.file_name}
                </FileName>

              </FileNameBox>
            )
            )}
          </TransformedItemsBox>
        )
        }
      </TransformerWidgetBox>
    )
  )
}

const TransformerWidgetBox = styled.div`
    margin: 8px 8px 0;
    position: relative;
    max-width: 440px;
    user-select: none;
    width: auto;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 20px 0px;
    
    @media screen and (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
      max-width: unset;
    }
`

const HeaderBox = styled.div`
    display: flex;
    align-items: center;
    min-height: 56px;
    background-color: var(--button-tertiary-default);
    border-radius: 4px 4px 0 0;
    border: 1px solid var(--button-secondary-default-border);
    padding: 10px 16px;
`

const TextBox = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-right: 10px;
`

const HeaderButtonClose = styled(SpriteIcon)`
    color: var(--icon-primary);
    
    :hover {
        cursor: pointer;
        color: var(--text-secondary);
    }
`

const HeaderButtonArrow = styled(SpriteIcon)`
    transform: ${props => `rotate(${props.rotate})`};

    color: var(--icon-primary);
    
    :hover {
        cursor: pointer;
        color: var(--text-secondary);
    }
`

const Text = styled.span`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    margin-bottom: 15px;
    
    &:last-child {
        margin-bottom: 0;
    }
`

const LoadingTextBox = styled.div`
    display: flex;
    align-items: center;
    background-color: var(--button-tertiary-default);
`

const IconBox = styled.div`
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 20px;
    top: 16px;
    gap: 16px;
`

const TransformedItemsBox = styled.div`
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    padding: 0 16px;
    max-height: 174px;
    overflow: auto;
    border: 1px solid var(--button-secondary-default-border);
    border-top: unset;
`

const FileNameBox = styled.div`
    margin: 10px 0;
    cursor: pointer;    
    display: flex;
    align-items: center;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    color: var(--icon-primary);
`

const FileName = styled.div`
    width: 100%;
    overflow-x:hidden;
    text-overflow: ellipsis;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    white-space: nowrap;
    margin-left: 10px;
    color: var(--simple-dark);
`

const SpinnerBox = styled.div`
    width: 40px;
    height: 40px;
`
