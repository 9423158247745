import { LOADING_STATUSES } from 'constants/loadingStatuses'

import React, { useCallback, useEffect, useMemo, useState } from 'react'

import * as yup from 'yup'
import {
  ConfirmationModal,
  ConfirmationModalTypes,
  FilesTableBreadcrumbs,
  FileTableContainer,
  MenuItem,
  SearchInput,
  SelectType,
  SpriteIcon,
  STYLED_VARIABLES
} from '@cloudike/web_ui_components'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import {
  getAllFilesNodesSelector,
  getBreadcrumbsSelector,
  getCheckboxVisibility,
  getCurrentFolderIdSelector,
  getFilesLoadingStatusSelector,
  getFilesRenamingItemIdSelector,
  getFilesSelectedItemsCountSelector,
  getFilesSelectedItemsIdsSelector,
  getFilesSelectedItemsSelector,
  getFilesSortSelector,
  getIsActiveSearchSelector,
  getRemovedFolderIdSelector,
  getReplaceFilesItemModalData,
  getSelectType,
} from 'features/files/selectors'
import { useAppDispatch } from "store"
import { useGetFilesTableColumns } from 'features/files/useGetFilesTableColumns'
import { generateRemovingText, mapFileItemsToTableRow, pushCreateEditItemPublicLink } from 'features/files/filesUtils'
import { FilesList } from 'features/files/FilesList'
import {
  cancelFileReplacingThunk,
  checkAndUploadFilesThunk,
  createNodePathThunk,
  deleteNodesThunk,
  downloadNodesThunk,
  fetchFilesBreadcrumbsThunk,
  fetchNodesThunk,
  filesActions,
  getSearchNodesThunk,
  keepBothFileVersionsThunk,
  renameFileNodeThunk,
  replaceFileThunk,
  ReplaceModalTypes,
  resetFilesStateThunk,
  setCurrentFolderThunk,
  subscribeFilesToWSThunk,
  unSubscribeFilesFromWSThunk,
  uploadFilesWithDropzone
} from 'features/files/filesSlice'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { EmptyFolderPlaceholder } from 'features/files/EmptyFolderPlaceholder'
import { PageTopbar } from 'features/common/page-topbar'
import { useMobileDetection } from 'features/common/hooks'
import classNames from 'classnames'
import { FileItemsTypes } from 'features/files/types'
import { filesPreviewActions, openFilesPreviewThunk } from 'features/files/files-preview/filesPreviewSlice'
import { usePageTitle } from 'features/common/hooks/usePageTitle'
import { useFilesPreviewItemsUpdate } from 'features/files/files-preview/useFilesPreviewItemsUpdate'
import { FilesSkeleton } from 'features/files/FilesSkeleton'
import { ReplaceFilesItemModal } from 'features/files/ReplaceFilesItemModal'
import { getIsDropzoneAreaVisibleSelector } from 'features/common/dropzone/selectors'
import { DropzoneArea } from 'features/common/dropzone/DropzoneArea'
import { dropzoneActions, removeUploadHandlerThunk, setUploadHandlerThunk } from 'features/common/dropzone/dropzoneSlice'
import { filesApi } from 'api/filesApi'
import { getUserDataSelector } from 'features/user/userSlice'
import _ from 'lodash'
import { MobileToolbarMenuToggle } from 'features/common/right-sidebar/MobileToolbarMenuToggle'
import { FileVersionsModal } from 'features/files/file-versions/FileVersionsModal'
import { NodeTypes } from '@cloudike/web_files'
import { ITypeProps } from "@cloudike/web_ui_components/dist/Components/SearchInput/SearchInput"

import { deleteSharedLinkThunk, getPublicLinkAndCopyThunk, sharingActions } from "../../features/sharing/sharingSlice"
import { SDK_TYPES } from "../../sdk/sdkConstants"
import { parsePathFromParams } from "../../features/common/parsePathFromParams/parsePathFromParams"
import { OpenedItemsService } from "../../utils/OpenedItemsService"
import { analytics, ANALYTICS_EVENTS } from "../../features/common/analytics"
import { useResizeWindow } from "../../features/common/hooks/useResizeWindow"
import { DialogModal } from "../../ui/DialogModal"
import { appActions } from "../../store/app"
import { CopyMoveModal, CopyMoveModalType } from "../../features/files/copy-move-modal/CopyMoveModal"
import { FolderSelectionModal } from "../../features/files/folder-selection-modal/FolderSelectionModal"
import { getModalsRootElement } from "../../constants/modals"

const MAP_FOLDER_NAME_ID_KEY = 'MAP_FOLDER_NAME_ID'

const debounceGetSearchNodes = _.debounce((dispatch, text, type, parentId) => {
  dispatch(getSearchNodesThunk({ text, type, parentId }))
}, 300)

const debounceFetchNodes = _.debounce((dispatch, catalogId) => {
  dispatch(filesActions.setCurrentFolderId(catalogId))
  dispatch(fetchNodesThunk({ parent_id: catalogId }))
}, 300)

function FilesPage() {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const [newPaths, setNewPaths] = useState('')
  const [itemsRightClick, setItemsRightClick] = useState(null)
  const [itemsRightClickIds, setItemsRightClickIds] = useState(null)
  const [removingConfirmationModalOpened, toggleRemovingConfirmationModal] = useState(false)
  const [copyMoveModalType, setCopyMoveModalType] = useState(null)
  const [publicLinkDataForDelete, setPublicLinkDataForDelete] = useState(null)

  const breadcrumbs = getBreadcrumbsSelector()
  const nodes = getAllFilesNodesSelector()
  const sortState = getFilesSortSelector()
  const selectedItemsCount = getFilesSelectedItemsCountSelector()
  const status = getFilesLoadingStatusSelector()
  const isDropzoneAreaVisible = getIsDropzoneAreaVisibleSelector()
  const userData = getUserDataSelector()
  const selectType = getSelectType()
  const checkboxVisibility = getCheckboxVisibility()
  const catalogId = getCurrentFolderIdSelector()
  const replaceModalData = getReplaceFilesItemModalData()
  const isActiveSearch = getIsActiveSearchSelector()

  const selectedFilesIds = getFilesSelectedItemsIdsSelector()
  const selectedFiles = getFilesSelectedItemsSelector()
  const renamingFileId = getFilesRenamingItemIdSelector()

  const params = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const paths = parsePathFromParams(params, 'drive')
  const [arrColumns, handleSortByColumn] = useGetFilesTableColumns(
    onChangeSorting,
    sortState
  )

  const isMobile = useMobileDetection()

  const [withPressedCtrl, setWithPressedCtrl] = useState(false)
  const [withPressedShift, setWithPressedShift] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [searchType, setSearchType] = useState(null)
  const [searchFolder, setSearchFolder] = useState<{name?: string, id?: string}>({})
  const [isFolderSelectionModalOpened, setIsFolderSelectionModalOpened] = useState(false)
  const [fileForScrollingId, setFileForScrollingId] = useState(null)
  const widthWindow = useResizeWindow()

  const currentFileFolder = paths.length === 0 ? '' : paths[paths.length - 1]

  const selectStartListener = useCallback((e) => {
    e.preventDefault()

    return false
  }, [])

  function dispatchKeyupEvent(key, code, keyCode) {
    const keyupEvent = new KeyboardEvent('keyup', {
      key: key,
      code: code,
      keyCode: keyCode,
      charCode: 0,
      bubbles: true,
      cancelable: true
    })

    document.dispatchEvent(keyupEvent)
  }

  function downHandler({ key }) {
    if (key === 'Meta' || key === 'Control') {
      setWithPressedCtrl(true)
    }

    if (key === 'Shift') {
      setWithPressedShift(true)

      document.addEventListener('selectstart', selectStartListener)
    }
  }

  function upHandler({ key }) {
    if (key === 'Meta' || key === 'Control') {
      setWithPressedCtrl(false)
    }

    if (key === 'Shift') {
      setWithPressedShift(false)

      document.removeEventListener('selectstart', selectStartListener)
    }
  }

  function blurWindow() {
    setWithPressedCtrl(false)
    setWithPressedShift(false)

    dispatchKeyupEvent('Shift', 'ShiftLeft', 16)
    dispatchKeyupEvent('Control', 'ControlLeft', 17)
    dispatchKeyupEvent('Meta', 'MetaLeft', 91)

    document.removeEventListener('selectstart', selectStartListener)
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)
    window.addEventListener('blur', blurWindow)
    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
      window.removeEventListener('blur', blurWindow)
    }
  }, [])

  usePageTitle('a_nav_myFiles')

  useEffect(() => {
    dispatch(subscribeFilesToWSThunk({ navigate }))
    OpenedItemsService.initItemsArray()

    return () => {
      dispatch(unSubscribeFilesFromWSThunk())
      dispatch(filesPreviewActions.resetState())
      dispatch(resetFilesStateThunk())
    }
  }, [])

  useEffect(() => {
    if (status === LOADING_STATUSES.LOADING) {
      return
    }

    const paths = parsePathFromParams(params, 'drive')

    dispatch(fetchFilesBreadcrumbsThunk({ paths }))
  }, [params['*'], status])

  useEffect(() => {
    if (!isActiveSearch) {
      const currentFolder = breadcrumbs[breadcrumbs.length - 1]

      setSearchFolder({ id: currentFolder?.id, name: currentFolder?.name })
    }
  }, [breadcrumbs[breadcrumbs.length - 1]?.id, isActiveSearch])

  useEffect(() => {
    if (isActiveSearch) {
      return
    }

    const checkPathAndFetchNodes = () => {
      const paths = parsePathFromParams(params, 'drive')
      const catalogId = paths.slice(-1)[0]

      const { needToCheckIdsPath, nodeType } = location.state as any || {}

      if (!needToCheckIdsPath) {
        dispatch(filesActions.setStatus(LOADING_STATUSES.LOADING))
        debounceFetchNodes(dispatch, catalogId)
      } else {
        if (nodeType === NodeTypes.FILE) {
          setFileForScrollingId(catalogId)
        }

        dispatch(createNodePathThunk({ id: catalogId, navigate, nodeType }))
      }
    }

    checkPathAndFetchNodes()
  }, [params['*'], isActiveSearch])

  useEffect(() => {
    if (isActiveSearch && searchFolder?.id && !searchValue) {
      dispatch(filesActions.setStatus(LOADING_STATUSES.LOADING))
      debounceFetchNodes(dispatch, searchFolder?.id)

      return
    }

    if (isActiveSearch) {
      debounceGetSearchNodes(dispatch, searchValue, searchType?.type, searchFolder?.id)

      return
    }
  }, [params['*'], isActiveSearch, searchValue, searchType?.type, searchFolder?.id])

  useEffect(() => {
    dispatch(setUploadHandlerThunk({ callback: handleUploadFilesWithDropzone }))
    dispatch(dropzoneActions.updateDropzoneOptions({
      disabled: false
    }))
    dispatch(dropzoneActions.setLocalAvailableExtensions([]))

    return () => {
      dispatch(removeUploadHandlerThunk())
      dispatch(dropzoneActions.updateDropzoneOptions({
        disabled: true
      }))
      dispatch(dropzoneActions.setLocalAvailableExtensions(null))

    }
  }, [])

  useEffect(() => {
    dispatch(setCurrentFolderThunk({ nodeId: catalogId }))
  }, [nodes])

  const removedFolderId = getRemovedFolderIdSelector()

  useEffect(() => {
    if (!!removedFolderId && isActiveSearch) {
      const paths = newPaths.split('/')
      const index = paths.findIndex(item => item === removedFolderId)

      if (index >= 0) {
        setNewPaths(`${paths.slice(0, index).join('/')}`)
      }
    }

  }, [removedFolderId])

  useEffect(() => {
    !isActiveSearch && handleClearInput()
    isActiveSearch && setNewPaths(location.pathname)

    if (!isActiveSearch) {
      dispatch(filesActions.setRemovedFolderId(''))
      !!newPaths ? navigate(newPaths) : navigate(location.pathname)
    }
  }, [isActiveSearch])


  useEffect(() => {
    const createListener = (classes, callback) => {
      return (event) => {
        const elements = classes.map((className) => document.querySelector(`.${className}`)).filter(Boolean)

        if (!elements.some(element => element.contains(event.target))) {
          callback()
        }
      }
    }

    const listener = createListener(
      ['js-files-list', 'js-files-toolbar', 'js-mobile-toolbar-menu-toggle', 'js-files-mobile-toolbar', 'modal-dialog__container', 'js-upload-widget'],
      () => {
        dispatch(filesActions.unselectAll())
      }
    )

    document.addEventListener('click', listener, { capture: true })

    return () => {
      document.removeEventListener('click', listener)
    }
  }, [])

  const previewNodes = useMemo(() => nodes.filter(node => node.type === NodeTypes.FILE), [nodes])

  useFilesPreviewItemsUpdate(previewNodes)

  const handleChangeFolder = async (node) => {
    dispatch(filesActions.unselectAll())
    OpenedItemsService.openItem(node)
    if (!searchValue && !searchType) analytics.push(ANALYTICS_EVENTS.WEB_FILES_RECENTLY_OPENED_VIEW)

    if (node.type === FileItemsTypes.DIR) {
      if (isActiveSearch) {
        dispatch(filesActions.setActiveSearch(false))
        handleClearInput()
      }
    }

    if (node.type !== FileItemsTypes.DIR) {
      dispatch(openFilesPreviewThunk({
        items: previewNodes as any,
        currentItemId: node.id,
        totalItemsCount: previewNodes.length
      }))

      return
    }

    dispatch(filesActions.setStatus(LOADING_STATUSES.LOADING))

    const nameIdMapFromStorage = JSON.parse(sessionStorage.getItem(MAP_FOLDER_NAME_ID_KEY))
    const newData = { ...nameIdMapFromStorage, [node.id]: node.name }
    sessionStorage.setItem(MAP_FOLDER_NAME_ID_KEY, JSON.stringify(newData))

    dispatch(filesActions.setCurrentFolder(node))

    if (isActiveSearch) {
      const url = await getUrl(node.id)
      return navigate(`${url}${node.id}`)
    }

    navigate(`${location.pathname}${location.pathname.substr(-1) === '/' ? '' : '/'}${node.id}`)
  }

  const getUrl = async (nodeId) => {
    const rsp = await filesApi.getAncestorsItems(userData.id, nodeId)
    const nodes = rsp._embedded.nodes.reverse()

    return nodes.reduce((acc, item) => {
      return acc + `${item.id}/`
    }, '')
  }

  function onChangeSorting(sortState) {
    dispatch(filesActions.setSort(sortState))
  }

  function handleBreadcrumbClick(item) {
    const paths = parsePathFromParams(params, 'drive')
    const indexOfPath = paths.findIndex(path => path === item.id)

    dispatch(filesActions.unselectAll())

    if (!item.name) {
      navigate('/drive')

      return
    }

    navigate(`/drive/${paths.slice(0, indexOfPath + 1).join('/')}`)
  }

  const handleUploadFilesWithDropzone = (files) => {
    dispatch(uploadFilesWithDropzone({ files }))
  }

  const handleUploadFiles = (files) => {
    if (files.length > 0) {
      dispatch(checkAndUploadFilesThunk({ files }))
    }
  }

  const handleSelectNode = (node, items) => {
    if (withPressedShift) {
      dispatch(filesActions.selectItemWithPressedShift({ id: node.id, items }))

      return
    }

    if (withPressedCtrl) {
      dispatch(filesActions.selectItemWithPressedCtrl({ id: node.id, items }))

      return
    }

    dispatch(filesActions.selectItem({ id: node.id, items }))
  }

  const handleElementsSelection = (node, items) => {
    if (withPressedShift) {
      dispatch(filesActions.selectItemWithPressedShift({ id: node.id, items }))

      return
    }

    dispatch(filesActions.selectItemWithPressedCtrl({ id: node.id, items }))
  }

  const handleCancelSelection = () => {
    dispatch(filesActions.unselectAll())
  }

  const handleRenameItem = (name) => {
    dispatch(renameFileNodeThunk({ name }))
  }

  const handleSelectAll = (items) => {
    dispatch(filesActions.selectAll(items))
  }

  const handleClickCheckbox = (allFileIds) => {
    if (selectType === SelectType.ALL) {
      handleCancelSelection()
    } else {
      handleSelectAll(allFileIds)
    }
  }

  const handleShareRightMenuSelected = () => {
    handleShareSelected(itemsRightClick)
  }

  const handleShareSelected = (item) => {
    dispatch(sharingActions.setSharingAlbumConfig({
      sdkType: SDK_TYPES.FILES
    }))
    dispatch(sharingActions.setSharedItemData(item))
    pushCreateEditItemPublicLink(item)
  }

  const handleOpenPublicLinkRemovingModal = (item) => {
    setPublicLinkDataForDelete(item)
  }

  const handleClosePublicLinkRemovingModal = () => {
    setPublicLinkDataForDelete(null)
  }

  const handleDeletePublicLink = () => {
    dispatch(deleteSharedLinkThunk({ data: publicLinkDataForDelete, type: SDK_TYPES.FILES }))
    setPublicLinkDataForDelete(null)
  }

  const handleCopyPublicLink = (item) => {
    dispatch(getPublicLinkAndCopyThunk({ data: item }))
  }

  const handleOpenInContextMenuPublicLinkRemovingModal = () => {
    setPublicLinkDataForDelete(itemsRightClick)
  }


  const handleContextMenuCopyPublicLink = () => {
    dispatch(getPublicLinkAndCopyThunk({ data: itemsRightClick }))
  }

  const validationSchemaInputSearch = yup.object().shape({
    name: yup
      .string()
      .required('ValidationMessage.REQUIRED')
      .max(255, 'ValidationMessage.TOO_LONG_STRING')
      .min(1, 'ValidationMessage.TOO_SHORT_STRING')
  })

  const arrTypes = [
    { name: t('a_search_folders'), iconName: 'folders', type: 'dir' },
    { name: t('a_search_PDFs'), iconName: 'pdf', type: 'ebook_adobe' },
    { name: t('a_search_docs'), iconName: 'docs', type: 'document_office' },
    { name: t('a_search_sheets'), iconName: 'sheets', type: 'spreadsheet_office' },
    { name: t('a_search_images'), iconName: 'images', type: 'image' },
    { name: t('a_search_audio'), iconName: 'audio', type: 'audio' },
    { name: t('a_search_video'), iconName: 'video', type: 'video' },
    { name: t('a_search_books'), iconName: 'books', type: 'ebook' },
    { name: t('a_search_archives'), iconName: 'archives', type: 'archive' },
  ]

  const textsSearch = {
    titleTextSearch: t('l_common_searchByType'),
    textPlaceholder: t('l_common_search'),
    textRecent: t('l_common_recent'),
    textCancel: t('a_common_cancel'),
    folders: t('a_search_folders')
  }

  const handleSearchText = (value: string) => {
    setSearchValue(value)
  }

  const handleSearchType = (type: ITypeProps) => {
    setSearchType(type)
  }

  const handleSwitchActive = (isActive: boolean) => {
    if (isActive) analytics.push(ANALYTICS_EVENTS.WEB_FILES_SEARCH_VIEW)
    dispatch(filesActions.setActiveSearch(isActive))
  }

  const handleClearInput = () => {
    setSearchValue('')
    setSearchType(null)
  }

  const handleReplaceCancel = () => {
    dispatch(filesActions.setReplaceFilesNodeModalData({ opened: false }))
    dispatch(cancelFileReplacingThunk())
  }

  const handleKeepBoth = () => {
    dispatch(filesActions.setReplaceFilesNodeModalData({ opened: false, type: ReplaceModalTypes.KEEP_BOTH }))
    dispatch(keepBothFileVersionsThunk())
  }

  const handleReplace = () => {
    dispatch(filesActions.setReplaceFilesNodeModalData({ opened: false, type: ReplaceModalTypes.REPLACE }))
    dispatch(replaceFileThunk())
  }

  const handleRightClick = (node) => {
    if (selectedItemsCount > 1) {
      setItemsRightClick(selectedFiles)
      setItemsRightClickIds(selectedFilesIds)
    } else {
      setItemsRightClick(node)
      setItemsRightClickIds([node.id])
    }
  }

  const handleDownloadSelected = () => {
    dispatch(downloadNodesThunk({ ids: itemsRightClickIds }))
  }

  const showRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(true)
  }

  const handleRemoveSelected = () => {
    dispatch(deleteNodesThunk({ ids: itemsRightClickIds }))
    toggleRemovingConfirmationModal(false)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleStartRenamingNode = () => {
    dispatch(filesActions.setRenamingItemId(itemsRightClick.id))
  }

  const handleCloseRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(false)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleCloseCopyMoveModal = () => {
    setCopyMoveModalType(null)
  }

  const handleClearSearchFolder = () => {
    setSearchFolder({})
  }

  const handleOpenFolderSelectModal = () => {
    setIsFolderSelectionModalOpened(true)
  }

  const handleCloseFolderSelectModal = () => {
    setIsFolderSelectionModalOpened(false)
  }

  const contextMenuItems = (() => {
    let items : React.ComponentProps<typeof MenuItem>[] = []
    if ( [itemsRightClick].length === 1 ) {
      items = [
        {
          label: t('a_common_download'),
          icon: <SpriteIcon iconName={'download_empty'} />,
          onClickItem: handleDownloadSelected,
        },
        {
          label: t('a_common_share'),
          iconName: 'share',
          onClickItem: handleShareRightMenuSelected
        },
        {
          label: t('a_common_copyPublicLink'),
          iconName: 'copy_link',
          onClickItem: handleContextMenuCopyPublicLink
        },
        !!itemsRightClick && itemsRightClick.is_shared && {
          label: t('a_common_deleteLink'),
          iconName: 'unlink',
          onClickItem: handleOpenInContextMenuPublicLinkRemovingModal
        },
        {
          label: t('a_common_move'),
          iconName: 'move_icon',
          onClickItem: () => setCopyMoveModalType(CopyMoveModalType.MOVE)
        },
        {
          label: t('a_common_copy'),
          iconName: 'copy',
          onClickItem: () => setCopyMoveModalType(CopyMoveModalType.COPY)
        },
        {
          label: t('a_common_rename'),
          iconName: 'pencil',
          onClickItem: handleStartRenamingNode
        },
        {
          label: t('a_common_remove'),
          iconName: 'remove_icon',
          onClickItem: showRemovingConfirmationModal
        }]
    }

    if (selectedItemsCount > 1) {
      items = [
        {
          label: t('a_common_download'),
          icon: <SpriteIcon iconName={'download_empty'} />,
          onClickItem: handleDownloadSelected,
        },
        {
          label: t('a_common_move'),
          iconName: 'move_icon',
          onClickItem: () => setCopyMoveModalType(CopyMoveModalType.MOVE)
        },
        {
          label: t('a_common_copy'),
          iconName: 'copy',
          onClickItem: () => setCopyMoveModalType(CopyMoveModalType.COPY)
        },
        {
          label: t('a_common_remove'),
          iconName: 'remove_icon',
          onClickItem: showRemovingConfirmationModal
        }]
    }

    return items.filter(item => !!item)
  })()

  return (
    <SScrollBox overflowY={isActiveSearch ? 'scroll' : 'unset'}
      overflowX={isActiveSearch ? 'hidden' : 'unset'}
      isActiveSerch={isActiveSearch}
    >
      <WithSearchBox className={classNames({ 'no-flex': !!selectedItemsCount, opened: isActiveSearch })}>
        {(!isActiveSearch || (isActiveSearch && !!selectedItemsCount)) && (
          <PageHeaderBox>
            <StyledPageTopbar
              title={t('a_nav_myFiles')}
              selectedText={isMobile ? t('l_common_selectedObjects', { number: selectedItemsCount }) : t('l_files_selectedFiles', { number: selectedItemsCount })}
              selectedItemsCount={selectedItemsCount}
              onCancelSelection={handleCancelSelection}
              className={classNames({ 'with-bigger-height': !!selectedItemsCount })}
            />

            {
              breadcrumbs.length >= 2 && !selectedItemsCount && (
                <FilesTableBreadcrumbsWrapper>
                  <FilesTableBreadcrumbs
                    widthWindow={widthWindow}
                    filePath={breadcrumbs}
                    firstItem={t('l_common_allFiles')}
                    onClickedPath={handleBreadcrumbClick}
                  />
                </FilesTableBreadcrumbsWrapper>
              )}
          </PageHeaderBox>
        )}


        <SearchInput
          validationSchema={validationSchemaInputSearch}
          arrTypes={arrTypes}
          textsSearch={textsSearch}
          onSearchText={handleSearchText}
          onSearchType={handleSearchType}
          onSwitchActive={handleSwitchActive}
          isActiveSearch={isActiveSearch}
          onClearInput={handleClearInput}
          isMobile={isMobile}
          type={searchType}
          value={searchValue}
          hideInput={!!selectedItemsCount}
          onFoldersClick={handleOpenFolderSelectModal}
          onClearFoldersClick={handleClearSearchFolder}
          currentFolderForSearchName={searchFolder?.name}
          withoutGrayLine={true}
        />
      </WithSearchBox>

      {
        !nodes.length && status !== LOADING_STATUSES.LOADING ? (
          <FileTableContainer
            isActiveSearch={isActiveSearch}
          >
            <EmptyFolderPlaceholder
              texts={{
                title: (isActiveSearch && (!searchFolder?.name || searchValue)) ? (!!searchValue || !!searchType) ? t('l_files_searchResultTitle')
                  : t('l_search_noRecentTitle') : t('l_common_driveEmptyPlaceholderTitle'),
                description: (isActiveSearch && (!searchFolder?.name || searchValue))
                  ? (!!searchValue || !!searchType)
                    ? !!searchValue
                      ? t('l_search_tryByType') : t('l_search_tryDifferentType')
                    : t('l_search_noRecentMessage')
                  : t('l_common_driveEmptyPlaceholderMessage'),
                uploadBtn: (!isActiveSearch || (!!searchFolder?.name && !searchValue)) && t('a_files_uploadFiles')
              }}
              onUploadFiles={handleUploadFiles}
              isNotFoundPlaceholder={isActiveSearch && (!!searchValue || !!searchType)}
            />

            {!isActiveSearch && <MobileToolbarMenuToggle iconName="add" />}

            <StyledDropzoneArea
              visible={isDropzoneAreaVisible}
              text={t('l_common_filesWillBeUploaded') + ' ' + breadcrumbs[breadcrumbs.length - 1]?.name}
            />

            <ReplaceFilesItemModal
              data={replaceModalData}
              onCancel={handleReplaceCancel}
              onKeepBoth={handleKeepBoth}
              onReplace={handleReplace}
            />
          </FileTableContainer>
        ) : (
          <FileTableContainer
            isActiveSearch={isActiveSearch}
            isDropzoneAreaVisible={isDropzoneAreaVisible}
          >

            {status === LOADING_STATUSES.LOADING && <FilesSkeleton />}

            {!!nodes.length && status !== LOADING_STATUSES.LOADING && (
              <FilesList
                isActiveSearch={isActiveSearch}
                columns={arrColumns}
                selectType={selectType}
                checkboxVisibility={checkboxVisibility}
                items={mapFileItemsToTableRow(nodes, t)}
                onRenameItem={handleRenameItem}
                onSortingChange={handleSortByColumn}
                onToggleItem={handleSelectNode}
                onChangeFolder={handleChangeFolder}
                onClickCheckbox={handleElementsSelection}
                onRightClick={handleRightClick}
                onEditPublicLink={handleShareSelected}
                onDeletePublicLink={handleOpenPublicLinkRemovingModal}
                onCopyPublicLink={handleCopyPublicLink}
                selectedFilesIds={selectedFilesIds}
                renamingFileId={renamingFileId}
                onClickAllCheckbox={handleClickCheckbox}
                fileForScrollingId={fileForScrollingId}
                contextMenuItems={contextMenuItems}
                searchValue={searchValue}
              />
            )}

            <ReplaceFilesItemModal
              data={replaceModalData}
              onCancel={handleReplaceCancel}
              onKeepBoth={handleKeepBoth}
              onReplace={handleReplace}
            />

            <FileVersionsModal />

            <StyledDropzoneArea
              visible={isDropzoneAreaVisible}
              text={t('l_common_filesWillBeUploaded') + ' ' + breadcrumbs[breadcrumbs.length - 1]?.name}
            />

            <MobileToolbarMenuToggle iconName={selectedItemsCount > 0 ? 'more_horiz' : 'add'}
              className="js-mobile-toolbar-menu-toggle"
            />
          </FileTableContainer>
        )}

      {
        !!itemsRightClick && (
          <DialogModal
            isOpened={removingConfirmationModalOpened}
            title={generateRemovingText(itemsRightClick.length > 1 ? itemsRightClick : [itemsRightClick], t).title}
            onClose={handleCloseRemovingConfirmationModal}
            okText={t('a_common_ok')}
            onOk={handleRemoveSelected}
            cancelText={t('a_common_cancel')}
            onCancel={handleCloseRemovingConfirmationModal}
            type={ConfirmationModalTypes.danger}
          >
            <TextInModalBox>
              {generateRemovingText(itemsRightClick.length > 1 ? itemsRightClick : [itemsRightClick], t).text}
            </TextInModalBox>
          </DialogModal>
        )}

      {
        isFolderSelectionModalOpened && (
          <FolderSelectionModal onClose={handleCloseFolderSelectModal}
            onSelect={setSearchFolder}
          />
        )}

      {
        !!copyMoveModalType && (
          <CopyMoveModal
            currentFileFolder={currentFileFolder}
            selectedItems={itemsRightClick.length > 1 ? itemsRightClick : [itemsRightClick]}
            type={copyMoveModalType}
            onClose={handleCloseCopyMoveModal}
          />
        )
      }

      <ConfirmationModal title={t('l_publicLink_deleteConfirmTitle')}
        isOpened={!!publicLinkDataForDelete}
        onClose={handleClosePublicLinkRemovingModal}
        parentBlock={getModalsRootElement()}
        onOk={handleDeletePublicLink}
        okText={t('a_common_delete')}
        onCancel={handleClosePublicLinkRemovingModal}
        cancelText={t('a_common_cancel')}
        type={ConfirmationModalTypes.danger}
      >
        <SRemovingLinkConfirmationModalText>
          {t('l_publicLink_deletConfirmMessage')}
        </SRemovingLinkConfirmationModalText>
      </ConfirmationModal>
    </SScrollBox>
  )
}

const SScrollBox = styled.div`
  height: 100%;
  overflow-y: ${(props: any) => props.overflowY};
  overflow-x: ${(props: any) => props.overflowX};
  padding: ${(props:any) => {return props.isActiveSerch ? '20px 16px' : ''}};

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_90}) {
    padding: ${(props:any) => {return props.isActiveSerch ? '20px 24px' : ''}}
  }

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    padding: ${(props:any) => {return props.isActiveSerch ? '20px 16px 0 24px' : ''}}
  }

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.LAPTOP}) {
    padding: ${(props:any) => {return props.isActiveSerch ? '20px 36px 0px' : ''}};
  }
  
  &::-webkit-scrollbar {
    width: 4px;

    :hover{
      cursor: pointer;
    }
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--scroll-color);
    border-radius: 14px;

    :hover{
      cursor: pointer;
    }
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
`

const PageHeaderBox = styled.div`
  display: flex;
  flex-flow: column;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    margin: 0;
  }
`

const StyledPageTopbar = styled(PageTopbar)`
  && {
    min-height: 40px;
  }
`

const FilesTableBreadcrumbsWrapper = styled.div`
  padding-left: 60px;
  
  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.LAPTOP}) {
    padding-left: 0;
  }
`

const StyledDropzoneArea = styled(DropzoneArea)`
`

const WithSearchBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  
  &.opened {
    padding-bottom: 0;
  }

  &.no-flex {
    display: block;
  }
`

const TextInModalBox = styled.span`
  font-size: 14px;
`

const SRemovingLinkConfirmationModalText = styled.p`
  color: var(--text-secondary);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  max-width: 572px;
`

export default FilesPage
