import { useAppSelector } from "store"
import { createSelector } from "@reduxjs/toolkit"

import { photoPreviewSelectors } from "./photoPreviewSlice"

export const getPhotoPreviewItemsSelector = () => useAppSelector(state => photoPreviewSelectors.selectAll(state.photoPreview))

export const getCurrentPhotoPreviewItemIndexSelector = () => useAppSelector(state => state.photoPreview.currentItemIndex)

export const getCurrentPhotoPreviewModalStatusSelector = () => useAppSelector(state => state.photoPreview.isModalOpened)

export const getCurrentPhotoPreviewSdkTypeSelector = () => useAppSelector(state => state.photoPreview.sdkType)

export const getCurrentPhotoPreviewTypeSelector = () => useAppSelector(state => state.photoPreview.type)

export const getPhotoPreviewLoadingSelector = () => useAppSelector(state => state.photoPreview.loading)

export const getPhotoPreviewLoadingMoreSelector = () => useAppSelector(state => state.photoPreview.loadingMore)

export const getPhotoPreviewErrorSelector = () => useAppSelector(state => state.photoPreview.hasError)

export const getPhotoPreviewTotalItemsCountSelector = () => useAppSelector(state => state.photoPreview.totalItemsCount)

export const getCurrentLivePhotoPreviewContentDataUrl = () => useAppSelector(state => state.photoPreview.currentLivePhotoPreviewContentDataUrl)

export const getCurrentFacesDate = () => useAppSelector(state => state.photoPreview.currentItemFacesData)

export const getIsLoadingFaces = () => useAppSelector(state => state.photoPreview.isLoadingFaces)

export const getIsLivePhotoPlaying = () => useAppSelector(state => state.photoPreview.isLivePhotoPlaying)

export const getCurrentItemExtensionsRawSelector = state => state.photoPreview.currentItemExtensions

export const getCurrentItemExtensionsSelector = () => useAppSelector(getCurrentItemExtensionsRawSelector)

export const getAreFacesVisibleSelector = () => useAppSelector(state => state.photoPreview.areFacesVisible)

export const getLivePhotoPreviewContentUrlSelector = () => useAppSelector(
  createSelector(
    getCurrentItemExtensionsRawSelector,
    extensions => {
      if(extensions.length > 0) {
        const item = extensions.filter((item) => item.type === 'live_photo')[0]
        if(!item) return
        const { _links:{ content:{ href } } } = item
        return href
      }
    }))

export const getIsLivePhotoCanPlaySelector = () => useAppSelector(
  createSelector(
    state => state.photoPreview.currentLivePhotoPreviewContentDataUrl,
    state => state.photoPreview.isLivePhotoCanPlay,
    (dataUrl, isCanPlay) => {
      return !!dataUrl && isCanPlay
    }
  ))
