import { useState } from 'react'

import { ConfirmationModalTypes, MenuWithActionItems,PrimaryButton, STYLED_VARIABLES } from '@cloudike/web_ui_components'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from  "store"
import { DialogModal } from 'ui/DialogModal'
import { MobileToolbar } from 'features/common/right-sidebar/MobileToolbar'
import { ANALYTICS_EVENTS, analytics } from 'features/common/analytics'

import { appActions } from "../../store/app"
import { useMobileDetection } from "../common/hooks"
import { TypeModal } from "../../pages/Documents"

import { DOCUMENTS_WALLET_STATUSES } from './types'
import { getDocumentSelectedTypesCountSelector, getDocumentSelectedTypesSelector, getDocumentsWalletLoadingStatusSelector } from './selectors'
import { deleteDocumentsWalletThunk,documentsActions,removeDocumentTypeThunk } from './documentsSlice'

interface DocumentTypesToolbarProps {
  className?: string,
}

export const DocumentTypesToolbar: React.FC<DocumentTypesToolbarProps> = ({ className = '' }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const isMobile = useMobileDetection()

  const status = getDocumentsWalletLoadingStatusSelector()
  const selectedTypes = getDocumentSelectedTypesSelector()
  const selectedTypesCount = getDocumentSelectedTypesCountSelector()

  const [removingConfirmationModalOpened, toggleRemovingConfirmationModal] = useState(false)

  const handleOpenRemovingConfirmationModal = () => {
    selectedTypesCount === 0 && analytics.push(ANALYTICS_EVENTS.WEB_MENU_DOCUMENTS_DELETE_WALLET_CLICK)
    toggleRemovingConfirmationModal(true)
  }

  const handleCloseRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(false)
  }

  const handleDeleteWallet = () => {
    analytics.push(ANALYTICS_EVENTS.WEB_DIALOG_DOCUMENTS_DELETE_WALLET_CLICK)
    dispatch(deleteDocumentsWalletThunk())
    toggleRemovingConfirmationModal(false)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleCreateDocument = () => {
    dispatch(documentsActions.setTypeModal(TypeModal.CREATE))
    dispatch(documentsActions.setShowCreateModal(true))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleRenameDocument = () => {
    dispatch(documentsActions.setTypeModal(TypeModal.RENAME))
    dispatch(documentsActions.setShowCreateModal(true))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const generateTitleRemoveModal = () => {
    return t('l_dw_deleteDocsFolder' , { number: selectedTypesCount, name: selectedTypes[0].name })
  }

  const generateTextRemoveModal = () => {
    return t('l_dw_deleteDocsFolderMessage' , { number: selectedTypesCount, name: selectedTypes[0].name })
  }

  const handleDeleteDocument = () => {
    dispatch(removeDocumentTypeThunk({ items: [...selectedTypes] }))
    toggleRemovingConfirmationModal(false)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const menuItems = (() => {
    return [
      isMobile && selectedTypesCount === 0 &&
      {
        label: t('a_files_createFolder'),
        iconName: 'create_new_folder',
        onClickItem: handleCreateDocument
      },
      selectedTypesCount === 1 && selectedTypes[0].preview_id === 'document' &&
      {
        label: t('a_common_rename'),
        iconName: 'pencil',
        onClickItem: handleRenameDocument
      },
      selectedTypesCount > 0 &&
      {
        label: t('a_common_remove'),
        iconName: 'remove_icon',
        onClickItem: handleOpenRemovingConfirmationModal
      },
      selectedTypesCount === 0 &&
      {
        label: t('a_dw_deleteDW'),
        iconName: 'remove_icon',
        onClickItem: handleOpenRemovingConfirmationModal
      },
    ].filter((item) => !!item)
  })()

  if (status !== DOCUMENTS_WALLET_STATUSES.DOCUMENT_TYPES_LOADED) {
    return null
  }

  return (
    <>
      <DocumentTypesToolbarBox className={className}>
        {selectedTypesCount === 0 && (
          <StyledPrimaryButton
            actionName={t('a_files_createFolder')}
            onAction={handleCreateDocument}
          />
        )}

        <MenuWithActionItems menuItems={menuItems} />

        <DialogModal
          isOpened={removingConfirmationModalOpened}
          title={selectedTypesCount === 0 ? t('a_dw_deleteDW') : generateTitleRemoveModal()}
          onClose={handleCloseRemovingConfirmationModal}
          okText={t('a_common_delete')}
          onOk={selectedTypesCount === 0 ? handleDeleteWallet : handleDeleteDocument}
          cancelText={t('a_common_cancel')}
          onCancel={handleCloseRemovingConfirmationModal}
          type={ConfirmationModalTypes.danger}
        >
          <TextInModalBox>
            {selectedTypesCount === 0 ? t('l_dw_deleteDWMessage') : generateTextRemoveModal()}
          </TextInModalBox>
        </DialogModal>
      </DocumentTypesToolbarBox>

      <MobileToolbar
        items={menuItems}
        headContent={null}
      />
    </>
  )
}

const DocumentTypesToolbarBox = styled.div`
  padding: 0 24px;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    display: none;
  }
`

const TextInModalBox = styled.span`
  max-width: 450px;
  font-size: 14px;
`

const StyledPrimaryButton = styled(PrimaryButton)`
  width: 100%;
  transition: all 0.4s linear;

  :hover {
    background-color: var(--button-primary-hover);
  }
`
