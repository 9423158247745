import { STYLED_VARIABLES } from 'constants/styledVariables'

import { useState } from 'react'

import { MenuWithActionItems, PrimaryButton, SecondaryButton } from '@cloudike/web_ui_components'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from  "store"
import { MobileToolbar } from 'features/common/right-sidebar/MobileToolbar'
import { DialogModal } from 'ui/DialogModal'
import { appActions } from 'store/app'
import { analytics, ANALYTICS_EVENTS } from 'features/common/analytics'
import { IItemSchema } from '@cloudike/web_photos/dist/types/intarfaces/IAlbumItem'

import { getTrashBinSelectedItemsSelector, getTrashBinTotalItemsCountSelector } from './selectors'
import { TRASH_BIN_TYPES, deleteTrashBinItemsThunk, emptyTrashBinThunk, fullRestoreTrashBinThunk, restoreTrashBinSelectedItemsThunk } from './trashBinSlice'
import { getTrashBinFilesSelectedItemsIdsSelector, getTrashBinFilesSelectedItemsSelector } from './files/selectors'
import { deleteFilesTrashBinItemsThunk, emptyFilesTrashBinThunk, fullRestoreFilesTrashBinThunk, restoreFilesTrashBinSelectedItemsThunk } from './files/trashBinFilesSlice'

interface TrashBinToolbarProps {
  className?: string,
  type: TRASH_BIN_TYPES
}

export const TrashBinToolbar: React.FC<TrashBinToolbarProps> = ({ className = '', type }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const totalItemsCount = getTrashBinTotalItemsCountSelector()
  const selectedItems = type === TRASH_BIN_TYPES.FILES ? getTrashBinFilesSelectedItemsSelector() : getTrashBinSelectedItemsSelector()
  const selectedItemsCount = selectedItems.length
  const selectedFilesIds = getTrashBinFilesSelectedItemsIdsSelector()

  const [fullEmptyConfirmationModalOpened, toggleFullEmptyConfirmationModal] = useState(false)
  const [fullRestoreConfirmationModalOpened, toggleFullRestoreConfirmationModal] = useState(false)
  const [restoreConfirmationModalOpened, toggleRestoreConfirmationModal] = useState(false)
  const [removingConfirmationModalOpened, toggleRemovingConfirmationModal] = useState(false)

  const handleOpenFullRestoreConfirmationModal = () => {
    toggleFullRestoreConfirmationModal(true)
  }

  const handleCloseFullRestoreConfirmationModal = () => {
    toggleFullRestoreConfirmationModal(false)
  }

  const handleOpenRestoreConfirmationModal = () => {
    toggleRestoreConfirmationModal(true)
  }

  const handleCloseRestoreConfirmationModal = () => {
    toggleRestoreConfirmationModal(false)
  }

  const handleOpenRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(true)
  }

  const handleCloseRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(false)
  }

  const handleOpenFullEmptyConfirmationModal = () => {
    toggleFullEmptyConfirmationModal(true)
  }

  const handleCloseFullEmptyConfirmationModal = () => {
    toggleFullEmptyConfirmationModal(false)
  }

  const handleDeleteSelectedItems = () => {
    handleCloseRemovingConfirmationModal()
    if (type === TRASH_BIN_TYPES.FILES) {
      dispatch(deleteFilesTrashBinItemsThunk({ ids: selectedFilesIds }))
    } else {
      dispatch(deleteTrashBinItemsThunk({ items: selectedItems as IItemSchema[] }))
    }

    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleEmptyTrashBin = () => {
    handleCloseFullEmptyConfirmationModal()

    if (type === TRASH_BIN_TYPES.FILES) {
      dispatch(emptyFilesTrashBinThunk())
    } else {
      dispatch(emptyTrashBinThunk())
    }

    dispatch(appActions.toggleRightMenuOnMobile(false))
    analytics.push(ANALYTICS_EVENTS.WEB_EMPTY_TRASHBIN_CLICK)
  }

  const handleFullRestore = () => {
    handleCloseFullRestoreConfirmationModal()

    if (type === TRASH_BIN_TYPES.FILES) {
      dispatch(fullRestoreFilesTrashBinThunk())
    } else {
      dispatch(fullRestoreTrashBinThunk())
    }

    dispatch(appActions.toggleRightMenuOnMobile(false))
    analytics.push(ANALYTICS_EVENTS.WEB_TRASHBIN_FULLRESTORE_CLICK)
  }

  const handleRestore = () => {
    handleCloseRestoreConfirmationModal()
    if (type === TRASH_BIN_TYPES.FILES) {
      dispatch(restoreFilesTrashBinSelectedItemsThunk({ ids: selectedFilesIds }))
    } else {
      dispatch(restoreTrashBinSelectedItemsThunk({ items: selectedItems as IItemSchema[] }))
    }

    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const menuItems = (() => {
    const items = [{
      label: t('a_trash_deleteSelectedItems'),
      iconName: 'remove_icon',
      onClickItem: handleOpenRemovingConfirmationModal
    }]

    return items
  })()

  const mobileMenuItems = (() => {
    const items = [
      !!selectedItemsCount && {
        label: t('a_common_restore'),
        iconName: 'restore',
        onClickItem: handleOpenRestoreConfirmationModal
      },
      !!selectedItemsCount && {
        label: t('a_trash_deleteSelectedItems'),
        iconName: 'remove_icon',
        onClickItem: handleOpenRemovingConfirmationModal
      },
      !selectedItemsCount && {
        label: t('a_trash_emptyTrashBin'),
        iconName: 'delete_forever',
        onClickItem: handleOpenFullEmptyConfirmationModal
      },
      !selectedItemsCount && {
        label: t('a_trash_fullRestoreTrashBin'),
        iconName: 'restore',
        onClickItem: handleOpenFullRestoreConfirmationModal
      },
    ].filter(item => !!item)

    return items
  })()

  return (
    <>
      <TrashBinToolbarBox className={className}>
        {
          !!selectedItemsCount && (
            <StyledPrimaryButton
              actionName={t('a_common_restore')}
              onAction={handleOpenRestoreConfirmationModal}
            />
          )
        }

        {
          !selectedItemsCount && Number(totalItemsCount) > 0 && (
            <>
              <StyledPrimaryButton
                actionName={t('a_trash_emptyTrashBin')}
                onAction={handleOpenFullEmptyConfirmationModal}
              />

              <StyledSecondaryButton
                actionName={t('a_trash_fullRestoreTrashBin')}
                onAction={handleOpenFullRestoreConfirmationModal}
              />
            </>
          )}

        {
          !!selectedItemsCount &&
          <MenuWithActionItems menuItems={menuItems} />
        }
      </TrashBinToolbarBox>

      <MobileToolbar
        items={mobileMenuItems}
        headContent={null}
      />

      <DialogModal
        isOpened={fullRestoreConfirmationModalOpened}
        title={t('a_trash_fullRestoreTrashBin')}
        onClose={handleCloseFullRestoreConfirmationModal}
        okText={t('a_trash_fullRestoreTrashBin')}
        onOk={handleFullRestore}
        cancelText={t('a_common_cancel')}
        onCancel={handleCloseFullRestoreConfirmationModal}
        withLongButtons
      >
        <TextInModalBox>
          {t('l_common_fullRestoreItemsFromTrashConfirmation')}
        </TextInModalBox>
      </DialogModal>

      <DialogModal
        isOpened={restoreConfirmationModalOpened}
        title={t('l_trash_restoreConfirmation', { number: selectedItemsCount })}
        onClose={handleCloseRestoreConfirmationModal}
        okText={t('a_common_restore')}
        onOk={handleRestore}
        cancelText={t('a_common_cancel')}
        onCancel={handleCloseRestoreConfirmationModal}
        withLongButtons
      />

      <DialogModal
        isOpened={removingConfirmationModalOpened}
        title={t('l_common_deleteItemsConfirmation', { number: selectedItemsCount })}
        onClose={handleCloseRemovingConfirmationModal}
        okText={t('a_trash_deleteSelectedItems')}
        onOk={handleDeleteSelectedItems}
        cancelText={t('a_common_cancel')}
        onCancel={handleCloseRemovingConfirmationModal}
        withLongButtons
      />

      <DialogModal
        isOpened={fullEmptyConfirmationModalOpened}
        header={(
          <ModalCustomHeader>
            {t('l_common_deleteFromTrashConfirmation')}
          </ModalCustomHeader>
        )}
        onClose={handleCloseFullEmptyConfirmationModal}
        okText={t('a_trash_emptyTrashBin')}
        onOk={handleEmptyTrashBin}
        cancelText={t('a_common_cancel')}
        onCancel={handleCloseFullEmptyConfirmationModal}
        withLongButtons
      />
    </>
  )
}

const TextInModalBox = styled.span`
  font-size: 14px;
`

const TrashBinToolbarBox = styled.div`
  padding: 0 24px;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    display: none;
  }
`

const StyledPrimaryButton = styled(PrimaryButton)`
  width: 100%;

  && {
    padding: 8px 0;
  }

  :hover {
    background-color: var(--button-primary-hover);
  }
`

const StyledSecondaryButton = styled(SecondaryButton)`
  margin-top: 16px;
  width: 100%;
  justify-content: center;

  && {
    padding: 8px 0;
  }
`

const ModalCustomHeader = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: var(--text-primary);
  padding: 24px 29px 10px;
  max-width: 428px;
`
