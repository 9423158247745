import { LOADING_STATUSES } from 'constants/loadingStatuses'
import { STYLED_VARIABLES } from 'constants/styledVariables'
import { DATES_FORMATS } from 'constants/datesFormats'

import React, { useEffect } from 'react'

import { fetchTokensThunk, removeAllTokensThunk, removeTokenThunk } from 'features/user/userSlice'
import { useAppDispatch } from  "store"
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { getUserTokensSelector } from 'features/user/selectors'
import { formatDate } from 'utils/formatDate'

import detect from '../../../../utils/detect'
import { useMobileDetection } from "../../../common/hooks"
import logout from '../../../../assets/icons/logout.svg'

import {
  ProfileTable,
  ProfileTableBox,
  ProfileTableText,
  ProfileTBody,
  ProfileTd,
  ProfileTHead,
  ProfileTHeadTh,
  ProfileTHeadTr,
  ProfileTr
} from './ProfileStyledComponents'

export const ProfileTokens = ({ className = '' }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const isMobile = useMobileDetection(767)

  const { items: tokens, status } = getUserTokensSelector()

  useEffect(() => {
    dispatch(fetchTokensThunk())
  }, [])

  const handleRemoveToken = (tokenId) => {
    dispatch(removeTokenThunk({ tokenId }))
  }

  const handleClickRemoveAllTokens = () => {
    dispatch(removeAllTokensThunk())
  }

  const parseUserAgent = (ua) => {
    const item = detect.parse(ua)
    if (item.browser.name) {
      return item.browser.name + ' / ' + item.os.name
    } else {
      return ua
    }
  }

  return (
    <ProfileTokensBox className={className}>
      <TitleBox>
        <Title>
          {t('l_settiings_tokensTitle')}
        </Title>

        <ButtonBox
          onClick={handleClickRemoveAllTokens}
          title={t('a_common_disconnectAll')}
        >
          <LogoutImgBox src={logout} />

          <LogoutTextBox>
            {t('a_common_disconnectAll')}
          </LogoutTextBox>
        </ButtonBox>
      </TitleBox>


      <ProfileTableBox>
        <ProfileTable>
          <Colgroup>
            <Col className="info" />

            <Col className="date" />

            <Col className="action" />
          </Colgroup>

          <ProfileTHead>
            <ProfileTHeadTr>
              <ProfileTHeadTh>
                {t('l_common_deviceInfo')}
              </ProfileTHeadTh>

              <ProfileTHeadTh>
                {t('l_settings_created')}
              </ProfileTHeadTh>

              <ProfileTHeadTh>
                {t('l_common_action')}
              </ProfileTHeadTh>
            </ProfileTHeadTr>
          </ProfileTHead>

          {status === LOADING_STATUSES.LOADING && (
            <ProfileTBody>
              <ProfileTr>
                <ProfileTd colSpan="3">
                  <ProfileTableText>
                    {t('l_common_loading')}
                                        ...
                  </ProfileTableText>
                </ProfileTd>
              </ProfileTr>
            </ProfileTBody>
          )}

          {!tokens.length && status !== LOADING_STATUSES.LOADING && (
            <ProfileTBody>
              <ProfileTr>
                <ProfileTd colSpan="3">
                  <ProfileTableText>
                    {t('You have no tokens')}
                  </ProfileTableText>
                </ProfileTd>
              </ProfileTr>
            </ProfileTBody>
          )}

          {!!tokens.length && (
            <ProfileTBody>
              {tokens.map(token => (
                <ProfileTr key={token.id}>
                  <ProfileTd>
                    <ProfileTableText className="ellipsis">
                      {parseUserAgent(token.device_description) || t('l_common_unknownDevice')}
                    </ProfileTableText>
                  </ProfileTd>

                  <ProfileTd>
                    {!isMobile && formatDate(token.created, DATES_FORMATS.profileTables)}
                  </ProfileTd>

                  <ProfileTd>
                    <RemoveBnt onClick={() => handleRemoveToken(token.id)}>
                      {t('l_common_removeToken')}
                    </RemoveBnt>
                  </ProfileTd>
                </ProfileTr>
              ))}
            </ProfileTBody>
          )}
        </ProfileTable>
      </ProfileTableBox>
    </ProfileTokensBox>
  )
}

const ProfileTokensBox = styled.div`
  margin-top: 32px;
`

const Title = styled.h2`
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: var(--text-primary);
`

const RemoveBnt = styled.button`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: var(--accent-normal);
  white-space: nowrap;
`

const Colgroup = styled.colgroup``

const Col = styled.col`
  &.info {
    width: 250px;
  }

  &.date {
    width: 0;
    visibility: collapse;
  }

  &.action {
    width: 250px;
  }

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_90}) {
    &.date {
      width: 120px;
      visibility: initial;
    }
  }
`

const TitleBox = styled.div`
  display: flex;
  justify-content: space-between;
`

const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  -webkit-tap-highlight-color: var(--divider-primary);
`

const LogoutImgBox = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`

const LogoutTextBox = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_85}) {
    display: none;
  }
`
