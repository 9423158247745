import { useAppSelector } from "store"
import { IItemSchema } from "@cloudike/web_photos/dist/types/intarfaces/IAlbumItem"
import { createSelector } from "@reduxjs/toolkit"

import { formatDate } from "../../../utils/formatDate"

import { TIMELINE_FILTERS, timelineSelectors } from "./timelineSlice"

export const getTimelineLoadingStatusSelector = () => useAppSelector(state =>
  state.timeline.status
)

export const getTimelineLoadingSmartStatusSelector = () => useAppSelector(state =>
  state.timeline.loadingSmartStatus
)

export const getTimelineAllItemsRawSelector = state => timelineSelectors.selectAll(state.timeline)

export const getTimelineItemsIdsRawSelector = state => state.timeline.selectedItemsIds

export const getTimelineSelectedItemsSelector = () => useAppSelector(
  createSelector(
    getTimelineAllItemsRawSelector,
    getTimelineItemsIdsRawSelector,
    (items, selectedItemsIds) => items.filter(item => selectedItemsIds.includes(item.id))
  )
)


export const getTimelineSelectedItemsIdsSelector = () => useAppSelector(state =>
  state.timeline.selectedItemsIds
)

export const getTimelineSelectedItemsCountSelector = () => useAppSelector(state =>
  state.timeline.selectedItemsIds.length
)

export const getTimelineItemsSelector = () =>
  useAppSelector(
    createSelector(
      getTimelineAllItemsRawSelector,
      (items) =>
        items
    )
  )

export const getTimelineTotalItemsCountSelector = () => useAppSelector(state => state.timeline.totalItemsCount)

export const getFlashbackAlbumsSelector = () => useAppSelector(state => state.timeline.flashbackAlbums)

export const getDateFormatSelector = () => useAppSelector(state => state.timeline.dateFormat)

export const getTimelineFilterSelector = () => useAppSelector(state => state.timeline.filter)

export const getSortFormatSelector = () => useAppSelector(state => state.timeline.sortFormat)

export const getTimelineItemsByDateSelector = (dateFormat: string) => useAppSelector(
  createSelector(
    getTimelineAllItemsRawSelector,
    items => {
      const getItemDate = (item: IItemSchema) => formatDate(item.created_original, dateFormat)

      const itemsByDates = items.reduce((r, item) => {
        const date = getItemDate(item)

        if (!r[date]) {
          return { ...r, [date]: [item] }
        }

        return { ...r, [date]: [...r[date], item] }
      }, {})

      const itemsByDatesArr = Object.entries<IItemSchema[]>(itemsByDates)
        .map(([key, value]) => ({ date: key, items: value }))
        .filter(date => !!date.items[0])
        .sort((a, b) => b.items[0]?.created_original - a.items[0]?.created_original)

      return itemsByDatesArr
    }))
