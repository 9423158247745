import { STYLED_VARIABLES } from 'constants/styledVariables'
import { getModalsRootElement } from 'constants/modals'

import { useState } from 'react'

import { MenuWithActionItems } from '@cloudike/web_ui_components'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from  "store"
import { MobileToolbar } from 'features/common/right-sidebar/MobileToolbar'
import { sharingActions } from 'features/sharing/sharingSlice'
import { SDK_TYPES } from 'sdk/sdkConstants'
import { AlbumType } from '@cloudike/web_photos'
import { appActions } from 'store/app'
import { RenameAlbumModal } from 'features/albums/album/RenameAlbumModal'

import { getPublicLinksSelectedItemsSelector } from './selectors'
import { downloadAlbumThunk, renameAlbumThunk } from './publicLinksSlice'

interface PublicLinksToolbarProps {
  className?: string,
  type: SDK_TYPES
}

export const PublicLinksToolbar: React.FC<PublicLinksToolbarProps> = ({ className = '', type }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const selectedItems = getPublicLinksSelectedItemsSelector()
  const selectedItemsCount = selectedItems.length

  const [renameModalOpened, toggleRenameModal] = useState(false)

  const handleEditPublicLink = () => {
    dispatch(sharingActions.setSharingAlbumConfig({
      sdkType: type,
      sharedAlbumType: AlbumType.SHARED
    }))
    dispatch(sharingActions.setSharedItemData(selectedItems[0]))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleDownloadAlbum = () => {
    dispatch(downloadAlbumThunk({ type, albumId: selectedItems[0].id }))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleOpenRenameModal = () => {
    toggleRenameModal(true)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleCloseRenameModal = () => {
    toggleRenameModal(false)
  }

  const handleRenameAlbum = (name) => {
    dispatch(renameAlbumThunk({ type, name: name.trim(), id: selectedItems[0].id }))
    toggleRenameModal(false)
  }

  const menuItems = (() => {
    const items = [{
      label: t('a_common_share'),
      iconName: 'share',
      onClickItem: handleEditPublicLink
    },
    {
      label: t('a_albums_downloadAlbum'),
      iconName: 'download_empty',
      onClickItem: handleDownloadAlbum
    },
    {
      label: t('a_common_rename'),
      iconName: 'pencil',
      onClickItem: handleOpenRenameModal
    }
    ]

    return items
  })()

  return (
    <>
      <PublicLinksBox className={className}>
        {
          !!selectedItemsCount &&
          <MenuWithActionItems menuItems={menuItems} />
        }
      </PublicLinksBox>

      <MobileToolbar
        items={menuItems}
        headContent={null}
      />

      <RenameAlbumModal
        isShown={renameModalOpened}
        albumNameBeforeRenaiming={selectedItems[0]?.description}
        parentBlock={getModalsRootElement()}
        onCloseModal={handleCloseRenameModal}
        onRenameAlbum={handleRenameAlbum}
        texts={{
          TITLE: selectedItems[0]?.description ? t('a_albums_renameAlbum') : t('l_common_addName'),
          PLACEHOLDER: t('l_common_untitledAlbum'),
          NAME_BUTTON_CANCEL: t('a_common_cancel'),
          NAME_BUTTON_ACTION: t('a_common_save'),
          SUB_TITLE: t('l_albums_albumNameField'),
          TITLE_ICON: selectedItems[0]?.description ? 'edit' : 'add_name'
        }}
      />
    </>
  )
}

const PublicLinksBox = styled.div`
  padding: 0 24px;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    display: none;
  }
`
