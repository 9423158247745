import { request } from "./request"

export const offersApi = {
  getOffers: async (offerId: string) => {
    return request('GET', `/api/2/offers/${offerId}`)
  },
  getOfferContent: async (data): Promise<{content: string, url: string}> => {
    let offerId = ''
    if (!!data.type) {
      const rsp = await offersApi.getOffersData(data)
      offerId = rsp._embedded.offers[0].id
    } else {
      offerId = data.offerId
    }

    const { content, url } = await offersApi.getOffers(offerId)
    return { content, url }
  },
  getOffersData: async (data) => {
    return request('GET', `/api/2/offers/`, data)
  },
}
