import { getModalsRootElement } from 'constants/modals'
import { formatImgUrl } from 'constants/configs/theme.config'

import { useEffect, useLayoutEffect, useRef, useState } from 'react'

import ReactDOM from 'react-dom'
import { PageOverlay, SpriteIcon, STYLED_VARIABLES } from '@cloudike/web_ui_components'
import styled from 'styled-components'
import { useAppDispatch } from "store"
import { insertStyleToIframe } from 'utils/iframe'
import _ from 'lodash'
import { request } from 'api/request'
import { useTranslation } from 'react-i18next'
import { useDocumentKeyDown } from 'features/common/hooks/useDocumentKeyDown'
import classNames from "classnames"

import { formatFileName, pushCreateEditItemPublicLink } from '../filesUtils'
import { downloadNodesThunk } from '../filesSlice'
import {
  ArrowBox,
  ArrowContainer,
  ArrowIcon,
  FilePreview,
  Iframe,
  ImageBox,
  Img,
  MainContent,
  PreviewModalBox as FilesPreviewModalBox,
  SHiddenImg,
  StyledSpinner,
  TopRow,
} from "../../common/preview-modal/styles"
import { VideoPreview } from "../../common/preview-modal/Video"
import { useMobileDetection } from "../../common/hooks"
import { ContentPlaceholder } from "../../common/preview-modal/ContentPlaceholder"
import { downloadSharedFilesArchiveThunk } from "../../public-link-files/publicLinkFilesSlice"
import { downloadNodesThunk as downloadPublicLinksNodesThunk } from "../../public-links-files/publicLinksFilesSlice"
import { sharingActions } from "../../sharing/sharingSlice"
import { SDK_TYPES } from "../../../sdk/sdkConstants"
import { analytics, ANALYTICS_EVENTS } from "../../common/analytics"
import { getFilesPublicLinkPermissionSelector } from "../../public-link-files/selectors"
import { getIsSomeSpecialModalOpened } from "../../common/selectors"

import { filesPreviewActions, handleNextPhotoThunk, handlePrevPhotoThunk, PREVIEW_TYPES } from './filesPreviewSlice'
import {
  getCurrentFilesPreviewItemIndexSelector,
  getCurrentFilesPreviewModalStatusSelector,
  getFilesPreviewErrorSelector,
  getFilesPreviewItemsSelector,
  getFilesPreviewLoadingMoreSelector,
  getFilesPreviewLoadingSelector,
  getFilesPreviewTypeSelector,
} from './selectors'
import { FilesPreviewToolbar } from './FilesPreviewToolbar'


const getPreviewLink = (item) => {
  if (!item?._embedded?.previews?.sizes?.preview) {
    return ''
  }

  const urlTemplate = _.template(item._embedded.previews.url_template, { interpolate: /\{(.+?)\}/g })
  const url = urlTemplate({ size_name: 'preview' }) + `&jwt=${item._embedded.previews.jwt}`

  return url
}

const hasPdfPreview = (item) => {
  return !!item?._embedded?.previews?.sizes?.preview && item?._embedded?.previews?.sizes?.preview?.type === 'pdf'
}

export const FilesPreviewModal = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const isSomeSpecialModalOpened = getIsSomeSpecialModalOpened()
  const items = getFilesPreviewItemsSelector()
  const totalItemsCount = items.length
  const currentIndex = getCurrentFilesPreviewItemIndexSelector()
  const isModalOpened = getCurrentFilesPreviewModalStatusSelector()
  const isLoading = getFilesPreviewLoadingSelector()
  const isLoadingMore = getFilesPreviewLoadingMoreSelector()
  const type = getFilesPreviewTypeSelector()
  const hasError = getFilesPreviewErrorSelector()
  const isMobile = useMobileDetection()
  const rootNodePermission = getFilesPublicLinkPermissionSelector()

  const [previewUrl, setPreviewUrl] = useState(null)
  const [fileName, setFileName] = useState('')

  const currentItem = items[currentIndex] as any

  const loadingTimeoutRef = useRef(null)
  const abortControllerRef = useRef(null)
  const currentItemIdRef = useRef(null)
  const currentItemPreviewUrlRef = useRef(null)

  useEffect(() => {
    if(!currentItem?.name) return
    const fileName = getShortFileName(currentItem.name)
    setFileName(fileName)
  }, [currentItem])

  useEffect(() => {
    const handleResize = _.debounce(() => {
      if(!currentItem?.name) return
      const fileName = getShortFileName(currentItem.name)
      setFileName(fileName)
    }, 300)

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [currentItem])

  const getShortFileName = (name: string) => {
    return formatFileName(name, [
      { width: 0, length: 5 },
      { width: 374, length: 10 },
      { width: 430, length: 15 },
      { width: 500, length: 25 },
      { width: 1024, length: 13 },
      { width: 1249, length: 25 },
      { width: 1279, length: 15 },
      { width: 1350, length: 25 },
      { width: 1439, length: 35 },
    ])
  }

  const handleFinishLoading = () => {
    dispatch(filesPreviewActions.setLoading(false))
    clearTimeout(loadingTimeoutRef.current)
  }

  const handleNext = () => {
    if (currentIndex >= totalItemsCount - 1 || !isModalOpened || isSomeSpecialModalOpened) {
      return
    }

    dispatch(handleNextPhotoThunk())
  }

  const handlePrev = () => {
    if (currentIndex <= 0 || !isModalOpened || isSomeSpecialModalOpened) {
      return
    }

    dispatch(handlePrevPhotoThunk())
  }

  const handleError = () => {
    dispatch(filesPreviewActions.setLoading(false))
    dispatch(filesPreviewActions.setError(true))
  }

  const handleDownload = () => {
    if (type === PREVIEW_TYPES.SHARED_FILES) {
      dispatch(downloadSharedFilesArchiveThunk({ ids: [currentItem.id] }))

      return
    }

    if (type === PREVIEW_TYPES.FILES_PUBLIC_LINKS) {
      dispatch(downloadPublicLinksNodesThunk({ ids: [currentItem.id] }))

      return
    }

    dispatch(downloadNodesThunk({ ids: [currentItem.id] }))
  }

  const handleClose = () => {
    if (isSomeSpecialModalOpened) {
      return
    }

    dispatch(filesPreviewActions.resetState())
  }

  useEffect(() => {
    if (currentIndex + 1 >= totalItemsCount) {
      dispatch(filesPreviewActions.setCurrentItemIndex(totalItemsCount - 1))
    }

    if (totalItemsCount === 0) {
      dispatch(filesPreviewActions.resetState())
    }
  }, [currentIndex, totalItemsCount])

  useEffect(() => {
    if (!isModalOpened) {
      dispatch(filesPreviewActions.resetState())
    }
  }, [isModalOpened])

  useEffect(() => {
    clearTimeout(loadingTimeoutRef.current)
    setPreviewUrl(null)

    if (abortControllerRef.current) {
      abortControllerRef.current.abort()
      abortControllerRef.current = null
    }

    const fetchFileData = async () => {
      if (!isModalOpened) {
        return
      }

      dispatch(filesPreviewActions.setLoading(true))
      dispatch(filesPreviewActions.setError(false))

      const type = currentItem.file_info.type
      const mime = currentItem.file_info.mime

      try {
        abortControllerRef.current = new AbortController()
        const { signal } = abortControllerRef.current
        if (mime === 'application/pdf') {
          const fileData = await request('GET', currentItem._links.self.href, {}, { host: null, signal })
          const content = await request('GET', fileData._links.content.href, {}, { host: null, signal })
          setPreviewUrl(encodeURIComponent(content._links.data.href))
        } else
          if (hasPdfPreview(currentItem)) {
            setPreviewUrl(encodeURIComponent(getPreviewLink(currentItem)))
          } else
            if (type === 'video') {
              const fileData = await request('GET', currentItem._links.self.href, {}, { host: null, signal })
              const content = await request('GET', fileData._links.content.href, {}, { host: null, signal })

              setPreviewUrl(content._links.data.href)
            } else
              if (type === 'audio') {
                const fileData = await request('GET', currentItem._links.self.href, {}, { host: null, signal })
                const content = await request('GET', fileData._links.content.href, {}, { host: null, signal })

                setPreviewUrl(content._links.data.href)
              } else
                if (type === 'image') {
                  const url = getPreviewLink(currentItem)

                  if (url === currentItemPreviewUrlRef.current) {
                    dispatch(filesPreviewActions.setLoading(false))
                  }

                  currentItemPreviewUrlRef.current = url
                  setPreviewUrl(url)
                } else {
                  throw new Error()
                }

        clearTimeout(loadingTimeoutRef.current)

        if (currentItemIdRef.current !== currentItem.id) {
          loadingTimeoutRef.current = setTimeout(() => {
            dispatch(filesPreviewActions.setLoading(false))
            dispatch(filesPreviewActions.setError(true))
          }, 60000)

          currentItemIdRef.current = currentItem.id
        }
      } catch (error) {
        console.log(error)
        if (error?.name === 'AbortError') {
          return
        }
        dispatch(filesPreviewActions.setLoading(false))
        dispatch(filesPreviewActions.setError(true))
      }
    }

    if (currentItem) {
      fetchFileData()
    }
  }, [currentItem, isModalOpened])

  useDocumentKeyDown(37, handlePrev)
  useDocumentKeyDown(39, handleNext)
  useDocumentKeyDown(27, handleClose)

  const handleSharingButtonClick = () => {
    dispatch(sharingActions.setSharingAlbumConfig({
      sdkType: SDK_TYPES.FILES
    }))
    dispatch(sharingActions.setSharedItemData(currentItem))
    pushCreateEditItemPublicLink(currentItem)
    analytics.push(ANALYTICS_EVENTS.WEB_FILES_CREATE_LINK)
  }

  if (!currentItem) {
    return null
  }

  const prevImageUrl = items[currentIndex - 1]?.file_info?.type === 'image' ? getPreviewLink(items[currentIndex - 1]) : ''
  const nextImageUrl = items[currentIndex + 1]?.file_info?.type === 'image' ? getPreviewLink(items[currentIndex + 1]) : ''

  return ReactDOM.createPortal(
    <>
      {
        isModalOpened && (
          <FilesPreviewModalBox>
            <SHiddenImg src={prevImageUrl} />

            <SHiddenImg src={nextImageUrl} />

            <TopRow>
              <Title>
                <TitleContainerBox>
                  <FileNameBox>
                    {fileName}
                  </FileNameBox>

                  {currentItem.is_shared && (
                    <SharingButtonBox
                      type="button"
                      onClick={handleSharingButtonClick}
                    >
                      <SharingIconBox iconName={'ic_link'} />
                    </SharingButtonBox>
                  )}
                </TitleContainerBox>
              </Title>

              {!!currentItem && (
                <FilesPreviewToolbar permission={rootNodePermission} />
              )}
            </TopRow>

            <MainContent>
              <ArrowContainer>
                {
                  currentIndex !== 0 && (
                    <ArrowBox
                      onClick={handlePrev}
                    >
                      <ArrowIcon iconName="arrow_left" />
                    </ArrowBox>
                  )
                }
              </ArrowContainer>

              <ImageBox>
                {
                  !!currentItem && currentItem.file_info.type === 'image' && !!previewUrl && !hasError && (
                    <Img
                      src={previewUrl}
                      onLoad={handleFinishLoading}
                      onError={handleError}
                      fetchPriority='high'
                      className={classNames({ 'not-loaded' : isLoading })}
                    />
                  )
                }

                {
                  !!currentItem && currentItem.file_info.type === 'video' && !!previewUrl && !hasError && (
                    <VideoPreview
                      src={previewUrl + '#t=0.001'}
                      onCanPlay={handleFinishLoading}
                      onError={handleError}
                    />
                  )
                }

                {
                  !!currentItem && currentItem.file_info.type === 'audio' && !!previewUrl && !hasError && (
                    <Audio
                      src={previewUrl + '#t=0.001'}
                      onCanPlay={handleFinishLoading}
                      onError={handleError}
                    />
                  )
                }

                {
                  !!currentItem && !!previewUrl && !hasError && (currentItem?.file_info?.mime === 'application/pdf' || hasPdfPreview(currentItem)) && (
                    <Pdf onLoad={handleFinishLoading}
                      src={formatImgUrl(`/pdf-viewer/web/viewer.html?file=${previewUrl}`)}
                    />
                  )
                }

                {
                  hasError && (
                    <FilePreview>
                      <ContentPlaceholder
                        currentItem={currentItem}
                        onClickDownload={handleDownload}
                      />
                    </FilePreview>
                  )}

                {(isLoading || isLoadingMore) && <StyledSpinner color='#E1E1E2' />}

                {isMobile && <ShadowLayerTop />}

              </ImageBox>

              <ArrowContainer className="right">
                {
                  currentIndex !== totalItemsCount - 1 && (
                    <ArrowBox
                      className="rotated"
                      onClick={handleNext}
                    >
                      <ArrowIcon iconName="arrow_left" />
                    </ArrowBox>
                  )
                }
              </ArrowContainer>
            </MainContent>
          </FilesPreviewModalBox>
        )}

      <PageOverlay
        active={isModalOpened}
        backgroundColor="rgba(0, 0, 0, 0.8)"
      />
    </>, getModalsRootElement()
  )
}

const Audio = ({ onCanPlay, onError, src }) => {
  const audioRef = useRef(null)

  useLayoutEffect(() => {
    audioRef.current.src = src


    return () => {
      audioRef.current.src = ''
    }
  }, [src])

  return (
    <audio
      onCanPlay={onCanPlay}
      onError={onError}
      controls
      preload="auto"
      ref={audioRef}
    />
  )
}

const Pdf = ({ src, onLoad }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    const styles = [
      'body {background-image: none !important}',
      '#openFile, #viewBookmark {display: none !important}',
      '#secondaryOpenFile, #secondaryDownload, #secondaryViewBookmark {display: none !important}',
      '#secondaryToolbarButtonContainer #firstPage {display: none !important}',
      '#secondaryToolbarButtonContainer #lastPage {display: none !important}',
      '#secondaryToolbarButtonContainer #toggleHandTool {display: none !important}',
      '#secondaryToolbarButtonContainer #documentProperties {display: none !important}',
      '#secondaryToolbarButtonContainer .horizontalToolbarSeparator {display: none !important}',
      '#toolbarViewerLeft {display: none !important}',
      '#toolbarViewerRight {right: 36px !important}',
      '#editorModeButtons {display: none !important}',
      '#editorModeSeparator {display: none !important}',
      '#secondaryToolbarToggle {display: none !important}',
      '.verticalToolbarSeparator {display: none !important}'
    ].join('')

    setTimeout(() =>
    {
      iframeRef?.current?.contentWindow && insertStyleToIframe(iframeRef.current.contentWindow, styles)
    }, 300)
  }, [iframeRef])

  return (
    <Iframe id="pdf-js-viewer"
      src={src}
      title="webviewer"
      frameBorder="0"
      onLoad={onLoad}
      ref={iframeRef}
    />
  )
}

const Title = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;  

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    margin-left: 16px;
    left: 16px;
    transform: unset;
    position: static;
    flex: 1;
    justify-content: unset;
    overflow: auto;
  }
`

const FileNameBox = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #FFFFFF;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 0;
    max-width: 350px;
    @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
        max-width: 300px; 
    }
    `

const ShadowLayerTop = styled.div`
  position: absolute;
  top: 0;
  height: 142px;
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.00) 100%);
`

const SharingIconBox = styled(SpriteIcon)`
  width: 16px;
  height: 16px;
  color: #FFFFFF;
`

const SharingButtonBox = styled.button`
  background: none;
  margin-left: 4px;
  margin-top: 4px;
`

const TitleContainerBox = styled.div`
  padding-right: 20px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
`
