import React, { useEffect, useRef } from 'react'

import { FilesUploadingWidget, IUploadingFile } from '@cloudike/web_ui_components'
import { useAppDispatch } from  "store"
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { goToSubscriptionsPage } from 'utils/subscriptions'

import { hideNotification, NOTIFICATION_TYPES, showNotification } from "../notifications"

import {
  closeWidgetThunk,
  removeFileFromUploadingThunk,
  restartFileUploadingThunk,
  resumeFileUploadingThunk,
  UPLOADING_STATUSES
} from './filesUploadingSlice'
import {
  getFilesUploadingStatusSelector,
  getFilesWithUnsupportedMediaTypeCountSelector,
  getIsPublicLinkOwnerSizeQuotaExceedErrorSelector,
  getIsSizeQuotaExceedErrorSelector,
  getUploadingFilesSelector,
  uploadingFilesState
} from './selectors'

const ConnectedFilesUploadingWidget = React.memo(() => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const files = getUploadingFilesSelector()
  const status = getFilesUploadingStatusSelector()
  const listState = uploadingFilesState()
  const isSizeQuotaExceedError = getIsSizeQuotaExceedErrorSelector()
  const isPublicLinkOwnerSizeQuotaExceedError = getIsPublicLinkOwnerSizeQuotaExceedErrorSelector()
  const unsupportedFilesCount = getFilesWithUnsupportedMediaTypeCountSelector()

  const sizeQuotaExceedNotification = useRef(null)
  const publicLinkOwnerSizeQuotaExceedNotification = useRef(null)
  const unsupportedFilesCountRef = useRef(0)

  useEffect(() => {
    const listener = (event) => {
      event.preventDefault()

      return event.returnValue = t('l_common_exitPage')
    }

    if (listState.isUploading) {
      window.addEventListener('beforeunload', listener)
    }

    return () => {
      window.removeEventListener('beforeunload', listener)
    }
  }, [listState, t])

  useEffect(() => {
    if (isSizeQuotaExceedError) {
      const notificationOptions = {
        isPermanent: true,
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_spaceError'),
        message: t('l_notification_uploadPhotoError'),
        typeError: 'SizeQuotaExceeded',
        callback: () => {
          hideNotification(sizeQuotaExceedNotification.current)
          sizeQuotaExceedNotification.current = null

          goToSubscriptionsPage()
        }
      }

      sizeQuotaExceedNotification.current = showNotification(notificationOptions)
    }
  }, [isSizeQuotaExceedError])

  useEffect(() => {
    if (isPublicLinkOwnerSizeQuotaExceedError && !publicLinkOwnerSizeQuotaExceedNotification.current) {
      const notificationOptions = {
        withHiddenArrow: true,
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_outOfStorage'),
        message: t('l_notification_uploadPhotosError'),
        typeError: 'SizeQuotaExceeded',
        callback: () => {
          hideNotification(publicLinkOwnerSizeQuotaExceedNotification.current)
          publicLinkOwnerSizeQuotaExceedNotification.current = null
        }
      }

      publicLinkOwnerSizeQuotaExceedNotification.current = showNotification(notificationOptions)
    }
  }, [files])

  useEffect(() => {
    if (unsupportedFilesCount > unsupportedFilesCountRef.current) {
      unsupportedFilesCountRef.current = unsupportedFilesCount

      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_invalidContent'),
      })
    }
  }, [unsupportedFilesCount])

  const handleCancelFileUploading = (file: IUploadingFile) => {
    dispatch(removeFileFromUploadingThunk(file))
  }

  const handleClose = () => {
    dispatch(closeWidgetThunk())
  }

  const handleResume = () => {
    dispatch(resumeFileUploadingThunk())
  }

  const handleReloadFileUploading = (item) => {
    dispatch(restartFileUploadingThunk(item))
  }

  if (status === UPLOADING_STATUSES.IDLE) {
    return null
  }

  return (
    <StyledWidget
      listState={listState}
      onCancelFileUploading={handleCancelFileUploading}
      onReloadFileUploading={handleReloadFileUploading}
      files={files}
      onClose={handleClose}
      onResume={handleResume}
      className="js-upload-widget"
      texts={{
        reload: t('l_files_reloadAtUpload'),
        error: t('l_files_errorAtUpload'),
        uploaded: t('l_common_uploaded'),
        items: t('l_files_itemsUploadWidget', { number: files.length }),
        confirmationModal: {
          title: t('l_files_cancelUploadTitle'),
          text: t('l_files_cancelUploadingConfirmation'),
          okText: t('a_common_ok'),
          cancelText: t('a_common_cancel')
        }
      }}
    />
  )
})

const StyledWidget = styled(FilesUploadingWidget)`
  && {
    position: unset;
    z-index: 15;
    margin: 8px 8px 0;
  }
`

export { ConnectedFilesUploadingWidget as FilesUploadingWidget }
