import { useEffect, useRef } from "react"

import { Form, Formik } from 'formik'
import { SpriteIcon, ModalDialog, ModalFooter, ModalHeader, PrimaryButton, SecondaryButton } from '@cloudike/web_ui_components'
import styled from "styled-components"
import { FormikProps } from "formik/dist/types"

import { getUiTheme } from "../../user/selectors"

type OptionsForPopupCreateNewFolder = {
  texts: any;
  onRenameAlbum: (newName: string) => void;
  onCloseModal: () => void;
  isShown: boolean;
  parentBlock: any,
  albumNameBeforeRenaiming: string
};

export const RenameAlbumModal = ({
  onCloseModal,
  onRenameAlbum,
  texts,
  isShown,
  parentBlock,
  albumNameBeforeRenaiming
}: OptionsForPopupCreateNewFolder) => {
  const initialValues = {
    name: albumNameBeforeRenaiming
  }

  const formikRef = useRef<FormikProps<typeof initialValues>>()
  const inputRef = useRef<HTMLInputElement>()

  const handleSubmit = (values: any) => {
    onRenameAlbum(values.name)
  }

  const theme = getUiTheme()

  useEffect(() => {
    if (isShown) {
      inputRef.current.focus()
      inputRef.current.select()
    }

    if (!isShown && formikRef.current) {
      formikRef.current?.resetForm()
    }
  }, [isShown])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnChange
      enableReinitialize
      innerRef={formikRef}
    >
      {({ values, errors, handleChange, handleSubmit, setErrors }) => (
        <Form className="modal-dialog__form">
          <ModalDialog
            header={(
              <ModalHeader
                title={texts.TITLE}
                titleIcon={(
                  <SpriteIcon iconName={texts.TITLE_ICON}
                    className="modal-dialog__title_icon"
                  />
                )}
              />
            )}
            footer={(
              <ModalFooter
                buttonList={[
                  <CloseButtonBox
                    key={'buttonCancel'}
                    actionName={texts.NAME_BUTTON_CANCEL}
                    onAction={() => {
                      setErrors({ name: '' })
                      onCloseModal()
                    }}
                  />,
                  <ButtonActionBox
                    key={'buttonAction'}
                    actionName={texts.NAME_BUTTON_ACTION}
                    onAction={handleSubmit}
                  />
                ]}
              />
            )}
            parentBlock={parentBlock}
            isOpened={isShown}
            onCloseModal={() => {
              setErrors({ name: '' })
              onCloseModal()
            }}
          >
            <TitleBox>
              {texts.SUB_TITLE}
            </TitleBox>

            <InputGroupBox>
              <InputBox
                theme={theme}
                name="name"
                className={`${errors.name && 'invalid-input'}`}
                type="text"
                placeholder={texts.PLACEHOLDER}
                autoFocus
                autoComplete="off"
                maxLength={40}
                onChange={handleChange}
                onBlur={handleChange}
                value={values.name}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') handleSubmit()
                }}
                ref={inputRef}
              />

              {errors.name && (
                <SpriteIcon iconName="warning"
                  className="modal-dialog__content_warning-icon"
                />
              )}
            </InputGroupBox>

            <ErrorMessageBox theme={theme}>
              {errors.name as string}
            </ErrorMessageBox>
          </ModalDialog>
        </Form>
      )}
    </Formik>
  )
}

const InputBox = styled.input`
  background: var(--background-primary);
  color: var(--text-primary);
  border: 1px solid var(--stroke-primary);
  border-radius: 4px;
  width: 100%;
  height: 44px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  padding: 10px 15px;
  margin-bottom: 4px;

  :active, :hover, :focus {
    outline: 0;
    outline-offset: 0;
  }

  &:focus-visible {
    outline: none;
    border: 1px solid var(--button-secondary-default-border);
  }

  &.invalid-input {
    border: 1px solid ${(props: any) => props.theme === 'light' ? 'var(--red-normal)' : 'var(--text-secondary)'};
  }
`

const InputGroupBox = styled.span`
  display: flex;
  align-items: center;
`

const TitleBox = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 152.34%;
  color: var(--text-primary);
`

const ErrorMessageBox = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  color: ${(props: any) => props.theme === 'light' ? 'var(--red-normal)' : 'var(--text-secondary)'};
  min-height: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 451px;
  overflow: hidden;
  margin-bottom: 5px;
`

const ButtonActionBox = styled(PrimaryButton)`
  height: 40px;
  border-radius: 4px;
  padding: 8px 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--button-primary-text);
  cursor: pointer;
`

const CloseButtonBox = styled(SecondaryButton)`
  height: 40px;
  border: 1px solid var(--stroke-primary);
  border-radius: 4px;
  padding: 8px 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--text-primary);
  margin-right: 8px;
`
