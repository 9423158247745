import { getModalsRootElement } from 'constants/modals'

import React, { useState } from 'react'

import {
  CreateNewFolderForm,
  MenuItem,
  MenuWithActionItems,
  PrimaryButton,
  STYLED_VARIABLES,
  UserMenu as UserMenuForUnauthorized
} from '@cloudike/web_ui_components'
import { UserMenu } from 'features/user/UserMenu'
import { getIsUserAuthorizedSelector } from 'features/user/userSlice'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from  "store"
import { appActions } from 'store/app'
import styled from 'styled-components'
import { redirectToExternalSSO } from 'utils/externalSSO'
import { DialogModal } from 'ui/DialogModal'
import { MobileToolbar } from 'features/common/right-sidebar/MobileToolbar'
import { validationSchemaForFileItem } from 'features/files/validationSchemaForFileItem'
import { useParams } from 'react-router-dom'
import { parsePathFromParams } from 'features/common/parsePathFromParams/parsePathFromParams'
import { NodeTypes } from "@cloudike/web_files"

import { CopyMoveModal, CopyMoveModalType as FilesCopyMoveModalTypes } from "../files/copy-move-modal/CopyMoveModal"
import { getUiTheme } from "../user/selectors"
import { MobileToolbarCounter } from "../common/mobile-toolbar-counter/MobileToolbarCounter"

import {
  getFilesPublicLinkCurrentFolderIdSelector,
  getFilesPublicLinkCurrentFolderSelector,
  getFilesPublicLinkErrorSelector, getFilesPublicLinkItemsSelector,
  getFilesPublicLinkNodeDataSelector,
  getFilesPublicLinkPermissionSelector,
  getFilesPublicLinkRootNodeTypeSelector,
  getFilesPublicLinkSelectedItemsIdsSelector,
  getFilesPublicLinkSelectedItemsSelector,
  getFilesPublicLinkShareIdSelector
} from './selectors'
import {
  checkAndUploadSharedFilesThunk,
  createFilesPublicLinkFolderThunk,
  deleteFilesPublicLinkNodesThunk,
  downloadSharedFilesArchiveThunk,
  FilesPublicLinksErrorTypes,
  publicLinkFilesActions,
  uploadSharedFolderThunk
} from './publicLinkFilesSlice'
import { CopyMoveModalType, PublicLinkCopyMoveModal } from './copy-move-modal/PublicLinkCopyMoveModal'

export const PublicLinkFilesSidebar = ({ className = '' }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const isAuthorized = getIsUserAuthorizedSelector()
  const selectedItems = getFilesPublicLinkSelectedItemsSelector()
  const selectedItemsIds = getFilesPublicLinkSelectedItemsIdsSelector()
  const permission = getFilesPublicLinkPermissionSelector()
  const error = getFilesPublicLinkErrorSelector()
  const catalogId = getFilesPublicLinkCurrentFolderIdSelector()
  const shareId = getFilesPublicLinkShareIdSelector()
  const nodeData = getFilesPublicLinkNodeDataSelector()
  const currentFolder = getFilesPublicLinkCurrentFolderSelector()
  const rootNodeType = getFilesPublicLinkRootNodeTypeSelector()
  const items = getFilesPublicLinkItemsSelector()

  const selectedItemsCount = selectedItems.length

  const params = useParams()
  const paths = parsePathFromParams(params, 'public')
  const currentFileFolder = paths.length === 0 ? '' : paths[paths.length - 1]

  const theme = getUiTheme()

  const [removingConfirmationModalOpened, toggleRemovingConfirmationModal] = useState(false)
  const [newFolderModalOpened, toggleNewFolderModal] = useState(false)
  const [copyMoveModalType, setCopyMoveModalType] = useState(null)
  const [copyModalOpened, toggleCopyModal] = useState(false)

  const handleOpenCopyToPersonalModal = () => {
    toggleCopyModal(true)
  }

  const handleCloseCopyToPersonalModal = () => {
    toggleCopyModal(false)
  }

  const handleSignIn = () => {
    redirectToExternalSSO()
  }

  const handleSignUp = () => {
    redirectToExternalSSO('/signup')
  }

  const handleOpenRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(true)
  }

  const handleCloseRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(false)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleDownloadArchive = () => {
    dispatch(downloadSharedFilesArchiveThunk({}))
  }

  const handleDownloadSelected = () => {
    dispatch(downloadSharedFilesArchiveThunk({ ids: selectedItemsIds }))
  }

  const handleRemoveItems = () => {
    dispatch(deleteFilesPublicLinkNodesThunk({ ids: selectedItemsIds }))
    handleCloseRemovingConfirmationModal()
  }

  const handleUploadFiles = (event) => {
    const files = event.target.files

    if (files.length > 0) {
      const callback = () => {
        event.target.value = ''
      }

      dispatch(checkAndUploadSharedFilesThunk({ files, callback }))
      dispatch(appActions.toggleRightMenuOnMobile(false))
    }
  }

  const handleUploadFolder = (event) => {
    const files = event.target?.files

    if (files.length === 0) {
      return
    }

    const callback = () => {
      event.target.value = ''
    }

    dispatch(uploadSharedFolderThunk({ files, callback }))
  }

  const handleOpenNewFolderModal = () => {
    toggleNewFolderModal(true)
  }

  const handleCloseNewFolderModal = () => {
    toggleNewFolderModal(false)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleRenameNode = () => {
    dispatch(publicLinkFilesActions.setRenamingItemId(selectedItemsIds[0]))
  }

  const handleCreateFolder = (name: string) => {
    if (name) {
      dispatch(
        createFilesPublicLinkFolderThunk({
          name,
          parentId: paths.length > 1 ? catalogId : '',
        })
      )
    }
    toggleNewFolderModal(false)
  }

  const handleCloseCopyMoveModal = () => {
    setCopyMoveModalType(null)
  }

  const menuItems = (() => {
    let items: React.ComponentProps<typeof MenuItem>[] = []

    if (!selectedItemsCount) {
      items = [
        permission === 'write' && rootNodeType === NodeTypes.DIR && {
          label: t('a_files_uploadFolder'),
          iconName: 'upload_folder',
          type: 'file',
          onClickItem: handleUploadFolder,
          accept: '*',
          acceptDirectory: true
        },
        permission === 'write' && rootNodeType === NodeTypes.DIR && {
          label: t('a_files_createFolder'),
          iconName: 'create_new_folder',
          onClickItem: handleOpenNewFolderModal
        },
        permission === 'write' && rootNodeType === NodeTypes.DIR && {
          label: t('a_files_uploadFiles'),
          iconName: 'upload',
          type: 'file',
          onClickItem: handleUploadFiles,
          accept: '*'
        },
        !!isAuthorized && {
          label: t('a_common_addToMyCloud'),
          iconName: 'add_photo_alternate_1',
          onClickItem: handleOpenCopyToPersonalModal,
        },
      ]
    }

    if (selectedItemsCount === 1) {
      items.push(
        !!isAuthorized && {
          label: t('a_common_addToMyCloud'),
          iconName: 'add_photo_alternate_1',
          onClickItem: handleOpenCopyToPersonalModal,
        },
        permission === 'write' && rootNodeType === NodeTypes.DIR && {
          label: t('a_common_move'),
          iconName: 'move_icon',
          onClickItem: () => setCopyMoveModalType(CopyMoveModalType.MOVE)
        },
        permission === 'write' && rootNodeType === NodeTypes.DIR && {
          label: t('a_common_copy'),
          iconName: 'copy',
          onClickItem: () => setCopyMoveModalType(CopyMoveModalType.COPY)
        },
        permission === 'write' && {
          label: t('a_common_rename'),
          iconName: 'pencil',
          onClickItem: handleRenameNode
        },
        permission === 'write' && rootNodeType === NodeTypes.DIR && {
          label: t('a_common_remove'),
          iconName: 'remove_icon',
          onClickItem: handleOpenRemovingConfirmationModal
        })
    }

    if (selectedItemsCount > 1) {
      items.push(
        !!isAuthorized && {
          label: t('a_common_addToMyCloud'),
          iconName: 'add_photo_alternate_1',
          onClickItem: handleOpenCopyToPersonalModal,
        },
        permission === 'write' && {
          label: t('a_common_move'),
          iconName: 'move_icon',
          onClickItem: () => setCopyMoveModalType(CopyMoveModalType.MOVE)
        },
        permission === 'write' && {
          label: t('a_common_copy'),
          iconName: 'copy',
          onClickItem: () => setCopyMoveModalType(CopyMoveModalType.COPY)
        },
        permission === 'write' && {
          label: t('a_common_remove'),
          iconName: 'remove_icon',
          onClickItem: handleOpenRemovingConfirmationModal
        })
    }

    return items.filter(Boolean)
  })()

  const mobileMenuItems = (() => {
    let items: React.ComponentProps<typeof MenuItem>[] = []

    if (!selectedItemsCount && items.length > 1) {
      items = [{
        label: t('a_files_downloadArchive'),
        iconName: 'download_empty',
        onClickItem: handleDownloadArchive,
      }, ...menuItems
      ]
    } else {
      items = [{
        label: t('a_common_download'),
        iconName: 'download_empty',
        onClickItem: handleDownloadSelected,
      }, ...menuItems
      ]
    }

    return items
  })()

  return (
    <PublicLinkSidebarBox className={className}>
      {
        !isAuthorized && (
          <StyledUserMenuForUnauthorized
            onLogIn={handleSignIn}
            onCreateAccount={handleSignUp}
            texts={{
              logIn: t('a_common_logIn'),
              or: t('l_common_or'),
              createAccount: t('a_common_signUp')
            }}
          />
        )
      }

      {
        !!isAuthorized && (
          <StyledUserMenu />
        )
      }

      {
        (!error || error === FilesPublicLinksErrorTypes.EMPTY_FOLDER) && (
          <>
            <ToolbarBox>
              {!selectedItemsCount && items.length > 1 ? (
                <StyledPrimaryButton
                  actionName={t('a_files_downloadArchive')}
                  onAction={handleDownloadArchive}
                />
              ) : (
                <StyledPrimaryButton
                  actionName={t('a_common_download')}
                  onAction={handleDownloadSelected}
                />
              )}

              {
                !!menuItems.length &&
                <MenuWithActionItems menuItems={menuItems} />
              }
            </ToolbarBox>

            <MobileToolbar
              items={mobileMenuItems}
              headContent={!!selectedItemsCount && (
                <MobileToolbarCounter selectedItemsCount={selectedItemsCount} />
              )}
            />
          </>
        )}

      <DialogModal
        isOpened={removingConfirmationModalOpened}
        title={t('l_common_headerRemovePhotos', { number: selectedItemsCount })}
        onClose={handleCloseRemovingConfirmationModal}
        okText={t('a_common_ok')}
        onOk={handleRemoveItems}
        cancelText={t('a_common_cancel')}
        onCancel={handleCloseRemovingConfirmationModal}
      >
        <TextInModalBox>
          {t('l_common_areYouSure')}
        </TextInModalBox>
      </DialogModal>

      {
        newFolderModalOpened && (
          <CreateNewFolderForm
            theme={theme}
            texts={{
              TITLE: t('l_common_createFolderTitle'),
              SUB_TITLE: t('l_common_folderName_field'),
              PLACEHOLDER: t('l_common_folderName_field'),
              NAME_BUTTON_ACTION: t('a_common_create'),
              NAME_BUTTON_CANCEL: t('a_common_close'),
              TITLE_ICON: 'create_new_folder',
            }}
            onCreateFolder={handleCreateFolder}
            onCloseModal={handleCloseNewFolderModal}
            isShown={true}
            parentBlock={getModalsRootElement()}
            validationSchema={validationSchemaForFileItem}
            defaultValue={t('l_files_createFolderPlaceholder')}
          />
        )}

      {
        !!copyMoveModalType && (
          <PublicLinkCopyMoveModal
            selectedItems={selectedItems}
            type={copyMoveModalType}
            currentFileFolder={currentFileFolder}
            onClose={handleCloseCopyMoveModal}
          />
        )}

      {copyModalOpened && (
        <CopyMoveModal
          selectedItems={selectedItems.length ? selectedItems : rootNodeType === NodeTypes.FILE ? [items[0]] : [paths.length > 1 ? currentFolder : nodeData]}
          type={FilesCopyMoveModalTypes.COPY_TO_PERSONAL}
          currentFileFolder={''}
          onClose={handleCloseCopyToPersonalModal}
          shareId={shareId}
        />
      )}
    </PublicLinkSidebarBox>
  )
}

const TextInModalBox = styled.span`
  font-size: 14px;
`

const PublicLinkSidebarBox = styled.div`
  width: 100%;
`

const StyledPrimaryButton = styled(PrimaryButton)`
  width: 100%;
`

const StyledUserMenuForUnauthorized = styled(UserMenuForUnauthorized)`
  padding: 0 24px;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    display: none;
  }
`

const StyledUserMenu = styled(UserMenu)`
  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    display: none;
  }
`

const ToolbarBox = styled.div`
  padding: 0 24px;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    display: none;
  }
`
