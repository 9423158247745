import { STYLED_VARIABLES } from 'constants/styledVariables'
import { formatImgUrl } from 'constants/configs/theme.config'

import React from 'react'

import styled from 'styled-components'
import { useTranslation } from "react-i18next"

import { getUiTheme } from "../user/selectors"
import { THEME } from "../user/userSlice"


interface EmptyAlbumPlaceholderProps {
  className?: string,
  isEmptyByDefault: boolean
}

export const EmptySearchPlaceholder: React.FC<EmptyAlbumPlaceholderProps> = ({ className = '', isEmptyByDefault = false }) => {
  const theme = getUiTheme()
  const { t } = useTranslation()

  return (
    <EmptyAlbumPlaceholderBox className={className}>
      {theme === THEME.LIGHT ? <ContainerImg src={formatImgUrl("/assets/placeholders-images/not-found.svg")} /> :
        <ContainerImg src={formatImgUrl("/assets/placeholders-images-black/not-found.svg")} />}

      <EmptyTitle>
        {t('l_files_searchResultTitle')}
      </EmptyTitle>

      <EmptyText>
        {isEmptyByDefault ? t('l_search_addMorePhotos') : t('l_search_photosEmptyState')}
      </EmptyText>
    </EmptyAlbumPlaceholderBox>
  )
}

const EmptyAlbumPlaceholderBox = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 0;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: calc(100vh - 16rem);
`

const ContainerImg = styled.img`
  width: 292px;
  height: 186px;
  pointer-events: none;
`

const EmptyTitle = styled.h2`
  margin-top: 40px;
  font-weight: 500;
  font-size: 24px;
  line-height: 37px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--text-primary);
  max-width: 280px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    line-height: 32px;
    max-width: unset;
    font-weight: 500;
  }
`

const EmptyText = styled.p`
  margin-top: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  color: var(--text-primary);
`
