import { encodeJsonToBase64 } from "../utils/utils"

import { request } from "./request"

export const transformerApi = {
  getTransformJWT: () => {
    return request('GET', `/api/3/transformer`, {}, { apiVersion: 3 })
  },
  getContentPhoto: (url: string) => {
    return request('GET', url, {}, { apiVersion: 3, host: null })
  },
  transformationPhoto: ({ url, transformerParamsData, transformationId, jwt }) => {
    return request('PUT', `/transformations/${transformationId}`, {},
      { host: url,
        headers: {
          'Transformation-Params': encodeJsonToBase64(transformerParamsData),
          'Authorization': `Bearer ${jwt}`
        },
      })
  },
  getResultTransformation: ({ url, jwt, transformationId }) => {
    return request('GET', `/transformations/${transformationId}`, {}, { host: url,
      headers: {
        'Authorization': `Bearer ${jwt}`
      },
    })
  },
}
