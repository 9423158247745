import React, { useCallback, useEffect } from "react"

import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { AddToAlbumModal } from "@cloudike/web_ui_components"

import { getPreviewAlbumLink } from "../../../utils/getPreviewAlbumLink"
import {
  getAlbumTypeToAddToAlbumModal,
  getIsOpenedAddPhotosToAlbumModal,
  getSelectedItemsToAddToAlbumModal
} from "../../common/selectors"
import { getAlbumsItemsSelector } from "../albums-list/selectors"
import { fetchAlbumsThunk } from "../albums-list/albumsSlice"
import { appActions } from "../../../store/app"
import { SDK_TYPES } from "../../../sdk/sdkConstants"
import { analytics, ANALYTICS_EVENTS } from "../../common/analytics"
import { addItemsToAlbumInModalThunk, albumActions, createNewAlbumWithItemsThunk } from "../album/albumSlice"
import { NOTIFICATION_TYPES, showNotification } from "../../common/notifications"
import { useAppDispatch } from "../../../store"
import { timelineActions } from "../../photo/timeline/timelineSlice"



export const AddToAlbum = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const selectedItemsToAddToAlbum = getSelectedItemsToAddToAlbumModal()
  const selectedItemsToAddToAlbumCount = selectedItemsToAddToAlbum.length
  const albums = getAlbumsItemsSelector()
  const albumsType = getAlbumTypeToAddToAlbumModal()

  const isOpenedAddPhotosToAlbumModal = getIsOpenedAddPhotosToAlbumModal()

  useEffect(() => {
    dispatch(fetchAlbumsThunk())
  }, [albumsType])

  const setIsOpenedAddPhotosToAlbumModal = (isOpened: boolean) => {
    dispatch(appActions.setIsOpenedAddPhotosToAlbumModal(isOpened))
    if (isOpened) {
      dispatch(fetchAlbumsThunk())

      if (albumsType === SDK_TYPES.DEFAULT) {
        analytics.push(ANALYTICS_EVENTS.WEB_PHOTOS_OUTSIDE_ADD_TO_ALBUM)
      } else {
        analytics.push(ANALYTICS_EVENTS.WEB_FC_OUTSIDE_ADD_TO_ALBUM)
      }
    }
  }

  const handleCloseAddToAlbumModal = () => {
    setIsOpenedAddPhotosToAlbumModal(false)
    dispatch(appActions.resetAddToAlbumModalState())
  }

  const handleCreateAlbumInModal = () => {
    handleCreateAlbumWithSelectedItems()
    if (albumsType === SDK_TYPES.DEFAULT) {
      analytics.push(ANALYTICS_EVENTS.WEB_PHOTOS_CREATE_NEW_ALBUM)
    } else {
      analytics.push(ANALYTICS_EVENTS.WEB_FC_CREATE_NEW_ALBUM)
    }
  }

  const handleCardClick = useCallback((id) => {
    const link = albumsType === SDK_TYPES.DEFAULT ? `/photos/albums/${id}` : `/family/photos/albums/${id}`

    dispatch(appActions.setAlbumIdToAddToAlbumModal(id))
    dispatch(addItemsToAlbumInModalThunk({
      items: selectedItemsToAddToAlbum, callback: () => {
        dispatch(timelineActions.unselectAll())
        dispatch(albumActions.unselectAll())
      }
    }))

    setIsOpenedAddPhotosToAlbumModal(false)

    showNotification({
      isPermanent: true,
      title: t('l_notification_itemsAddedtoAlbum', { number: selectedItemsToAddToAlbumCount }),
      type: NOTIFICATION_TYPES.SUCCESS,
      callback: () => navigate(link)
    })
  }, [selectedItemsToAddToAlbum, albums])

  const handleCreateAlbumWithSelectedItems = () => {
    const callback = (id: string) => {
      if (albumsType === SDK_TYPES.DEFAULT) {
        navigate(`/photos/albums/${id}`)
      }

      if (albumsType === SDK_TYPES.FAMILY) {
        navigate(`/family/photos/albums/${id}`)
      }

      setIsOpenedAddPhotosToAlbumModal(false)
    }

    dispatch(createNewAlbumWithItemsThunk({
      items: selectedItemsToAddToAlbum,
      callback,
      type: albumsType === SDK_TYPES.DEFAULT ? SDK_TYPES.DEFAULT : SDK_TYPES.FAMILY
    }))
    dispatch(albumActions.toggleAlbumNameEditStatus(true))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  return isOpenedAddPhotosToAlbumModal &&  (
    <AddToAlbumModal
      albums={albums}
      getPreviewAlbumLink={getPreviewAlbumLink}
      onClose={handleCloseAddToAlbumModal}
      onCreateAlbum={handleCreateAlbumInModal}
      onClickAlbum={handleCardClick}
      text={{
        title: t('l_albums_addToAlbum'),
        new_album_text: t('a_common_createAlbumButton'),
        photo_text: t('l_common_count')
      }}
    />
  )
}
