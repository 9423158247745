import styled from 'styled-components'
import { MenuItem } from '@cloudike/web_ui_components'

export const DropMenuPreview = ({ menuItems, isMobile ,handleClick }: any) => {

  const arrayDropMenu = menuItems.map(item => {
    return { ...item, onClickItem: item.handler }
  })

  const arrayDropMenuItemsSort =  arrayDropMenu.sort((a, b) => a.order - b.order)

  return (
    <DropDownBox>
      {arrayDropMenuItemsSort.map((props: any) => (
        <DropDownElemBox {...props}
          key={props.label || props.title}
          onClose={handleClick}
        />
      ))}
    </DropDownBox>
  )
}

const DropDownBox = styled.div`
  display: flex;
  background-color: var(--background-primary);
  padding: 8px 0;
  margin: 0;
  border: 1px solid var(--background-primary);
  border-radius: 4px;
  outline: none;
  font-size: 16px;
  box-shadow: 0 4px 20px rgb(0 0 0 / 8%);
  flex-flow: column;
  width: 220px;
  position: absolute;
  z-index: 100;
  top: 57px;
  right: 59px;
`

const DropDownElemBox = styled(MenuItem)`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  padding: 10px 16px;
  color: var(--text-secondary);

  & svg {
    color: var(--text-primary);
    margin-right: 8px;
  }
`
