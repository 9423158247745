import { LOADING_STATUSES } from 'constants/loadingStatuses'

import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getFilesSdk, getFilesWS } from 'sdk/files'
import { OrderType, SelectType } from '@cloudike/web_ui_components'
import { IFsNodeSchema, NodeTypes } from '@cloudike/web_files'
import { hideGlobalProgressLoader, showGlobalProgressLoader } from 'features/common/app-progress-bar'
import { NOTIFICATION_TYPES, showNotification } from 'features/common/notifications'
import { t } from 'i18next'
import { RootState } from 'store'
import { uploadFilesThunk } from 'features/common/files-uploading/filesUploadingSlice'
import _ from 'lodash'
import { request } from 'api/request'
import { NavigateFunction } from "react-router-dom"
import { IPostSearchFiles } from "@cloudike/web_files/dist/types/intarfaces/IPostSearchFiles"

import { filesApi } from "../../api/filesApi"
import { OpenedItemsService } from "../../utils/OpenedItemsService"
import { analytics, ANALYTICS_EVENTS } from "../common/analytics"
import {
  CheckboxVisibilityType,
  selectAllFileListItemsReducer,
  selectFileListItemReducer,
  selectFileListItemWithPressedCtrlReducer,
  selectFileListItemWithPressedShiftReducer,
  unselectAllFileListItemsReducer
} from "../../utils/filesListSelection"
import { goToSubscriptionsPage } from "../../utils/subscriptions"
import { fetchFileFullData } from "../../utils/files"
import { getErrorByFieldName, getHighestLevelErrorByFieldName } from "../../utils/utils"

import { FileItemsTypes } from './types'
import { getNewFileName, getNewFolderName, isFileExist, isFolderExist, pathsToTree } from './filesUtils'
import { initFilesCacheService } from "./filesCacheService"

export enum SortColumns {
  NAME = 'name',
  MODIFIED = 'updated',
  SIZE = 'file_info.size',
}

export enum ReplaceModalTypes {
  REPLACE = 'replace',
  KEEP_BOTH = 'keep_both'
}

export enum ReplaceModalUploadingTypes {
  DROPZONE = 'dropzone',
  FILES = 'files'
}

export const cacheService = initFilesCacheService()

const MAP_FOLDER_NAME_ID_KEY = 'MAP_FOLDER_NAME_ID'

let lastIdRequestAllNodes = null

const adapter = createEntityAdapter<IFsNodeSchema>()

export const filesSelectors = adapter.getSelectors()

let tempFilesWaitingForUserDecision: any[]
let callbackAfterUserDecision: () => void

const sortNodes = (nodes, { by, direction }) => {
  const orderByType = (entity) => entity.type

  return _.chain(nodes)
    .orderBy(by === 'name' ? node => node.name.toUpperCase() : by, [direction])
    .orderBy(orderByType)
    .value()
}

const initialState = {
  status: LOADING_STATUSES.LOADING,
  error: null,
  sort: { by: SortColumns.NAME, direction: OrderType.ASK },
  selectedItemsIds: [],
  renamingItemId: null,
  currentFolder: null,
  currentFolderId: '',
  selectType: SelectType.NONE,
  lastSelectedIndex: null,
  lastSelectedIndexes: [],
  lastSelectedWithShiftIndex: null,
  checkboxVisibility: CheckboxVisibilityType.HIDDEN,
  isActiveSearch: false,
  removedFolderId:'',
  replaceFilesNodeModalData: {
    opened: false,
    nodeType: FileItemsTypes.DIR,
    name: null,
    type: ReplaceModalTypes.KEEP_BOTH,
    uploadingType: ReplaceModalUploadingTypes.FILES
  },
  breadcrumbs: []
}

export const subscribeFilesToWSThunk = createAsyncThunk(
  'files/subscribeFilesToWSThunk',
  async function ({ navigate }: {navigate: NavigateFunction}, { dispatch, getState }) {
    const filesWs = getFilesWS()

    filesWs.addEventListener('fs2', async ({ output }) => {
      const state = getState() as RootState
      const changes = output?.changes || []
      const isActiveSearch = state.files.isActiveSearch

      const getCurrentFiles = () => {
        const state = getState() as RootState

        return filesSelectors.selectAll(state.files)
      }

      changes.forEach(async change => {
        if (change.action === 'created') {
          if (change.parent_id !== (state.files.currentFolderId || '')) {
            cacheService.deleteKey(change.parent_id)

            return
          }

          const nodeData = {
            created: change.created,
            updated: change.updated,
            name: change.name,
            id: change.node_id,
            type: change.type,
            parent_id: change.parent_id,
            is_trashed: false,
            is_explicitly_trashed: false,
            is_shared: false,
            file_info: change?.fields?.file_info,
            _embedded: change._embedded,
            _links: change._links
          }

          if (isActiveSearch) {
            return cacheService.addCachedFiles(change.parent_id, [nodeData])
          }

          if (nodeData?.file_info?.type === 'video' || nodeData?.file_info?.mime === 'application/pdf') {
            const { _links: { node } } = await filesApi.getFsRoot(state.user.userData.id)
            const urlTemplate = _.template(node.href, { interpolate: /\{(.+?)\}/g })
            const url = urlTemplate({ node_id: nodeData.id })

            const data = await request('GET', url, {}, { host: null })

            nodeData._links = data._links
          }

          const currentFilesItems = getCurrentFiles()
          const existedNode = currentFilesItems.find(n => n.id === nodeData.id)

          if (existedNode) {
            dispatch(actions.deleteItems([existedNode.id]))
          }

          dispatch(actions.setAll([nodeData, ...currentFilesItems]))
          cacheService.setCachedFiles(change.parent_id, [nodeData, ...currentFilesItems])
        }

        if (change.action === 'changed') {
          if (change.node_id === state.files.currentFolderId && change?.fields?.is_trashed) {
            navigate('/global-trash/files')

            return
          }

          if (change.parent_id !== (state.files.currentFolderId || '') && !isActiveSearch) {
            cacheService.deleteKey(change.parent_id)

            return
          }

          const currentFilesItems = getCurrentFiles()
          const existedNode = currentFilesItems.find(n => n.id === change.node_id)

          const node = {
            created: change.created,
            updated: change.updated,
            name: change.name,
            id: change.node_id,
            type: change.type,
            parent_id: change.parent_id,
            is_trashed: false,
            is_explicitly_trashed: false,
            _embedded: change._embedded || (existedNode as any)?._embedded,
            ...(change?.fields ? change.fields : {})
          }

          if (existedNode) {
            if (change?.fields?.is_trashed) {
              dispatch(actions.deleteItems([existedNode.id]))
              OpenedItemsService.removeItem(existedNode.id)
              cacheService.deleteFile(change.parent_id, existedNode.id)

              if (isActiveSearch && change.type === 'dir') {
                dispatch(actions.setRemovedFolderId(existedNode.id))
              }

              return
            }
          } else {
            if (change?.fields?.is_trashed === false) {
              const data = await fetchFileFullData(state.user.userData.id, node.id)
              const currentFilesItems = getCurrentFiles()

              dispatch(actions.setAll([data, ...currentFilesItems]))
              cacheService.setCachedFiles(change.parent_id, [data, ...currentFilesItems])
            }

            return
          }

          OpenedItemsService.updateItem(node)
          dispatch(actions.updateItem(node))
          cacheService.updateFile(change.parent_id, node)
        }
      })
    })
  }
)

export const unSubscribeFilesFromWSThunk = createAsyncThunk(
  'files/unSubscribeFilesFromWSThunk',
  async function () {
    const filesWs = getFilesWS()

    filesWs.removeEventListener('fs2')
  }
)

export const fetchFilesBreadcrumbsThunk = createAsyncThunk(
  'files/fetchFilesBreadcrumbsThunk',
  async function ({ paths }: { paths: string[] }, { getState, dispatch }) {
    const state = getState() as RootState
    const userData = state.user.userData

    const nameIdMapFromStorage = JSON.parse(sessionStorage.getItem(MAP_FOLDER_NAME_ID_KEY)) || {}

    const breadcrumbs = []

    for (const path of paths) {
      let name = nameIdMapFromStorage[path]

      if (!name) {
        const { _links: { node } } = await filesApi.getFsRoot(userData.id)
        const urlTemplate = _.template(node.href, { interpolate: /\{(.+?)\}/g })
        const url = urlTemplate({ node_id: path })

        const nodeData = await request('GET', url, {}, { host: null })

        name = nodeData.name
      }

      breadcrumbs.push({
        name,
        id: path
      })
    }

    const filteredBreadcrumbs = breadcrumbs.filter(path => !!path.name)

    if (filteredBreadcrumbs.length) {
      dispatch(actions.setBreadcrumbs([{ name: '' }, ...filteredBreadcrumbs]))
    } else {
      dispatch(actions.setBreadcrumbs([{ name: '' }]))
    }
  }
)

export const fetchNodesThunk = createAsyncThunk(
  'files/fetchNodesThunk',
  async ({ parent_id = '' }: { parent_id?: string }) => {
    const sdk = getFilesSdk()
    const currentIdRequestAllNodes = Date.now()
    lastIdRequestAllNodes = Date.now()

    let nodes: IFsNodeSchema[]
    const cachedNodes = cacheService.getCachedFiles(parent_id)

    if (cachedNodes) {
      nodes = cachedNodes
    } else {
      const response = await sdk.folderOperationsService.getAllFolderContent({ parent_id, preview: true, preview_jwt: true, embedded: true }, 500)
      nodes = response.filter(node => !node.is_trashed)
    }

    cacheService.setCachedFiles(parent_id, nodes)

    return { data: [...nodes], id: currentIdRequestAllNodes }
  }
)

export const getSearchNodesThunk = createAsyncThunk(
  'files/getSearchNodesThunk',
  async ({ text, type, parentId }: { text?: string, type?: string, parentId?: string }) => {
    const maxCountRecentNodes = 15
    const sdk = getFilesSdk()
    const currentIdRequestAllNodes = Date.now()
    lastIdRequestAllNodes = Date.now()

    const params: IPostSearchFiles = {}

    if (parentId) {
      params.parent_id = parentId
    }

    if (!!text) {
      analytics.push(ANALYTICS_EVENTS.WEB_FILES_SEARCH_ENTER)
      const rsp = await sdk.folderOperationsService.getAllSearchContent({ ...params, name_pattern: `*${text}*` })
      return { data: [...rsp], id: currentIdRequestAllNodes }
    }

    if (!!type) {
      if (type === 'dir') {
        const rsp = await sdk.folderOperationsService.getAllSearchContent({ ...params, type: type })
        return { data: [...rsp], id: currentIdRequestAllNodes }
      } else {
        const rsp = await sdk.folderOperationsService.getAllSearchContent({ ...params, type: 'file', file_type: type })
        return { data: [...rsp], id: currentIdRequestAllNodes }
      }
    }

    if (!text && !type) {
      const localNodes = OpenedItemsService.getItems()
      const nodes = localNodes.slice(0, maxCountRecentNodes)
      return { data: [...nodes], id: currentIdRequestAllNodes }
    }
  }
)

export const createNodePathThunk = createAsyncThunk(
  'files/createNodePathThunk',
  async ({ id = '', navigate, nodeType }: { id: string, navigate: NavigateFunction, nodeType: NodeTypes }, { getState, dispatch }) => {
    const state = getState() as RootState

    const { _links } = await filesApi.getFsRoot(state.user.userData.id)

    const urlTemplate = _.template(_links.node_ancestors.href, { interpolate: /\{(.+?)\}/g })
    const url = urlTemplate({ node_id: id })

    const data = await request('GET', url, {}, { host: null })

    const ancestors = data._embedded.nodes

    const path = '/drive' + ancestors.reverse().reduce((r, elem) => r += `/${elem.id}`, '') + (nodeType === NodeTypes.DIR ? `/${id}` : '')

    navigate(path, { replace: true })

    if (!ancestors.length && nodeType !== NodeTypes.FILE) {
      dispatch(actions.setCurrentFolderId(id))
      dispatch(fetchNodesThunk({ parent_id: id }))
    }
  }
)

export const deleteNodesThunk = createAsyncThunk(
  'files/deleteNodesThunk',
  async ({ ids }: { ids: string[] }, { dispatch }) => {
    showGlobalProgressLoader()

    try {
      const sdk = getFilesSdk()

      if(ids.length <= 100) {
        await sdk.folderOperationsService.moveNodesToTrash(ids)
      } else {
        const delay = ids.length * 5
        await sdk.folderOperationsService.batchMovingNodesToTrash(ids, undefined, delay)
      }

      dispatch(actions.unselectAll())

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: t('l_notification_itemsDeletedforWeb', { number: ids.length })
      })
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_somethingWrongTryAgain')
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const downloadNodesThunk = createAsyncThunk(
  'files/downloadNodesThunk',
  async ({ ids }: { ids: string[] }, { dispatch, getState }) => {
    const state = getState() as RootState
    const nodes = filesSelectors.selectAll(state.files)

    showGlobalProgressLoader()

    try {
      const sdk = getFilesSdk()

      let link

      if (ids.length === 1) {
        const nodeType = nodes.find(node => node.id === ids[0]).type

        if (nodeType === NodeTypes.DIR) {
          link = await sdk.folderOperationsService.getZipStreamLink(ids)
        } else {
          link = await sdk.folderOperationsService.getDownloadLink(ids[0])
        }
      } else {
        link = await sdk.folderOperationsService.getZipStreamLink(ids)
      }

      window.location.href = link
    } catch (error) {
      const details = getHighestLevelErrorByFieldName(error, 'details')

      if (details?.node_ids === 'Longer than maximum length 100') {
        showNotification({
          type: NOTIFICATION_TYPES.WARNING,
          title: t('l_notification_downloadFilesError')
        })

        return
      }

      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_somethingWrongTryAgain')
      })
    } finally {
      hideGlobalProgressLoader()
      dispatch(actions.unselectAll())
    }
  }
)

export const createFolderThunk = createAsyncThunk(
  'files/createFolderThunk',
  async ({ name, parentId }: { name: string, parentId: string }, { getState, dispatch }) => {
    const state = getState() as RootState
    const isActiveSearch = state.files.isActiveSearch
    const currentFolderId = state.files.currentFolderId

    const nodes = isActiveSearch ? cacheService.getCachedFiles(currentFolderId || '') : filesSelectors.selectAll(state.files)

    showGlobalProgressLoader()

    try {
      const sdk = getFilesSdk()

      if (isFolderExist(nodes, name)) {
        showNotification({
          type: NOTIFICATION_TYPES.WARNING,
          title: t('l_notification_folderNameError'),
          message: t('l_notification_createFolderError')
        })

        return
      }

      let newFolderName = name

      if (isFileExist(nodes, name)) {
        newFolderName = getNewFileName(nodes, name)
      }

      const result = await sdk.folderOperationsService.createNewFolder(newFolderName, parentId, { unique_name: true })
      dispatch(actions.updateItem({ id: result.data.id, _links: result.data._links }))

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: t('l_notification_folderCreated'),
      })
    } catch (error: any) {
      const code = getErrorByFieldName(error, 'code')

      if (code === 'SizeQuotaExceeded') {
        showNotification({
          type: NOTIFICATION_TYPES.WARNING,
          isPermanent: true,
          message: t('l_notification_createFolderError'),
          title: t('l_notification_spaceError'),
          typeError: 'SizeQuotaExceeded',
          callback: () => {
            goToSubscriptionsPage()
          }
        })

        return
      }

      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_somethingWrongTryAgain')
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const renameFileNodeThunk = createAsyncThunk(
  'files/renameFileNodeThunk',
  async ({ name }: { name: string }, { getState, dispatch }) => {
    const state = getState() as RootState
    const isActiveSearch = state.files.isActiveSearch
    const currentFolderId = state.files.currentFolderId

    const nodes = isActiveSearch ? cacheService.getCachedFiles(currentFolderId || '') : filesSelectors.selectAll(state.files)

    const renamingItemId = state.files.renamingItemId
    const node = nodes.find(node => node.id === renamingItemId)
    const nodeType = node?.type

    let finalName = name.trim()

    if (!finalName) {
      dispatch(actions.setRenamingItemId(null))
      dispatch(actions.unselectAll())

      return
    }

    if (finalName.length > 255) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_fileNameLongError')
      })

      dispatch(actions.setRenamingItemId(null))
      dispatch(actions.unselectAll())

      return
    }

    if (name === node.name) {
      dispatch(actions.setRenamingItemId(null))
      dispatch(actions.unselectAll())

      return
    }

    showGlobalProgressLoader()

    try {
      const sdk = getFilesSdk()

      if (nodeType !== NodeTypes.DIR && isFileExist(nodes, name)) {
        finalName = getNewFileName(nodes, name)
      }

      if (nodeType === NodeTypes.DIR && isFolderExist(nodes, name)) {
        finalName = getNewFolderName(nodes, name)
      }

      await sdk.folderOperationsService.renameNode(renamingItemId, finalName)

      // dispatch(actions.updateItem({ id: renamingItemId, name: finalName }))

      if (nodeType === NodeTypes.DIR) {
        showNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          title: t('l_notification_folderRenamed')
        })
      } else {
        showNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          title: t('l_notification_fileRenamed')
        })
      }
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_somethingWrongTryAgain')
      })
    } finally {
      hideGlobalProgressLoader()

      dispatch(actions.setRenamingItemId(null))
      dispatch(actions.unselectAll())
    }
  }
)

export const uploadFilesWithDropzone = createAsyncThunk(
  'files/uploadFilesWithDropzone',
  async ({ files }: { files: (File & {path: string })[] }, { getState, dispatch }) => {
    const state = getState() as RootState
    const catalogId = state.files.currentFolderId
    const isActiveSearch = state.files.isActiveSearch

    const currentFilesItems = isActiveSearch ? cacheService.getCachedFiles(catalogId || '') : filesSelectors.selectAll(state.files)
    const replaceModalData = state.files.replaceFilesNodeModalData

    const sdk = getFilesSdk()

    showGlobalProgressLoader()

    try {
      const paths = Array.from(files).map((file) => file.path)

      const parentIdToFilesMap = {}

      const filesItems = pathsToTree(paths).children

      if (filesItems.length === 1) {
        const item = filesItems[0]

        if (item.type === FileItemsTypes.DIR && isFolderExist(currentFilesItems, item.name) && replaceModalData.name !== item.name) {
          tempFilesWaitingForUserDecision = files

          dispatch(actions.setReplaceFilesNodeModalData({ nodeType: FileItemsTypes.DIR, name: item.name, opened: true, uploadingType: ReplaceModalUploadingTypes.DROPZONE }))

          return
        }

        if (item.type !== FileItemsTypes.DIR && isFileExist(currentFilesItems, item.name)) {
          tempFilesWaitingForUserDecision = files

          dispatch(actions.setReplaceFilesNodeModalData({ nodeType: FileItemsTypes.FILE, name: item.name, opened: true }))

          return
        }
      }

      const generateFolderStructure = async (parentId = '', items, path, previousFolderName = '') => {
        for (const item of items) {
          if (item.type === FileItemsTypes.DIR) {
            let name = item.name

            if (!previousFolderName && replaceModalData.name === name && replaceModalData.nodeType === FileItemsTypes.DIR) {
              if (replaceModalData.type === ReplaceModalTypes.KEEP_BOTH) {
                name = getNewFolderName(currentFilesItems, item.name)
              }

              if (replaceModalData.type === ReplaceModalTypes.REPLACE) {
                const folderToRemove = currentFilesItems.find(filesItem => filesItem.name === item.name && item.type === NodeTypes.DIR)

                await sdk.folderOperationsService.moveNodesToTrash([folderToRemove.id])
              }

              dispatch(actions.resetReplaceFilesNodeModalData())
              tempFilesWaitingForUserDecision = []
            }

            if (!previousFolderName && filesItems.length > 1 && isFolderExist(currentFilesItems, item.name)) {
              name = getNewFolderName(currentFilesItems, item.name)
            }

            const newDir = await sdk.folderOperationsService.createNewFolder(name, parentId, { unique_name: true })
            const newDirId = newDir.data.id

            if (item.children) {
              parentIdToFilesMap[newDirId] = []
              await generateFolderStructure(newDirId, item.children, path + (previousFolderName || item.name) + '/')
            }
          } else {
            const file = Array.from(files).find(file => file.path === (path ? '/' + path : '') + item.name)

            if (!parentIdToFilesMap[parentId]) {
              parentIdToFilesMap[parentId] = [file]
            } else {
              parentIdToFilesMap[parentId].push(file)
            }
          }
        }
      }

      await generateFolderStructure(catalogId, filesItems, '')

      Object.keys(parentIdToFilesMap).forEach(key => {
        const files = parentIdToFilesMap[key] as FileList

        if (key === (catalogId || '')) {
          const filesToUpload = Array.from(files).map(file => {
            if (isFileExist(currentFilesItems, file.name)) {
              const newFileName = getNewFileName(currentFilesItems, file.name)

              const blob = file.slice(0, file.size, file.type)

              const newFile = new File([blob], newFileName, { type: file.type })

              return newFile
            }

            return file
          })

          dispatch(uploadFilesThunk({ files: filesToUpload as any, parentId: catalogId }))

          return
        }

        dispatch(uploadFilesThunk({ files, parentId: key }))
      })
    } catch (error) {
      console.log(error)

      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_somethingWrongTryAgain')
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const uploadFolderThunk = createAsyncThunk(
  'files/uploadFolderThunk',
  async ({ files, callback }: { files: File[], callback?: () => void }, { getState, dispatch }) => {
    const state = getState() as RootState
    const catalogId = state.files.currentFolderId
    const isActiveSearch = state.files.isActiveSearch

    const currentFilesItems = isActiveSearch ? cacheService.getCachedFiles(catalogId || '') : filesSelectors.selectAll(state.files)

    const replaceModalData = state.files.replaceFilesNodeModalData

    const sdk = getFilesSdk()

    showGlobalProgressLoader()

    try {
      const paths = Array.from(files).map((file) => `/${file.webkitRelativePath}`)

      const parentIdToFilesMap = {}

      const generateFolderStructure = async (parentId, items, path, previousFolderName = '') => {
        for (const item of items) {
          if (item.type === FileItemsTypes.DIR) {
            const newDir = await sdk.folderOperationsService.createNewFolder(item.name, parentId, { unique_name: true })
            const newDirId = newDir.data.id

            if (item.children) {
              parentIdToFilesMap[newDirId] = []
              await generateFolderStructure(newDirId, item.children, path + (previousFolderName || item.name) + '/')
            }
          } else {
            const file = Array.from(files).find(file => file.webkitRelativePath === path + item.name)

            parentIdToFilesMap[parentId].push(file)
          }
        }
      }

      const items = pathsToTree(paths).children

      const rootFolderName = items[0].name

      if (replaceModalData.name === rootFolderName && replaceModalData.nodeType === FileItemsTypes.DIR) {
        if (replaceModalData.type === ReplaceModalTypes.KEEP_BOTH) {
          items[0].name = getNewFolderName(currentFilesItems, rootFolderName)
        }

        if (replaceModalData.type === ReplaceModalTypes.REPLACE) {
          const folderToRemove = currentFilesItems.find(item => item.name === rootFolderName && item.type === NodeTypes.DIR)

          await sdk.folderOperationsService.moveNodesToTrash([folderToRemove.id])
        }

        dispatch(actions.resetReplaceFilesNodeModalData())
        tempFilesWaitingForUserDecision = []
      } else {
        if (isFolderExist(currentFilesItems, rootFolderName)) {

          tempFilesWaitingForUserDecision = files
          callbackAfterUserDecision = callback

          dispatch(actions.setReplaceFilesNodeModalData({ nodeType: FileItemsTypes.DIR, name: rootFolderName, opened: true }))

          return
        }
      }

      await generateFolderStructure(catalogId, items, '', rootFolderName !== items[0].name ? rootFolderName : undefined)

      Object.keys(parentIdToFilesMap).forEach(key => {
        const files = parentIdToFilesMap[key]

        dispatch(uploadFilesThunk({ files, parentId: key }))
      })

      if (callback) {
        callback()
      }
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_somethingWrongTryAgain')
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const checkAndUploadFilesThunk = createAsyncThunk(
  'files/checkAndUploadFilesThunk',
  async ({ files, callback }: { files: FileList, callback?: () => void }, { getState, dispatch }) => {
    const state = getState() as RootState
    const catalogId = state.files.currentFolderId

    const replaceModalData = state.files.replaceFilesNodeModalData
    const isActiveSearch = state.files.isActiveSearch
    const currentFilesItems = isActiveSearch ? cacheService.getCachedFiles(catalogId) : filesSelectors.selectAll(state.files)

    showGlobalProgressLoader()

    try {
      if (files.length > 1) {
        const filesToUpload = Array.from(files).map(file => {
          if (isFileExist(currentFilesItems, file.name)) {
            const newFileName = getNewFileName(currentFilesItems, file.name)

            const blob = file.slice(0, file.size, file.type)

            const newFile = new File([blob], newFileName, { type: file.type })

            return newFile
          }

          return file
        })

        dispatch(uploadFilesThunk({ files: filesToUpload as any, callback, parentId: catalogId }))

        return
      }

      const fileName = files[0].name

      if (replaceModalData.name === fileName && replaceModalData.nodeType === FileItemsTypes.FILE) {
        if (replaceModalData.type === ReplaceModalTypes.KEEP_BOTH) {
          const newFileName = getNewFileName(currentFilesItems, fileName)
          const file = files[0]

          const blob = file.slice(0, file.size, file.type)

          const newFile = new File([blob], newFileName, { type: file.type })

          dispatch(uploadFilesThunk({ files: [newFile] as any, callback, parentId: catalogId }))
          dispatch(actions.setReplaceFilesNodeModalData({ name: null }))

          return
        }

        if (replaceModalData.type === ReplaceModalTypes.REPLACE) {
          const fileToReplace = currentFilesItems.find(item => item.name === fileName && item.type === NodeTypes.FILE)

          dispatch(uploadFilesThunk({ files, callback, parentId: catalogId, modificators: [{ target_node_id: fileToReplace.id }] }))
        }

        dispatch(actions.setReplaceFilesNodeModalData({ name: null }))
        tempFilesWaitingForUserDecision = []
      } else {
        if (isFileExist(currentFilesItems, fileName)) {
          tempFilesWaitingForUserDecision = Array.from(files)

          dispatch(actions.setReplaceFilesNodeModalData({ nodeType: FileItemsTypes.FILE, name: fileName, opened: true }))

          if (callback) {
            callback()
          }
          return
        } else {
          dispatch(uploadFilesThunk({ files, callback, parentId: catalogId }))
        }
      }
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_somethingWrongTryAgain')
      })
    } finally {
      hideGlobalProgressLoader()
    }
  })

export const keepBothFileVersionsThunk = createAsyncThunk(
  'files/keepBothFileVersionsThunk',
  async (_, { getState, dispatch }) => {
    const state = getState() as RootState

    const replaceModalData = state.files.replaceFilesNodeModalData

    if (replaceModalData.nodeType === FileItemsTypes.DIR) {
      if (replaceModalData.uploadingType === ReplaceModalUploadingTypes.DROPZONE) {
        dispatch(uploadFilesWithDropzone({ files: [...tempFilesWaitingForUserDecision] }))
      } else {
        dispatch(uploadFolderThunk({ files: [...tempFilesWaitingForUserDecision] }))
      }
    }

    if (replaceModalData.nodeType === FileItemsTypes.FILE) {
      dispatch(checkAndUploadFilesThunk({ files: [...tempFilesWaitingForUserDecision] as any }))
    }

    dispatch(actions.resetReplaceFilesNodeModalData())

    if (callbackAfterUserDecision) {
      callbackAfterUserDecision()

      callbackAfterUserDecision = undefined
    }
  }
)

export const setCurrentFolderThunk = createAsyncThunk(
  'files/replaceFileThunk',
  async ({ nodeId }: { nodeId: string }, { getState, dispatch }) => {
    const state = getState() as RootState

    const userId = state.user.userData.id

    if (!!nodeId) {
      try {
        const currentFolder = await filesApi.getCurrentFolderData(userId, nodeId)
        dispatch(actions.setCurrentFolder(currentFolder))
      } catch (error) {
        console.log(error)
      }
    } else {
      dispatch(actions.setCurrentFolder(null))
    }
  }
)

export const replaceFileThunk = createAsyncThunk(
  'files/replaceFileThunk',
  async (_, { getState, dispatch }) => {
    const state = getState() as RootState

    const replaceModalData = state.files.replaceFilesNodeModalData

    if (replaceModalData.nodeType === FileItemsTypes.DIR) {
      if (replaceModalData.uploadingType === ReplaceModalUploadingTypes.DROPZONE) {
        dispatch(uploadFilesWithDropzone({ files: [...tempFilesWaitingForUserDecision] }))
      } else {
        dispatch(uploadFolderThunk({ files: [...tempFilesWaitingForUserDecision] }))
      }
    }

    if (replaceModalData.nodeType === FileItemsTypes.FILE) {
      dispatch(checkAndUploadFilesThunk({ files: [...tempFilesWaitingForUserDecision] as any }))
    }

    dispatch(actions.resetReplaceFilesNodeModalData())

    if (callbackAfterUserDecision) {
      callbackAfterUserDecision()

      callbackAfterUserDecision = undefined
    }
  }
)

export const cancelFileReplacingThunk = createAsyncThunk(
  'files/cancelFileReplacingThunk',
  async (_, { dispatch }) => {
    tempFilesWaitingForUserDecision = []
    dispatch(actions.resetReplaceFilesNodeModalData())
  }
)

export const resetFilesStateThunk = createAsyncThunk(
  'files/resetFilesStateThunk',
  async (_, { dispatch }) => {
    dispatch(actions.resetState())
    cacheService.clear()
  }
)

export const filesSlice = createSlice({
  name: 'files',
  initialState: adapter.getInitialState(initialState),
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload
    },
    addItem: (state, action) => {
      adapter.removeOne(state, action.payload.id)
      adapter.addOne(state, action.payload)
    },
    updateItem: (state, action) => {
      adapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      })
    },
    setAll: (state, action) => {
      const nodes = sortNodes(action.payload, state.sort)

      adapter.setAll(state, nodes)
    },
    selectItem: selectFileListItemReducer(filesSelectors),
    selectItemWithPressedCtrl: selectFileListItemWithPressedCtrlReducer(filesSelectors),
    selectItemWithPressedShift: selectFileListItemWithPressedShiftReducer(filesSelectors),
    selectAll: selectAllFileListItemsReducer,
    unselectAll: unselectAllFileListItemsReducer,
    setSort: (state, action) => {
      state.sort = action.payload

      const nodes = sortNodes(filesSelectors.selectAll(state), state.sort)

      adapter.setAll(state, nodes)
    },
    setCurrentFolder: (state, action) => {
      state.currentFolder = action.payload
    },
    setCurrentFolderId: (state, action) => {
      state.currentFolderId = action.payload
    },
    deleteItems: (state, action: PayloadAction<string[]>) => {
      adapter.removeMany(state, action.payload)

      action.payload.forEach(id => {
        cacheService.deleteFile(state.currentFolderId || '', id)
      })
    },
    setActiveSearch: (state, action) => {
      state.isActiveSearch = action.payload
    },
    setRenamingItemId: (state, action) => {
      state.renamingItemId = action.payload
    },
    setBreadcrumbs: (state, action) => {
      state.breadcrumbs = action.payload
    },
    setRemovedFolderId: (state, action) => {
      state.removedFolderId = action.payload
    },
    setReplaceFilesNodeModalData: (state, action) => {
      state.replaceFilesNodeModalData = { ...state.replaceFilesNodeModalData, ...action.payload }
    },
    resetReplaceFilesNodeModalData: (state) => {
      state.replaceFilesNodeModalData = initialState.replaceFilesNodeModalData
    },
    resetState: (state) => {
      adapter.removeAll(state)

      Object.keys(initialState).forEach(key => {
        state[key] = initialState[key]
      })
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchNodesThunk.fulfilled, (state, action) => {
        state.status = LOADING_STATUSES.SUCCEEDED
        if (action.payload.id !== lastIdRequestAllNodes) return

        const nodes = sortNodes(action.payload.data, state.sort)

        adapter.setAll(state, nodes)
      })
      .addCase(fetchNodesThunk.rejected, (state, action) => {
        state.status = LOADING_STATUSES.FAILED
        state.error = action.error.message
      })
      .addCase(getSearchNodesThunk.pending, (state) => {
        state.status = LOADING_STATUSES.LOADING
      })
      .addCase(getSearchNodesThunk.fulfilled, (state, action) => {
        state.status = LOADING_STATUSES.SUCCEEDED
        if (action.payload.id !== lastIdRequestAllNodes) return

        const nodes = sortNodes(action.payload.data, state.sort)

        adapter.setAll(state, nodes)
      })
      .addCase(getSearchNodesThunk.rejected, (state, action) => {
        state.status = LOADING_STATUSES.FAILED
        state.error = action.error.message
      })
  },
})

export default filesSlice.reducer

const {
  reducer, actions
} = filesSlice

export { reducer as filesReducer, actions as filesActions }
