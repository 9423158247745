export function parsePathFromParams(params, currentPath) {
  const path = params['*']
  const paths = path.split('/')

  if (!paths[paths.length - 1]) {
    paths.pop()
  }

  if (paths[0] === currentPath) {
    paths.splice(0, 1)
  }

  return paths
}