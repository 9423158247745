export enum NOTIFICATION_TYPES {
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING'
}

export type INotification = {
  type: NOTIFICATION_TYPES,
  title: string,
  message?: string,
  typeError?: string,
  isPermanent?: boolean,
  id?: number | string,
  callback?: () => void,
  timeoutToHide?: number,
  userCantHide?: boolean,
  withHiddenArrow?: boolean,
  suffixText?: string
}

export type NotificationsListener = (notifications: INotification[]) => void
